import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { setCaptchaValues } from '../Redux/actions';
import { useAppDispatch } from '../Redux/reducers';

/**
 * Why custom hook useReCaptcha?
 * @see https://jira.paysystem.tech/browse/TEAMT-4917
 *
 */

export function useReCaptcha() {
  const dispatch = useAppDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(
    async (action: string) => {
      if (!executeRecaptcha) return;

      const googletoken = await executeRecaptcha(action);
      const payload = { action, googletoken };

      dispatch(setCaptchaValues(payload));
    },
    [executeRecaptcha]
  );

  return { handleReCaptchaVerify };
}
