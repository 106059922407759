//@flow
import { UnknownPaymentStatusError } from '../../../Constants/errors';
import PaymentService from '../../../Services/PaymentService';

const resolvePayment = async (paymentId: string) => {
  const { status, reason } = await PaymentService.getPaymentStatus({
    paymentId,
  });

  if (status === 'Succeed') {
    return status;
  } else if (status === 'Rejected') {
    const error = reason ? reason.description : '';
    throw new Error(error);
  } else {
    throw new UnknownPaymentStatusError();
  }
};

export default async (paymentId: string, onPending: Function, onSuccess: Function, onError: Function) => {
  try {
    await resolvePayment(paymentId);
    return onSuccess();
  } catch (error) {
    if (!(error instanceof UnknownPaymentStatusError)) {
      return onError(error);
    }
  }

  try {
    await new Promise(resolve => setTimeout(resolve, 1000));
    await resolvePayment(paymentId);
    return onSuccess();
  } catch (error) {
    if (error instanceof UnknownPaymentStatusError) {
      return onPending();
    } else {
      return onError(error);
    }
  }
};
