import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';

import App from './App';
import './polyfills';
import store from './Redux/store';
import { unregister } from './registerServiceWorker';
import { isCaptchaEnabled, selectedCaptcha } from './Constants/captcha';
import { checkSelectedCaptcha } from './Helpers';

/**
 * WTF? Why setTimeout?
 * @see https://jira.paysystem.tech/browse/TEAMT-2215?focusedCommentId=193292&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-193292
 *
 */

const reCaptchaProps = {
  reCaptchaKey: process.env.REACT_APP_FRONT_SITE_KEY,
  useRecaptchaNet: true,
  useEnterprise: true,
};

const isGoogleCaptchaEnabled = checkSelectedCaptcha(isCaptchaEnabled, selectedCaptcha, 'google');

const getApp = () =>
  ({
    [false]: <App />,
    [true]: (
      <GoogleReCaptchaProvider {...reCaptchaProps}>
        <App />
      </GoogleReCaptchaProvider>
    ),
  }[isGoogleCaptchaEnabled]);

setTimeout(() => ReactDOM.render(<Provider store={store}>{getApp()}</Provider>, document.getElementById('root')), 0);
unregister();
