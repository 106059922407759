import bindStatus from './bindStatus';
import paymentStatus from './paymentStatus';

let isPollingEnded = false;

const setIsPollingEnded = (nextIsPollingEnded) => (isPollingEnded = nextIsPollingEnded);

export default async (props) => {
  const {
    state,
    handlePaymentSucceed,
    handleBindSucceed,
    cancelTokenSource,
    handlePollingEnded: onPollingEnded,
    handleError,
  } = props;
  const { values } = state;
  const { paymentId, sbpTokenId } = values;
  const { token: cancelToken } = cancelTokenSource;

  const handlePollingEnded = () => {
    setIsPollingEnded(true);
    onPollingEnded();
  };

  const handlePollingCancel = () => {
    setIsPollingEnded(true);
  };

  try {
    await paymentStatus(
      paymentId,
      { onSuccess: handlePaymentSucceed, onPollingEnded: handlePollingEnded, onPollingCancel: handlePollingCancel },
      cancelToken
    );

    if (!isPollingEnded) {
      await bindStatus(
        sbpTokenId,
        { onSuccess: handleBindSucceed, onPollingEnded: handlePollingEnded, onPollingCancel: handlePollingCancel },
        cancelToken
      );
    }
  } catch (error) {
    handleError(error);
  }
};
