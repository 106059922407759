//@flow
import { api, parseApiResponse } from '../common';
import type {
  TGetCategoriesRequest,
  TGetCategoriesResponse,
  TGetTypesRequest,
  TGetTypesResponse,
  TSetThresholdRequest,
  TSetThresholdResponse,
  TSetPeriodicRequest,
  TSetPeriodicResponse,
  TSetAccountRequest,
  TGetSubscriptionFeeRequest,
  TGetSubscriptionFeeResponse,
  TSetSubscriptionFeeRequest,
  TSetSubscriptionFeeResponse,
  TAutopaymentСonfirmRequest,
  TAutopaymentСonfirmResponse,
} from '../../Constants/types';

const AUTOPAYMENTS = '/autopayments';

/**
 * Список доступных категорий по автоплатежу
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=45322920
 */
export const getCategories = async (requestData: TGetCategoriesRequest): Promise<TGetCategoriesResponse> => {
  const { msisdn, isB2b } = requestData;
  const response = await api.get(`${AUTOPAYMENTS}/categories?msisdn=${msisdn}&isB2b=${isB2b.toString()}`);
  return parseApiResponse(response);
};

/**
 * Список доступных типов по категориям по автоплатежу
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=44073940
 */
export const getTypes = async (requestData: TGetTypesRequest): Promise<TGetTypesResponse> => {
  const { msisdn, isB2b } = requestData;
  const response = await api.get(`${AUTOPAYMENTS}/types?msisdn=${msisdn}&isB2b=${isB2b.toString()}`);

  return parseApiResponse(response);
};

/**
 * Устанавливает автоплатеж по порогу
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=45322925
 */
export const setThreshold = async (requestData: TSetThresholdRequest): Promise<TSetThresholdResponse> => {
  const response = await api.post(`${AUTOPAYMENTS}/threshold`, requestData);

  return parseApiResponse(response);
};

/**
 * Устанавливает автоплатеж b2b
 *
 *
 */
export const setAccount = async (requestData: TSetAccountRequest): Promise<TSetThresholdResponse> => {
  const response = await api.post(`${AUTOPAYMENTS}/account`, requestData);

  return parseApiResponse(response);
};

/**
 * Устанавливает автоплатеж по периоду
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=45322923
 */
export const setPeriodic = async (requestData: TSetPeriodicRequest): Promise<TSetPeriodicResponse> => {
  const response = await api.post(`${AUTOPAYMENTS}/periodic`, requestData);

  return parseApiResponse(response);
};

export const getSubscriptionFee = async (
  requestData: TGetSubscriptionFeeRequest
): Promise<TGetSubscriptionFeeResponse> => {
  const response = await api.get(`${AUTOPAYMENTS}/subscriptionfee`, requestData);

  return parseApiResponse(response);
};

export const setSubscriptionFee = async (
  requestData: TSetSubscriptionFeeRequest
): Promise<TSetSubscriptionFeeResponse> => {
  const response = await api.post(`${AUTOPAYMENTS}/subscriptionfee`, requestData);

  return parseApiResponse(response);
};

/**
 * Проверить код подтверждения автоплатежа
 */
export const autopaymentСonfirm = async (
  requestData: TAutopaymentСonfirmRequest
): Promise<TAutopaymentСonfirmResponse> => {
  const response = await api.post(`${AUTOPAYMENTS}/${requestData.id}/confirm/${requestData.code}`, requestData);

  return parseApiResponse(response);
};
