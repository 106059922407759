import { ValidationError } from '../../../Constants/errors';
import { PAYMENT_STATUS } from '../../../Constants/paymentStatus';
import PaymentService from '../../../Services/PaymentService';

export default (
  paymentId: string,
  handlers: { onSuccess: Function, onError: Function, onWaitingExternalExecuting: Function },
  cancelToken
) => {
  const { onSuccess, onError, onWaitingExternalExecuting } = handlers;

  const handleErrorStatus = (reason) => {
    let error;

    if (reason) {
      error = new ValidationError({
        commonErrors: [reason.description],
      });
    } else {
      error = new ValidationError({
        commonErrors: ['Произошла техническая ошибка, повторите запрос позднее.'],
      });
    }

    onError(error);
  };

  const handleWaitingExternalExecutingStatus = async () => {
    onWaitingExternalExecuting();
    await paymentExactStatus(PAYMENT_STATUS.WAITING_EXTERNAL_EXECUTING);
  };

  const handleSucceedStatus = () => {
    onSuccess();
  };

  const paymentExactStatus = async (excl) => {
    const expectedStatuses = [
      PAYMENT_STATUS.WAITING_EXTERNAL_EXECUTING,
      PAYMENT_STATUS.REJECTED,
      PAYMENT_STATUS.SUCCEED,
    ].filter((sts) => sts !== excl);
    try {
      const { status: statusByRequest, reason } = await PaymentService.getPaymentExactStatus({
        paymentId,
        expectedStatuses,
        tries: 120,
        timeout: 10000,
        cancelToken,
      });
      const status = expectedStatuses.find((expectedSts) => expectedSts === statusByRequest);

      switch (status) {
        case PAYMENT_STATUS.WAITING_EXTERNAL_EXECUTING:
          await handleWaitingExternalExecutingStatus();
          break;
        case PAYMENT_STATUS.SUCCEED:
          handleSucceedStatus();
          break;
        default:
          handleErrorStatus(reason);
      }
    } catch (error) {
      onError(error);
    }
  };

  paymentExactStatus();
};
