import styled from 'styled-components';
import { breakpoints } from '../../../Constants/css';

export const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${breakpoints.desktop}) {
    flex: 1;
    flex-basis: auto; //Fix IE11
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;
