//
// @flow
//

/**
 * Метод конвертирования "целых" рублей в копейки
 */
export function convertIntegerRublesToKopecks(rubles: number): number {
  return rubles * 100;
}

/**
 * Метод конвертирования "дробных" рублей в копейки
 */
export function convertDecimalRublesToKopecks(rubles: number): number {
  return Math.round(rubles * 100);
}

/**
 * Метод конвертирования копеек в рубли (дробное число)
 */
export function convertKopecksToRublesAsDecimal(kopecks: number): number {
  return kopecks / 100;
}

/**
 * Метод конвертирования копеек в "целый" рубли с отсечение дробной части (остаток копеек)
 */
export function convertKopecksToRublesAsInteger(kopecks: number): number {
  return Math.floor(convertKopecksToRublesAsDecimal(kopecks));
}

/**
 * Метод проверки бренда "Skylink"
 */
export const checkSkylinkBrand = (brand: string): boolean => brand === 'skylink';

/**
 * Метод конвертирования "строчной логики" в "булеву логику"
 */
export const convertStringToBoolean = (str) => /^\s*(true|1|on)\s*$/i.test(str);
