import React from 'react';

import FieldSelect from '../../Controls/Select/FieldSelect';
import TextInput, { FieldTextInput } from '../../Controls/TextInput';
import { CATEGORY_PERIODIC, CATEGORY_SUBSCRIPTIONFEE } from './constants';
import DateField from './DateField';
import { amountInputRef } from './refs';
import { AmountBox, AmountInput, Condition, Row, Selectors, Value } from './styled';

const AutopaymentFields = (props) => {
  const { categoriesList, typesList, onCategoryChanged, values, subscriptionFee } = props;
  const { category } = values;

  const isSubscriptionFee = category === CATEGORY_SUBSCRIPTIONFEE;
  const amountLabel = isSubscriptionFee ? `Сумма` : 'Сумма, ₽ (от 100 до 3000)';

  return (
    <>
      <AmountBox>
        <AmountInput>
          <FieldTextInput
            type={'tel'}
            name={'amount'}
            maskType={isSubscriptionFee ? 'decimal' : 'amountInteger'}
            label={amountLabel}
            required
            maxLength={isSubscriptionFee ? 7 : 4}
            disabled={isSubscriptionFee}
            ref={amountInputRef}
          />
        </AmountInput>
      </AmountBox>
      <Row>
        <Selectors>
          <Condition>
            <FieldSelect name={'category'} options={categoriesList} onChange={onCategoryChanged} />
          </Condition>
          <Value>
            {isSubscriptionFee ? (
              <TextInput disabled name={'nextDate'} required label={'Дата списания'} value={subscriptionFee.nextDate} />
            ) : (
              <FieldSelect name={'type'} options={typesList} />
            )}
          </Value>
        </Selectors>
      </Row>
      {category === CATEGORY_PERIODIC && <DateField />}
    </>
  );
};

export default React.memo(AutopaymentFields);
