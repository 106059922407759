import { cvvInputRef, expirationDateInputRef } from './refs';

export const cardFieldsWidth = [
  {
    key: '1',
    type: 'tel',
    name: 'expirationDate',
    maskType: 'cardDate',
    label: 'Срок действия',
    autoComplete: 'cc-exp',
    maxLength: 5,
    ref: expirationDateInputRef,
  },
  {
    key: '2',
    type: 'password',
    name: 'cvv',
    maskType: 'cardCVC4',
    label: 'Код',
    autoComplete: 'cc-csc',
    maxLength: 3,
    ref: cvvInputRef,
  },
];
