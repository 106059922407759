/**
 * 'Failed'
 *
 * @flow
 */
import React from 'react';
import Button from '../../Controls/Button';

import { Controls, Icon, Message, Topic, Wrapper } from './styled';

type TProps = {
  /** Заголовок сообщения */
  topic?: string,
  /** Текст сообщения */
  message?: string,
  /** Текст кнопки */
  buttonLabel?: string,
  /** Обработчик нажатия кнопки */
  onClick?: (e: Event) => void,
};

/**
 * TODO: Refactor - need to delete in favor of ../Blocks/Result component (as more universal)
 * Right now E2E tests depends of uniq data-testid attrs
 */
const Failed = (props: TProps) => {
  return (
    <Wrapper data-testid={'block-failed'}>
      <Icon />
      <Topic data-testid={'block-failed-topic'}>{props.topic ? props.topic : 'Произошла ошибка!'}</Topic>
      {props.message && <Message data-testid={'block-failed-message'}>{props.message}</Message>}
      {props.onClick && (
        <Controls>
          <Button
            label={props.buttonLabel ? props.buttonLabel : 'Попробовать еще раз'}
            onClick={props.onClick}
            data-testid={'block-failed-button'}
          />
        </Controls>
      )}
    </Wrapper>
  );
};

export default React.memo(Failed);
