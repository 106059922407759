/**
 * withThreeDS HOC
 *
 * @flow
 */
import React, { Fragment } from 'react';

import type { TThreeDSData } from '../../Constants/types';

export type TWithThreeDS = {
  redirect: (threeDSData: TThreeDSData) => *,
};

type TState = TThreeDSData;

export default function withThreeDS(Component: React$ElementType) {
  return class withFetchingHOC extends React.Component<*, TState> {
    constructor(props: *) {
      super(props);

      this.state = {
        redirectUrl: '',
        md: '',
        pareq: '',
        termUrl: '',
        creq: '',
        threeDSSessionData: '',
      };
    }

    /**
     * Промисифицированный setState
     *
     * @private
     */
    asyncSetState = async (nextState: {}): Promise<void> => {
      return new Promise(resolve => {
        this.setState(nextState, resolve());
      });
    };

    /**
     * Метод перехода на страницу банка с 3DS
     */
    handleRedirect = async (threeDSData: TThreeDSData) => {
      const { redirectUrl, md = '', pareq, termUrl, creq, threeDSSessionData } = threeDSData;

      await this.asyncSetState({
        redirectUrl,
        md: btoa(md),
        pareq,
        termUrl,
        creq,
        threeDSSessionData,
      });

      this.refs['3ds-form'].submit();

      // return non-resolved Promise, because form submit is end of scenario.
      return new Promise(() => {});
    };

    render() {
      const { redirectUrl, md, pareq, termUrl, creq, threeDSSessionData } = this.state;
      const threeDSProps = {
        redirect: this.handleRedirect,
      };

      return (
        <Fragment>
          <form method={'post'} action={redirectUrl} ref={'3ds-form'}>
            {pareq ? (
              <>
                <input type={'hidden'} name={'MD'} value={md} />
                <input type={'hidden'} name={'PaReq'} value={pareq} />
              </>
            ) : (
              <>
                <input type={'hidden'} name={'creq'} value={creq} />
                <input type={'hidden'} name={'threeDSSessionData'} value={threeDSSessionData} />
              </>
            )}
            <input type={'hidden'} name={'TermUrl'} value={termUrl} />
          </form>
          <Component {...this.props} threeDS={threeDSProps} />
        </Fragment>
      );
    }
  };
}
