import * as React from 'react';

import { ButtonSpace, Buttons, Oferta } from '../../../../../Components/Blocks/DetailsPanel';
import SavePayButton from '../../../../../Components/Blocks/SavePayButton';
import Button from '../../../../../Components/Controls/Button';
import { CardInfoButtonBox, SberPayButton } from '../styled';
import SecureBadges from '../../../../../Components/Blocks/SecureBadges';
import BankCardLogos from '../../../../../Components/CardsImage';
import SecureSign from '../../../../../Components/Blocks/SecureSign';
import { images } from '../../../../../Constants/css';

const CardInfo = (props) => {
  const label = <img src={images.sberPayLogo} height={props.isMobile ? 24 : 34} alt="SberPay лого" />;

  return (
    <>
      <CardInfoButtonBox>
        <SavePayButton>
          <Buttons>
            <Button
              type={'submit'}
              onClick={props.onClickCardPay}
              label={'Оплатить картой'}
              disabled={!props.isValid}
              big={!props.isMobile}
            />
            {props.isSberPayButtonVisible && (
              <>
                <ButtonSpace />
                <SberPayButton
                  label={label}
                  borderless
                  type={'submit'}
                  onClick={props.onClickSberPay}
                  disabled={!props.isSberPayValid}
                  big={!props.isMobile}
                />
              </>
            )}
          </Buttons>
        </SavePayButton>
      </CardInfoButtonBox>
      <SecureSign />
      <SecureBadges />
      <Oferta>
        Нажимая «Оплатить картой», вы подтверждаете согласие с{' '}
        <a href={'https://tele2.ru/payoferta'} target={'_blank'}>
          Условиями предоставления услуги
        </a>
      </Oferta>
      {props.isMobile && <BankCardLogos />}
    </>
  );
};

export default React.memo(CardInfo);
