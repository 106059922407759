import { Form } from 'formik';
import React from 'react';

import CardFields from '../../../../Components/Blocks/CardFields';
import { Content, Wrapper } from '../../../../Components/Blocks/Common';
import { Oferta, Wrapper as DetailWrapper } from '../../../../Components/Blocks/DetailsPanel';
import { FormContainer, Row } from '../../../../Components/Blocks/Form';
import Panel from '../../../../Components/Blocks/Panel';
import SavePayButton from '../../../../Components/Blocks/SavePayButton';
import SecureBadges from '../../../../Components/Blocks/SecureBadges';
import SecureSign from '../../../../Components/Blocks/SecureSign';
import CardsImage from '../../../../Components/CardsImage';
import Button from '../../../../Components/Controls/Button';
import ReceiptElectron from '../../../../Components/Controls/ReceiptElectron';
import ErrorPanel from '../../../../Components/ErrorPanel';

const SelfRegistrationForm = (props) => {
  const { commonError, setFieldValue, isMobile, isValid } = props;

  const isDisabledButton = !isValid;

  return (
    <Wrapper>
      <Form>
        {commonError && (
          <div style={{ margin: '0px 0px 25px 0px' }}>
            <ErrorPanel text={commonError} />
          </div>
        )}
        <Content>
          <Panel>
            <FormContainer>
              <CardFields setFieldValue={setFieldValue} />

              <Row>
                <ReceiptElectron
                  type={props.values.electronReceiptType}
                  emailInputName={'electronReceiptEmail'}
                  mobileInputName={'electronReceiptMobile'}
                  receiptTypeRadioName={'electronReceiptType'}
                />
              </Row>

              {!isMobile && (
                <div style={{ margin: '24px 0 0 0' }}>
                  <CardsImage />
                </div>
              )}
            </FormContainer>
          </Panel>

          <Panel alternate>
            <DetailWrapper>
              <SavePayButton>
                <Button label={'Оплатить'} type={'submit'} disabled={isDisabledButton} big={!isMobile} />
              </SavePayButton>
              <SecureSign />
              <SecureBadges />
              <Oferta>
                Нажимая «Оплатить», вы подтверждаете согласие с{' '}
                <a href={'https://tele2.ru/payoferta'} target={'_blank noreferrer noopener'}>
                  Условиями предоставления услуги
                </a>
              </Oferta>
              {isMobile && <CardsImage />}
            </DetailWrapper>
          </Panel>
        </Content>
      </Form>
    </Wrapper>
  );
};

export default React.memo(SelfRegistrationForm);
