//
// @flow
//
import { create, isCancel } from 'apisauce';

import moment from 'moment';

import {
  CancelRequestError,
  ConflictError,
  CriticalError,
  InternalServerError,
  InvalidBodyError,
  NotFoundError,
  ValidationError,
} from '../Constants/errors';
import { RECAPTCHA_CHECK_FAILED_MSG, YANDEX_CAPTCHA_CHECK_FAILED_MSG } from '../Constants/statuses';
import { checkSelectedCaptcha, getQueryParamsString, verifyInterceptedUrl } from '../Helpers';
import { setResponse } from '../Redux/actions';
import store from '../Redux/store';
import { isCaptchaEnabled, selectedCaptcha } from '../Constants';

type TResponse = {
  ok: string,
  status: number,
  data: any,
};

export const api: Object = create({
  //$FlowFixMe
  baseURL: `${process.env.REACT_APP_FRONT_API_ENDPOINT}/api/v1/`,
  headers: {
    Pragma: 'no-cache',
  },
});

api.addMonitor((response) => {
  store.dispatch(setResponse({ data: response.data, url: response.config.url, time: moment().format('HH:mm:ss') }));
});

api.axiosInstance.interceptors.request.use((config) => {
  const { captchaReq: captchaHeaders, yandexCaptchaReq: yandexCaptchaHeaders } = store.getState();

  if (verifyInterceptedUrl(captchaHeaders, config)) {
    config.headers.action = captchaHeaders.action;
    config.headers.googletoken = captchaHeaders.googletoken;
  }

  if (verifyInterceptedUrl(yandexCaptchaHeaders, config)) {
    config.headers.yandexToken = yandexCaptchaHeaders.token;
  }

  const queryParams = getQueryParamsString();
  if (queryParams) {
    config.headers['Query-Params'] = queryParams;
  }

  return config;
});

/**
 * Обработчик ответа FronAPI
 */
export function parseApiResponse(response: TResponse) {
  const { ok, status, data: body } = response;

  if (ok) {
    return body;
  } else {
    if (isCancel(response.originalError)) {
      throw new CancelRequestError();
    }
    return handleApiError(status, body);
  }
}

function getForbiddenErrorInfo() {
  let error;

  const isGoogleCaptchaEnabled = checkSelectedCaptcha(isCaptchaEnabled, selectedCaptcha, 'google');
  const isYandexCaptchaEnabled = checkSelectedCaptcha(isCaptchaEnabled, selectedCaptcha, 'yandex');

  if (isGoogleCaptchaEnabled) error = RECAPTCHA_CHECK_FAILED_MSG;
  if (isYandexCaptchaEnabled) error = YANDEX_CAPTCHA_CHECK_FAILED_MSG;

  return { commonErrors: [error] };
}

/**
 * Обработчик ошибки с FrontAPI
 * proof: https://confluence.tyme.ru/pages/viewpage.action?pageId=44075685
 */
export function handleApiError(status: number = 0, body: any = {}) {
  switch (status) {
    case 400:
      throw new ValidationError(getValidationInfo(body));
    case 403:
      throw new ValidationError(getForbiddenErrorInfo());
    case 404:
      throw new NotFoundError(getCommonErrorInfo(body));
    case 409:
      throw new ConflictError(getCommonErrorInfo(body));
    case 500:
      throw new InternalServerError(getInternalServerErrorInfo(body));
    default:
      throw new CriticalError(getCommonErrorInfo(body));
  }
}

export function getValidationInfo(body: any = {}) {
  if (isObjectEmpty(body)) throw new InvalidBodyError(body);

  const { '': commonErrors = null, ...fieldErrors } = body;
  const { googletoken, action, yandexToken } = fieldErrors;

  if (commonErrors && !Array.isArray(commonErrors)) {
    throw new InvalidBodyError(body);
  }

  // Ошибка при отсутствии googletoken или action
  if (googletoken || action || yandexToken) {
    throw new InvalidBodyError(body);
  }

  return {
    commonErrors,
    fieldErrors: isObjectEmpty(fieldErrors) ? null : fieldErrors,
  };
}

//TODO: добавить обработку ошибки сети
export function getCommonErrorInfo(body: any = {}) {
  // if (!body) {
  //   // maybe: 0 - NETWORK_ERROR, status: null
  //   throw new NetworkError();
  // }

  if (body && body[''] && Array.isArray(body[''])) {
    return { commonErrors: body[''] };
  } else {
    throw new InvalidBodyError(body);
  }
}

export function getInternalServerErrorInfo(body: any = {}) {
  if (body === null) {
    throw new InvalidBodyError(body);
  }

  const { activityId, exceptionType, message, stackTrace } = body;

  if (activityId) {
    return {
      commonErrors: [`ActivityId: ${activityId}\n${exceptionType}: ${message}\n${stackTrace}`],
    };
  } else {
    throw new InvalidBodyError(body);
  }
}

export function getRequestTimeoutInfo(body: any = {}) {
  // TODO: По стандарту в теле ошибка должна лежать в "": ['текс']
  // на текущий момент (21.06.2018) ошибка лежит в commonErrors
  return body;
}

function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}
