import React from 'react';
import { compose } from 'recompose';
import Failed from '../../Components/Blocks/Failed';
import Layout from '../../Components/Blocks/Layout';
import Request from '../../Components/Blocks/Request';
import { withCloseInFrame, withErrorsHandle, withQueryParams } from '../../Components/HOC';
import PaymentStatus from './PaymentStatus';
import scenario from './Scenario';

class SinglePayment3DSResult extends React.Component {
  state = {
    pending: false,
    status: '',
  };

  onPaymentSuccess = () => {
    this.setState({ pending: false, status: 'Succeed' });
  };

  onPaymentPending = () => {
    this.setState({ pending: false, status: 'Pending' });
  };

  onError = (error) => {
    this.setState({ pending: false });
    this.props.handleError(error);
  };

  componentDidMount() {
    const { onPaymentPending, onPaymentSuccess, onError } = this;
    const {
      queryParams: { paymentid },
    } = this.props;

    this.setState({ pending: true });
    scenario(paymentid, onPaymentPending, onPaymentSuccess, onError);
  }

  render() {
    const error = this.props.criticalError || this.props.commonError;

    return (
      <Layout title={'Пополнение баланса'}>
        {this.state.pending && <Request />}

        {this.state.status && <PaymentStatus status={this.state.status} />}

        {error && <Failed message={error} onClick={this.props.onClose} buttonLabel={'Закрыть'} />}
      </Layout>
    );
  }
}

export default compose(withQueryParams, withErrorsHandle, withCloseInFrame)(SinglePayment3DSResult);
