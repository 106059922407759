import { Field } from 'formik';
import * as React from 'react';

import { setFormPayValues } from '../../../Redux/actions';
import { useAppDispatch } from '../../../Redux/reducers';
import TextInput from './index';

const FieldTextInput = React.forwardRef((props, ref) => {
  const dispatch = useAppDispatch();

  return (
    <Field
      {...props}
      render={({ field, form }) => (
        <TextInput
          {...field}
          {...props}
          onBlur={(event) => {
            const { name } = field;
            const { value } = event.target;
            dispatch(setFormPayValues({ name, value }));

            field.onBlur(event);
            props.onBlur && props.onBlur(event);
          }}
          ref={ref}
          errorText={form.touched[field.name] ? form.errors[field.name] : ''}
        />
      )}
    />
  );
});

export default FieldTextInput;
