import React from 'react';
import Layout from '../../Components/Blocks/Layout';
import SubscriberAccountPaymentSwitcher from './SubscriberAccountPaymentSwitcher';

const SubscriberAccountPaymentPage = props => {
  return (
    <Layout title={'Оплата задолженности по закрытому договору'}>
      <SubscriberAccountPaymentSwitcher {...props} />
    </Layout>
  );
};

export default SubscriberAccountPaymentPage;
