import { compose, lifecycle, withHandlers, withProps, withStateHandlers } from 'recompose';
import yup from 'yup';

import { withFetching, withFormikHelper } from '../../../Components/HOC';
import { debounce } from '../../../Helpers';
import { schemas as cardSchemas } from '../../../Helpers/validation';
import pullBranches from './Scenario/pullBranches';
import cardPay from './Scenario/topup';
import TopUpView from './View';
import { amountInputRef, cvvInputRef, expirationDateInputRef } from './View/RequisitesPanel/refs';

const handleSubmit = debounce((values, allProps) => cardPay(values, allProps), 200);

const validationSchema = ({ values }) => {
  const schemas = cardSchemas;
  return yup.object(
    Object.keys(values).reduce((acc, key) => {
      if (schemas[`${key}Schema`]) {
        if (key === 'amount') {
          acc[key] = schemas['subscriberAccountAmountSchema'];
        } else {
          acc[key] = schemas[`${key}Schema`];
        }
      }
      return acc;
    }, {})
  );
};

export default compose(
  withFetching,
  withStateHandlers(
    {
      remainingQuantity: '',
      commonError: '',
      branches: [],
    },
    {
      setCommonError: () => (commonError) => ({ commonError }),
      setBranches: () => (branches) => ({ branches }),
    }
  ),
  withProps((props) => {
    return {
      values: {
        subscriberAccount: props.subscriberAccount,
        amount: props.amount,
        pan: '',
        expirationDate: '',
        cvv: '',
        electronReceiptType: 'email',
        electronReceiptEmail: props.email || '',
        electronReceiptMobile: '',
        branch: props.branch,
      },
    };
  }),

  withFormikHelper({
    handleSubmit,
    validationSchema,
  }),
  withProps((props) => ({
    branchesList: props.branches.map((branch) => ({
      label: `${branch.branchId} ${branch.name}`,
      value: branch.branchId,
    })),
    isCardValid: props.dirty && !props.errors.pan && !props.errors.expirationDate && !props.errors.cvv,
    isSubscriberAccountDisabled: props.subscriberAccount,
    isAmountDisabled: props.amount,
    isBranchDisabled:
      !Number.isNaN(+props.branch) && Boolean(props.branches.find((branch) => branch.branchId === +props.branch)),
  })),
  withHandlers({
    onBranchesListChanged: (props) => (branch) => {
      if (branch) {
        props.setFieldValue('branch', branch.value);
      }
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { props } = this;

      /** It can't check formik's errors to set focus on next input,
       * because setting values and validating are separated operations,
       * so current value and error in one operation will be inconsistent.
       * This is how formik work under the hood.
       * That's why we need to call validation manually
       */

      if (
        prevProps.values.subscriberAccount !== props.values.subscriberAccount &&
        props.values.subscriberAccount.length === 13
      ) {
        amountInputRef.current.input.focus();
      }

      if (prevProps.values.pan !== props.values.pan && cardSchemas.panSchema.isValidSync(props.values.pan)) {
        expirationDateInputRef.current.input.focus();
      }

      if (
        prevProps.values.expirationDate !== props.values.expirationDate &&
        cardSchemas.expirationDateSchema.isValidSync(props.values.expirationDate)
      ) {
        cvvInputRef.current.input.focus();
      }

      if (prevProps.values.electronReceiptType !== props.values.electronReceiptType) {
        if (props.values.electronReceiptType === 'email') props.setFieldValue('electronReceiptMobile', '');

        if (props.values.electronReceiptType === 'mobile') props.setFieldValue('electronReceiptEmail', '');
      }

      if (
        prevProps.values.subscriberAccount !== props.values.subscriberAccount ||
        prevProps.values.pan !== props.values.pan ||
        prevProps.values.expirationDate !== props.values.expirationDate ||
        prevProps.values.cvv !== props.values.cvv ||
        prevProps.values.branch !== props.values.branch
      ) {
        props.setCommonError('');
      }
    },
    componentDidMount() {
      const { props } = this;
      pullBranches(this.props);
      const touched = Object.keys(props.values).reduce((touched, key) => {
        if (props.values[key]) {
          touched[key] = true;
        }
        return touched;
      }, {});
      props.setTouched({ ...touched });
    },
  })
)(TopUpView);
