import styled from 'styled-components';
import { breakpoints } from '../../../Constants/css';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 12px;

  > div {
    width: 50%;
    display: flex;
  }

  img {
    margin-right: auto;
  }

  @media (max-width: ${breakpoints.mobile}) {
    margin-top: 28px;

    flex-direction: column;
    align-items: start;

    > div {
      width: 100%;
      margin: 15px 0 0 0;
    }

    img {
      margin-right: 18px;
    }
  }
`;
