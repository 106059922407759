import React from 'react';
import { compose, withHandlers, withProps, withStateHandlers } from 'recompose';

import Succeed from '../../../../../Components/Blocks/Succeed';
import Switcher from '../../../../../Components/Controls/Switcher';
import { FieldTextInput } from '../../../../../Components/Controls/TextInput';
import ErrorPanel from '../../../../../Components/ErrorPanel';
import { withFetching } from '../../../../../Components/HOC';
import withFormik from '../../../../../Components/HOC/withFormikHelper';
import { formalizeMsisdn } from '../../../../../Helpers/Normalize';
import { schemas } from '../../../../../Helpers/validation';
import acceptCode from './Scenario/acceptCode';
import bindCard from './Scenario/bindCard';
import { AcceptCodeBox, Hint, Input, Label, Row, Separator, Title, Wrapper } from './styled';

const SucceedWithBindCardForm = props => {
  const { msisdn, fields, message, remainingQuantity } = props;

  return (
    <Wrapper>
      {props.commonError && (
        <div style={{ margin: '0px 0px 25px 0px' }}>
          <ErrorPanel text={props.commonError} />
        </div>
      )}
      <Succeed
        {...{
          fields,
          message,
          isSubmitButtonDisabled: !props.isValid,
          isSubmitButtonVisible: props.values.isCardBind,
          submitButtonLabel: 'Сохранить карту',
          onSubmit: props.handleSubmit,
        }}
      >
        <Wrapper>
          <Row>
            <Title>Сохраните карту и пополняйте счет через SMS</Title>
            <Separator />
            <Switcher
              {...{
                on: props.values.isCardBind,
                onClick: props.onToggle,
              }}
            />
          </Row>
          {props.values.isCardBind && (
            <AcceptCodeBox>
              <Input>
                <FieldTextInput
                  type={'tel'}
                  label={'Код'}
                  name={'acceptCode'}
                  required
                  maxLength={'4'}
                  maskType={'integer'}
                />
              </Input>
              <Label>SMS-код отправлен на номер {formalizeMsisdn(msisdn)} </Label>
            </AcceptCodeBox>
          )}
          {remainingQuantity && (
            <Hint data-testid={'remainingQuantity'}>Кол-во попыток осталось: {remainingQuantity}</Hint>
          )}
        </Wrapper>
      </Succeed>
    </Wrapper>
  );
};

export default compose(
  withFetching,
  withStateHandlers(
    {
      remainingQuantity: '',
      commonError: '',
    },
    {
      setCommonError: () => commonError => ({ commonError }),
      setRemainingQuantity: () => remainingQuantity => ({ remainingQuantity }),
    }
  ),
  withProps({
    values: { isCardBind: false, acceptCode: '' },
  }),
  withFormik({ handleSubmit: bindCard }, schemas),
  withHandlers({
    onToggle: props => () => {
      acceptCode(props);
    },
  })
)(React.memo(SucceedWithBindCardForm));
