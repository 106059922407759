import React from 'react';

import AddAutopaymentWrapper from '../../../../../../Components/Blocks/AddAutopaymentWrapper';
import AutopaymentFields from '../../../../../../Components/Blocks/AutopaymentFields';
import { FieldTextInput } from '../../../../../../Components/Controls/TextInput';
import ErrorPanel from '../../../../../../Components/ErrorPanel';
import { Msisdn, Row } from './styled';

const AddAutopaymentForm = (props) => {
  const { fields, message, categoriesList, typesList, values, onCategoryChanged, subscriptionFee } = props;

  const isSubmitButtonDisabled = !props.isValid || !values.type || !values.category;

  return (
    <AddAutopaymentWrapper
      {...{
        fields,
        message,
        isSubmitButtonDisabled: isSubmitButtonDisabled,
        isSubmitButtonVisible: props.values.withAutopayment,
        submitButtonLabel: 'Подключить автоплатеж',
        onSubmit: props.handleSubmit,
      }}
    >
      {props.commonError && (
        <div style={{ margin: '0px 0px 25px 0px' }}>
          <ErrorPanel text={props.commonError} />
        </div>
      )}
      <Row>
        <Msisdn>
          <FieldTextInput
            type={'tel'}
            name={'msisdn'}
            maskType={'phoneFull'}
            label={'Номер телефона'}
            required
            disabled={props.msisdn}
          />
        </Msisdn>
      </Row>
      <AutopaymentFields
        categoriesList={categoriesList}
        typesList={typesList}
        values={values}
        onCategoryChanged={onCategoryChanged}
        subscriptionFee={subscriptionFee}
      />
    </AddAutopaymentWrapper>
  );
};

export default React.memo(AddAutopaymentForm);
