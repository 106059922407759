import React from 'react';
import { images } from '../../../Constants/css';
import { Wrapper } from './styled';

const SecureSign = () => (
  <Wrapper>
    <img alt={''} src={images.paySave} />
    <span>
      Tele2 заботится о безопасности. Наши системы имеют сертификат PCI DSS, данные хранятся в зашифрованном виде.
    </span>
  </Wrapper>
);

export default SecureSign;
