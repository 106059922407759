import { ValidationError } from '../../../../Constants/errors';
import SubscriberService from '../../../../Services/SubscriberService';
import AcceptCodeService from '../../../../Services/AcceptCodeService';
import { SmsConfirmError } from '../../../../Constants/errors';

const withCommonHandle = func => async (values, formikBag) => {
  await func(values, formikBag);
};

const topUp = async (values, props) => {
  const { acceptCodeConfirm, cardId, subscriberId, acceptCodeId } = values;

  if (values.isCardBind) {
    try {
      await SubscriberService.confirmAcceptCode({
        subscriberId,
        cardId,
        acceptCodeId,
        acceptCode: acceptCodeConfirm,
      });
    } catch (error) {
      if (error instanceof ValidationError) {
        try {
          const { remainingQuantity } = await AcceptCodeService.getAcceptCodeStatus({
            acceptCodeId,
          });

          if (remainingQuantity && remainingQuantity !== 0) {
            props.setRemainingQuantityConfirm(remainingQuantity);
          } else {
            throw new SmsConfirmError();
          }
        } catch (error) {
          throw new SmsConfirmError();
        }
      }

      throw error;
    }
  }
};

export default withCommonHandle(topUp);
