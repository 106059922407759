/**
 * 'Option', 'Select's Element
 *
 * @flow
 */
import React from 'react';

import { Wrapper } from './styled';

class Option extends React.Component<*> {
  handleMouseDown = (event: SyntheticMouseEvent<>) => {
    const { onSelect, option } = this.props;

    event.preventDefault();
    event.stopPropagation();

    onSelect(option, event);
  };

  handleMouseEnter = (event: SyntheticMouseEvent<>) => {
    const { onFocus, option } = this.props;

    onFocus(option, event);
  };

  handleMouseMove = (event: SyntheticMouseEvent<>) => {
    const { isFocused, onFocus, option } = this.props;

    if (isFocused) return;
    onFocus(option, event);
  };

  render() {
    const {
      isFocused,
      isSelected,
      option: { title },
      children,
    } = this.props;

    return (
      <Wrapper
        {...{
          title,
          isFocused,
          isSelected,
          onMouseDown: this.handleMouseDown,
          onMouseEnter: this.handleMouseEnter,
          onMouseMove: this.handleMouseMove,
        }}
      >
        {children}
      </Wrapper>
    );
  }
}

export default Option;
