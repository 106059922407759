import styled, { css } from 'styled-components';
import { breakpoints, fonts } from '../../../Constants/css';

const desktopWidth = css`
  @media (min-width: ${breakpoints.mobile}) {
    max-width: 250px;
  }
`;

export const Row = styled.div`
  display: flex;

  align-items: center;
`;

export const Selectors = styled.div`
  display: flex;
  flex: 1;
  flex-basis: auto; //Fix IE11

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const AmountBox = styled.div`
  display: flex;
  margin: 0 0 20px 0;

  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakpoints.tablet}) {
    margin-bottom: 10px;

    flex-direction: column;
    align-items: flex-start;
  }
`;

export const AmountLabel = styled.div`
  margin: 0 0 16px 0;

  font-family: ${fonts.alternate};
  font-size: 16px;
  color: #191818;
  ${desktopWidth}

  @media (max-width: ${breakpoints.mobile}) {
    margin: 10px 0 10px 0;
    font-size: 15px;
    max-width: none;
  }
`;

export const AmountInput = styled.div`
  @media (min-width: ${breakpoints.tablet}) {
    width: 250px;
  }
  @media (max-width: ${breakpoints.tablet}) {
    margin: 5px 0 0 0;
    width: 100%;
  }
`;

export const Condition = styled.div`
  ${desktopWidth}
  flex: 1;
  flex-basis: auto; //Fix IE11
`;

export const Value = styled.div`
  ${desktopWidth}
  flex: 2;
  flex-basis: 0px;

  margin: 0 0 0 20px;
  // min-width: 260px;

  @media (max-width: ${breakpoints.mobile}) {
    margin: 10px 0 0 0;
  }
`;
