import { Form } from 'formik';
import * as React from 'react';

import { Content, Wrapper } from '../../../../Components/Blocks/Common';
import ErrorMessage from './ErrorMessage';
import RequisitesPanel from './RequisitesPanel';
import TotalPanel from './TotalPanel';

const TopUpForm = (props) => {
  const {
    commonError,
    errors,
    values,
    payType,
    setPayType,
    isSbpActive,
    isCardActive,
    yandexCaptcha,
    onYandexCaptchaSuccess,
    elementIsDisabled,
    onSbpBind,
    sbpRemainingQuantity,
  } = props;

  return (
    <Wrapper>
      <Form style={{ height: '100%' }}>
        <ErrorMessage commonError={commonError} />

        <Content>
          <RequisitesPanel
            isCardValid={props.isCardValid}
            errors={errors}
            setFieldValue={props.setFieldValue}
            values={values}
            onCardBind={props.onCardBind}
            remainingQuantity={props.remainingQuantity}
            payType={payType}
            setPayType={setPayType}
            isSbpActive={isSbpActive}
            isCardActive={isCardActive}
            elementIsDisabled={elementIsDisabled}
            onSbpBind={onSbpBind}
            sbpRemainingQuantity={sbpRemainingQuantity}
          />
          <TotalPanel
            errors={errors}
            values={values}
            isValid={props.isValid}
            isSberPayValid={props.isSberPayValid}
            payType={payType}
            onClickCardPay={props.onClickCardPay}
            onClickSberPay={props.onClickSberPay}
            onClickSBPPay={props.onClickSBPPay}
            isSberPayButtonVisible={props.isSberPayButtonVisible}
            yandexCaptcha={yandexCaptcha}
            onYandexCaptchaSuccess={onYandexCaptchaSuccess}
          />
        </Content>
      </Form>
    </Wrapper>
  );
};

export default React.memo(TopUpForm);
