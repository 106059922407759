import React from 'react';

const withFrame = (Component) => (props) => {
  const ref = React.useRef();
  const initialFrameData = { threeDSMethodData: '', url: '' };
  const [frameData, setFrameData] = React.useState(initialFrameData);
  const { threeDSMethodData, url } = frameData;

  const handlePostFrame = async (frameData) => {
    await setFrameData(frameData);

    if (ref.current) ref.current.submit();
  };

  return (
    <>
      <form method="post" target="hidden_iframe" action={url} ref={ref}>
        <input type="hidden" name="threeDSMethodData" value={threeDSMethodData} />
      </form>
      <iframe title="hidden_iframe" name="hidden_iframe" hidden />

      <Component {...props} {...{ handlePostFrame }} />
    </>
  );
};

export default withFrame;
