import { compose, lifecycle, withHandlers, withProps, withStateHandlers } from 'recompose';
import yup from 'yup';

import { withFetching, withFormikHelper } from '../../../Components/HOC';
import {
  ACTION_CARD_INFO,
  ACTION_SBERPAY_INFO,
  ACTION_SBP_INFO,
  isCaptchaEnabled,
  sbpIsDefault,
  selectedCaptcha,
} from '../../../Constants';
import { checkSelectedCaptcha, debounce } from '../../../Helpers';
import { sbpSchemas, schemas as cardSchemas, sberSchemas } from '../../../Helpers/validation';
import acceptCode from './Scenario/acceptCode';
import sbpPay from './Scenario/sbpPay';
import cardPay from './Scenario/topup';
import sberPay from './Scenario/sberPay';
import TopUpView from './View';
import { amountInputRef, cvvInputRef, expirationDateInputRef } from './View/RequisitesPanel/refs';
import checkPaymentMeans from './Scenario/checkPaymentMeans';
import checkCard from './Scenario/checkCard';
import { isSberPayCardCheck, isSberPayMsisdnCheck } from '../../../Constants/sberPay';
import { validateYupSchema, yupToFormErrors } from 'formik';
import { NO_CAPTCHA_CHANNELS } from '../../../Constants/captcha';
import acceptSbpCode from './Scenario/acceptSbpCode';

const payByType = (props) => {
  switch (props.submitType) {
    case 'card':
      cardPay(props);
      break;
    case 'sbp':
      sbpPay(props);
      break;
    case 'sberPay':
      sberPay(props);
      break;
    default:
      break;
  }
};

const getActionByType = (props) => {
  let action;

  switch (props.submitType) {
    case 'card':
      action = ACTION_CARD_INFO;
      break;
    case 'sbp':
      action = ACTION_SBP_INFO;
      break;
    case 'sberPay':
      action = ACTION_SBERPAY_INFO;
      break;
    default:
      break;
  }

  return action;
};

const handleSubmit = debounce(async (values, { props }) => {
  const isMobile = values.isMobile;
  const isAllowedChannel = !NO_CAPTCHA_CHANNELS.includes(props.channel);

  const isYandexCaptchaEnabled = checkSelectedCaptcha(isCaptchaEnabled, selectedCaptcha, 'yandex');
  const isGoogleCaptchaEnabled = checkSelectedCaptcha(isCaptchaEnabled, selectedCaptcha, 'google');

  if (!isMobile && isAllowedChannel) {
    if (isYandexCaptchaEnabled) {
      return props.handleYandexCaptchaVerify();
    }

    if (isGoogleCaptchaEnabled) {
      const action = getActionByType(props);
      await props.handleReCaptchaVerify(action);
      return payByType({ ...props, values });
    }
  }

  payByType({ ...props, values });

  props.setSubmitType('');
}, 200);

const validate = async (values, props) => {
  let errors = {};

  let schemas = cardSchemas;

  if (props.payType === 'card') {
    if (props.submitType === 'sberPay') {
      schemas = sberSchemas;
    }
  } else {
    schemas = sbpSchemas;
  }

  const validationSchema = yup.object(
    Object.keys(values).reduce((acc, key) => {
      if (schemas[`${key}Schema`]) {
        acc[key] = schemas[`${key}Schema`];
      }

      return acc;
    }, {})
  );
  try {
    await validateYupSchema(values, validationSchema);
  } catch (err) {
    errors = yupToFormErrors(err);
  }

  if (Object.keys(errors).length) {
    throw errors;
  }
};

export default compose(
  withFetching,
  withStateHandlers(
    (props) => {
      return {
        remainingQuantity: '',
        sbpRemainingQuantity: '',
        commonError: '',
        payType: sbpIsDefault && props.isSbpActive ? 'sbp' : 'card',
        isSberPayButtonVisible: false,
        isCheckPaymentsFetched: false,
        isCheckCardFetched: false,
        submitType: '',
      };
    },
    {
      setCommonError: () => (commonError) => ({ commonError }),
      setRemainingQuantity: () => (remainingQuantity) => ({ remainingQuantity }),
      setSbpRemainingQuantity: () => (sbpRemainingQuantity) => ({ sbpRemainingQuantity }),
      setPayType: () => (payType) => ({ payType }),
      setIsSberPayButtonVisible: () => (isSberPayButtonVisible) => ({ isSberPayButtonVisible }),
      setIsCheckPaymentsFetched: () => (isCheckPaymentsFetched) => ({ isCheckPaymentsFetched }),
      setIsCheckCardFetched: () => (isCheckCardFetched) => ({ isCheckCardFetched }),
      setSubmitType: () => (submitType) => ({ submitType }),
    }
  ),
  withProps((props) => ({
    values: {
      msisdn: props.msisdn,
      amount: props.amount,
      pan: '',
      expirationDate: '',
      cvv: '',
      electronReceiptType: 'email',
      electronReceiptEmail: props.email || '',
      electronReceiptMobile: '',
      acceptCode: '',
      isCardBind: false,
      sbpAcceptCode: '',
      isSbpBind: false,
      isMobile: props.isMobile,
    },
    channel: props.channel,
    authToken: props.authToken,
  })),
  withFormikHelper({
    handleSubmit,
    validate,
  }),
  withProps((props) => {
    const isCardValuesValid =
      props.submitType === '' || props.submitType === 'card'
        ? cardSchemas.panSchema.isValidSync(props.values.pan) &&
          cardSchemas.expirationDateSchema.isValidSync(props.values.expirationDate) &&
          cardSchemas.cvvSchema.isValidSync(props.values.cvv, { context: props.values })
        : false;

    const isValid =
      props.payType === 'card'
        ? isCardValuesValid && !Object.keys(props.errors).length
        : !Object.keys(props.errors).length;

    return {
      isCardValid:
        props.dirty && !props.errors.pan && !props.errors.expirationDate && !props.errors.cvv && isCardValuesValid,
      isValid,
      isSberPayValid: !props.errors.msisdn && !props.errors.amount,
    };
  }),
  withHandlers({
    onCardBind: (props) => () => {
      acceptCode(props);
    },
    onSbpBind: (props) => () => {
      acceptSbpCode(props);
    },
    onClickCardPay: (props) => () => props.setSubmitType('card'),
    onClickSBPPay: (props) => () => props.setSubmitType('sbp'),
    onClickSberPay: (props) => () => props.setSubmitType('sberPay'),
    onMsisdnFilled: (props) => () => checkPaymentMeans(props),
    onCardFilled: (props) => () => checkCard(props),
    onYandexCaptchaSuccess: (props) => (token) => {
      props.yandexCaptcha.handleSuccess(token);
      payByType(props);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { props } = this;
      if (prevProps.payType !== props.payType) {
        props.setCommonError('');
        props.setErrors({});
        props.validateForm();
      }

      if (props.payType === 'card') {
        if (cardSchemas.msisdnSchema.isValidSync(props.values.msisdn) && isSberPayMsisdnCheck) {
          props.onMsisdnFilled();
        }
        if (
          cardSchemas.panSchema.isValidSync(props.values.pan) &&
          cardSchemas.expirationDateSchema.isValidSync(props.values.expirationDate) &&
          document.activeElement !== expirationDateInputRef.current.input &&
          isSberPayCardCheck
        ) {
          props.onCardFilled();
        }
      }

      /** It can't check formik's errors to set focus on next input,
       * because setting values and validating are separated operations,
       * so current value and error in one operation will be inconsistent.
       * This is how formik work under the hood.
       * That's why we need to call validation manually
       */
      if (
        prevProps.values.msisdn !== props.values.msisdn &&
        cardSchemas.msisdnSchema.isValidSync(props.values.msisdn)
      ) {
        amountInputRef.current.input.focus();
      }

      if (props.payType === 'card') {
        if (prevProps.values.pan !== props.values.pan && cardSchemas.panSchema.isValidSync(props.values.pan)) {
          expirationDateInputRef.current.input.focus();
        }

        if (
          prevProps.values.expirationDate !== props.values.expirationDate &&
          cardSchemas.expirationDateSchema.isValidSync(props.values.expirationDate)
        ) {
          cvvInputRef.current.input.focus();
        }
      }

      if (
        props.payType === 'card' &&
        (prevProps.values.msisdn !== props.values.msisdn ||
          prevProps.values.pan !== props.values.pan ||
          prevProps.values.expirationDate !== props.values.expirationDate ||
          prevProps.values.cvv !== props.values.cvv)
      ) {
        props.setRemainingQuantity('');
        props.setCommonError('');
        props.setFieldValue('acceptCode', '', false);
        props.setFieldValue('isCardBind', '', false);
      }

      if (props.payType === 'sbp' && prevProps.values.msisdn !== props.values.msisdn) {
        props.setSbpRemainingQuantity('');
        props.setCommonError('');
        props.setFieldValue('sbpAcceptCode', '', false);
        props.setFieldValue('isSbpBind', '', false);
      }
    },
    componentDidMount() {
      const { props } = this;
      const touched = Object.keys(props.values).reduce((touched, key) => {
        if (props.values[key]) {
          touched[key] = true;
        }
        return touched;
      }, {});
      props.setTouched({ ...touched });

      if (
        cardSchemas.msisdnSchema.isValidSync(props.values.msisdn) &&
        props.payType === 'card' &&
        isSberPayMsisdnCheck
      ) {
        props.onMsisdnFilled();
      }
    },
  })
)(TopUpView);
