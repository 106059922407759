import yup from 'yup';
const amountSchema = yup
  .number()
  .nullable()
  .required('Обязательное поле')
  .test('amount-test', 'Некорректная сумма', amount => !amount || (amount >= 100 && amount <= 3000));

export const schemas = {
  amountSchema,
};
