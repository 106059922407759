import React, { memo } from 'react';
import Bind from './Bind';
import BindCode from './BindCode';

const SBPBind = (props) => {
  const { values, errors, onSbpBind, remainingQuantity } = props;

  const showHint = !Boolean(errors.msisdn) && !values.isSbpBind;
  const disabled = Boolean(errors.msisdn);

  return (
    <>
      <Bind
        value={values.isSbpBind}
        disabled={disabled}
        showHint={showHint}
        onClick={onSbpBind}
        hintText="Не забудьте сохранить СБП"
        bindText="Сохранить СБП для быстрой оплаты"
      />
      <BindCode
        msisdn={values.msisdn}
        isBind={values.isSbpBind}
        fieldName="sbpAcceptCode"
        remainingQuantity={remainingQuantity}
      />
    </>
  );
};

export default memo(SBPBind);
