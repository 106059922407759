/**
 * 'Button's Styled Components
 *
 * @flow
 */
import styled, { css } from 'styled-components';
import { fonts, rules, colors, breakpoints } from '../../../Constants/css';

const { bg } = colors;

const disabledRule = css`
  border-color: #bbbec6;
  background-color: ${bg.disabled};
  color: #bbbec6;
`;

const alternateDisabledRule = css`
  border-color: rgba(29, 19, 70, 0.7);
  background-color: rgba(29, 19, 70, 0.7);
  color: #fff;
`;

const disabled = css`
  ${disabledRule};

  cursor: default;

  :hover {
    ${disabledRule};
  }
`;

const alternateDisabled = css`
  ${alternateDisabledRule};

  cursor: default;

  :hover {
    ${alternateDisabledRule}
  }
`;

const black = css`
  border-color: #1f2229;
  background-color: #1f2229;
  color: #fff;

  :hover {
    border-color: #626469;
    background-color: #626469;
  }
`;

const darkblue = css`
  background-color: #1d1346;
  color: #fff;
  border-color: #1d1346;
`;

const ghost = css`
  border-color: #1f2229;
  background-color: #fff;
  color: #1f2229;

  :hover {
    border-color: #67686c;
    background-color: #fff;
    color: #67686c;
  }
`;

const h48 = css`
  min-height: 48px;

  padding: 7px 35px;
  border-radius: 24px;
`;

const h60 = css`
  min-height: 60px;

  padding: 13px 45px;
  border-radius: 30px;
`;

export const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid;
  outline: none;

  flex:1;
  flex-basis: auto; //Fix IE11

  font-family: ${fonts.alternate};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.88;
  text-align: center;
  transition: all 0.3s

  cursor: pointer;

  ${h48};

  ${(p) => (p.alternate ? darkblue : black)};
  ${(p) => p.disabled && (p.alternate ? alternateDisabled : disabled)};
  ${(p) => p.ghost && ghost};
  ${(p) => p.big && h60};
  ${(p) => p.borderless && rules.borderless};

  @media (min-width: ${breakpoints.desktop}) {
    ${(p) => p.width && `width: ${p.width}`};
  }
`;
