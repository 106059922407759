/**
 * 'MaskedInput', 'TextInput's Element
 *
 * @flow
 */
import React, { Component } from 'react';
import ReactMaskedInput from 'react-text-mask';
import { conformToMask } from 'text-mask-core';

import patterns, { TMaskType } from './patterns';

type TProps = {
  /** Тип вводимого значения */
  maskType?: TMaskType,
  /** Тип вводимого значения для переконвертирования (при copy/paste) */
  maskStatic?: TMaskType,
  /** Значение поля */
  value?: string,
  /** Плейсхолдер */
  placeholder?: string,
  /** Обработчик клика */
  onClick?: (e: SyntheticKeyboardEvent<>) => void,
  /** Обработчик изменений */
  onChange?: (e: SyntheticInputEvent<>) => void,
  /** Отображать 'guide' символы */
  guide?: boolean,
  /** Автовыделение значения */
  autoselect?: boolean,
};

class MaskedInput extends Component<TProps> {
  inputElement: ?HTMLInputElement = null;

  /**
   * HANDLERS
   */
  handleClick = (e: SyntheticKeyboardEvent<>) => {
    const element = e.target;
    const { autoselect, onClick } = this.props;

    if (element instanceof HTMLInputElement) {
      if (autoselect) element.select();
    }

    onClick && onClick(e);
  };

  handlePaste = (e: SyntheticClipboardEvent<>) => {
    const element = e.target;
    const { maskStatic } = this.props;
    const newValue = e.clipboardData.getData('Text');

    if (element instanceof HTMLInputElement && maskStatic) {
      element.value = conformToMask(newValue, maskStatic).conformedValue;
    }
  };

  /**
   * METHODS
   */

  /**
   * Метод сохраняет ссылку на элемент
   *
   * Нужен потому, что классический метод сохраниения ссылки не рабтает для MaskedInput
   * MaskedInput по просту теряет контекс на this класса
   */
  setInputRef = (ref: { inputElement: HTMLInputElement } | null) => {
    if (ref && ref.inputElement) {
      this.inputElement = ref.inputElement;
    }
  };

  /**
   * LIFECICLE
   */
  render() {
    const { maskType, maskStatic, onClick, guide, autoselect, ...props } = this.props;

    // TODO: разобраться почему так нагло теряется контекст и приходится байндить
    return (
      <ReactMaskedInput
        mask={patterns[maskType].mask || []}
        pipe={patterns[maskType].pipe || null}
        onClick={this.handleClick.bind(this)}
        onPaste={this.handlePaste.bind(this)}
        ref={this.setInputRef.bind(this)}
        guide={!!guide}
        {...props}
      />
    );
  }
}

export default MaskedInput;
