//
// @flow
//
import { api, parseApiResponse } from '../common';
import type { TGetCardRequest, TGetCardResponse } from '../../Constants/types';

const ROOT = '/cards';

/**
 * Получение карты по реквизитам
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=44729241
 *
 */
export const getCard = async function(requestData: TGetCardRequest): Promise<TGetCardResponse> {
  const { pan, expirationMonth, expirationYear } = requestData;

  const response = await api.get(
    `${ROOT}?pan=${pan}&expirationMonth=${expirationMonth}&expirationYear=${expirationYear}`
  );

  return parseApiResponse(response);
};

// TODO: del legacy
export const getCardAuthMethod = async function(data: any): any {};
export const prepareToHoldAmount = async function(data: any): any {};
export const confirmHoldAmount = async function(data: any): any {};
export const generateNewAcceptCode = async function(data: any): any {};
export const confirmAcceptCode = async function(data: any): any {};
