/**
 * 'Succeeded', 'Payment's View
 *
 * @flow
 */
import * as React from 'react';
import { compose } from 'recompose';

import CardsImage from '../../CardsImage';
import Button from '../../Controls/Button';
import { withCloseInFrame, withMobile } from '../../HOC';
import { Buttons, ButtonSpace, Topic, Wrapper as DetailsWrapper } from '../DetailsPanel';
import Panel from '../Panel';
import SavePayButton from '../SavePayButton';
import SecureSign from '../SecureSign';
import Field from './Field';
import { AdditionalBox, Cards, Margin, Wrapper } from './styled';

type TProps = {
  /** Номер абонента */
  fields?: Array<{
    label: string,
    value: string,
    key: string,
  }>,
  isSubmitButtonDisabled?: boolean,
  isSubmitButtonVisible?: boolean,
  submitButtonLabel?: string,
  message: string,
  onSubmit?: Function,
  onClose: Function,
  children: any /** React.ReactNode */,
  isMobile: boolean,
};

const AddAutopaymentWrapper = (props: TProps) => {
  return (
    <Wrapper data-testid={'block-result'}>
      <Panel width="580px">
        {props.fields && props.fields.map((field) => <Field {...field} data-testid={'block-result-field'} />)}
        {props.children ? <AdditionalBox>{props.children}</AdditionalBox> : null}
      </Panel>
      <Panel alternate>
        <DetailsWrapper>
          <Margin>
            <Topic data-testid={'block-result-message'}>{props.message}</Topic>
          </Margin>
          <SavePayButton>
            <Buttons>
              {props.isSubmitButtonVisible && (
                <>
                  <Button
                    disabled={props.isSubmitButtonDisabled}
                    label={props.submitButtonLabel || ''}
                    onClick={props.onSubmit}
                    big={!props.isMobile}
                    data-testid={'block-result-button-submit'}
                  />
                  <ButtonSpace />
                </>
              )}
              <Button
                disabled={false}
                label={'Закрыть'}
                onClick={props.onClose}
                ghost
                big={!props.isMobile}
                data-testid={'block-result-button-close'}
              />
            </Buttons>
          </SavePayButton>
          <SecureSign />
          <Cards>
            <CardsImage />
          </Cards>
        </DetailsWrapper>
      </Panel>
    </Wrapper>
  );
};

export default compose(withMobile, withCloseInFrame)(React.memo(AddAutopaymentWrapper));
