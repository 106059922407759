import styled, { css } from 'styled-components';
import { colors } from '../../../Constants/css';

const { border, bg } = colors;

const small = css`
  width: 18px;
  height: 18px;
`;

const big = css`
  width: 24px;
  height: 24px;
`;

const getStateStyle = p => {
  if (p.disabled) {
    return `
      background-color: ${bg.disabled};
      border: 2px solid ${border.disabled};
      cursor: not-allowed;
    `;
  }

  if (p.checked) {
    const width = p.size === 'big' ? '8px' : '6px';
    return `
      border-width: ${width};
      border-style: solid;
      border-color: ${border.main}
      background-color: #fff
    `;
  }
  return `
    border: 2px solid ${border.second};
    background-color: #fff
  `;
};

export const Icon = styled.div`
  position: relative;
  content: '';
  border-radius: 50%;
  box-sizing: border-box;
  flex: none;
  will-change: border;
  transition: border 0.2s;
  ${p => (p.size === 'big' ? big : small)}
  ${p => getStateStyle(p)};
`;
