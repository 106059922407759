import styled from 'styled-components';
import { breakpoints } from '../../../Constants/css';

export const ButtonContainer = styled.div`
  display: flex;
  & > :not(:last-child) {
    margin-right: 12px;
  }
  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    & > :not(:last-child) {
      margin-right: 0;
      margin-bottom: 9px;
    }
  }
`;

export const Content = styled.div`
  margin-top: 16px;

  @media (max-width: ${breakpoints.mobile}) {
    margin-top: 24px;
  }
`;
