//
// @flow
//
import { api, parseApiResponse } from '../common';
import type { TGetAcceptCodeStatusRequest, TGetAcceptCodeStatusResponse } from '../../Constants/types';

const ACCEPT_CODES = '/accept-codes';

/**
 * Запрос статуса смс кода подтверждения
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=44729756
 */
export const getAcceptCodeStatus = async function(
  requestData: TGetAcceptCodeStatusRequest
): Promise<TGetAcceptCodeStatusResponse> {
  const { acceptCodeId } = requestData;

  const response = await api.get(`${ACCEPT_CODES}/${acceptCodeId}`);

  return parseApiResponse(response);
};
