/**
 * Card Validation Schemas
 *
 * @flow
 */
import * as R from 'ramda';
import yup from 'yup';

import type { TRule } from './bankCards';
import bankCards from './bankCards';

type TSchema = {
  validateSync: Function,
};

export type TValidationSchemas = {
  panSchema: TSchema,
  expirationDateSchema: TSchema,
  cvvSchema: TSchema,
};

const convertPan = (pan: string = '') => pan.replace(/\s/g, '');
const findRule = (pan: string = ''): ?TRule => {
  return bankCards.find((rule) => rule.pattern.test(pan));
};
// TODO: разобраться как в Ramda можно передавать результаты двух функций в третью в качестве аргументов
const isValidLength = (pan: string = ''): boolean => {
  const rule = findRule(pan);
  if (rule) {
    return rule.validLength.some((l) => l === pan.length);
  }

  return false;
};

/**
 * Метод проверки номера карты по алгоритму 'lunh'
 *
 * @private
 * @param { string } pan
 * @returns { boolean }
 */
function luhnCheck(pan: string = ''): boolean {
  if (!pan) return false;

  let digit, n, sum, _i, _len, _ref;

  sum = 0;
  _ref = pan.replace(/\s/g, '').split('').reverse();

  for (n = _i = 0, _len = _ref.length; _i < _len; n = ++_i) {
    digit = _ref[n];
    digit = +digit;
    if (n % 2) {
      digit *= 2;
      if (digit < 10) {
        sum += digit;
      } else {
        sum += digit - 9;
      }
    } else {
      sum += digit;
    }
  }
  return sum % 10 === 0;
}

const hasRule = R.pipe(convertPan, findRule, R.isNil, R.not);

const getCleanMsisdn = (msisdn) => {
  if (msisdn) {
    let cleanMsisdn = msisdn.replace(/^\+7/, '').replace(/[^\d]/g, '');
    if (['7', '8'].includes(cleanMsisdn[0]) && cleanMsisdn.length === 11) {
      cleanMsisdn = cleanMsisdn.slice(1);
    }
    return cleanMsisdn;
  }
  return msisdn;
};

const getAcceptCodeSchema = (schema) =>
  schema
    .required('Введите 4-х значный код из sms')
    .min(4, 'Введите 4-х значный код из sms')
    .max(4, 'Введите 4-х значный код из sms');

const panSchema = yup
  .string()
  .required('Обязательное поле')
  .test('rule-check', 'Карта не определена', hasRule)
  .test('pan-length-check', 'Неверная длина номера', R.pipe(convertPan, isValidLength))
  .test('luhn-check', 'Номер карты введен некорректно', R.pipe(convertPan, luhnCheck));

const cvvSchema = yup.string().when('pan', (pan = '', schema) =>
  schema.test('cvv-length', '3-х значный код', (cvv = '') => {
    let convertedPan = convertPan(pan);
    let rule = findRule(convertedPan);
    if (rule) {
      if (rule.cvcDigits < 0 && cvv.length) {
        // Если CVV может быть необязательной, но CVV есть, то делаем сравнение длин
        return Math.abs(rule.cvcDigits) === cvv.length;
      } else if (rule.cvcDigits < 0 && !cvv.length) {
        // Если CVV может быть необязательной, но CVV пуст, то пропускаем проверку
        return true;
      } else {
        // В остальных случаях проверка длинны CVV обязательна в соответствии с правилами
        return rule.cvcDigits === cvv.length;
      }
    }

    return false;
  })
);

const expirationDateSchema = yup
  .string()
  .required('Обязательное поле')
  .min(5, 'Некорректное значение поля')
  .max(5, 'Некорректное значение поля');
// .test('expDate-out-of-date', 'Срок действия карты истек', (expDate = '') => {
//   const actualDate = new Date(new Date().getFullYear(), new Date().getMonth());
//   const expDateMonthYear = expDate.split('/');
//   const expDateMonth = Number(expDateMonthYear[0]) - 1;
//   const expDateYear = Number(expDateMonthYear[1]);
//   const expDateDate = new Date(2000 + expDateYear, expDateMonth);

//   return expDateDate.getTime() >= actualDate.getTime();
// });

const amountSchema = yup
  .string()
  .required('Обязательное поле')
  .test('amount-test', 'Некорректная сумма', (amount = 0) => amount >= 100 && amount <= 6000);

const subscriberAccountAmountSchema = yup
  .string()
  .required('Обязательное поле')
  .test('amount-test', 'Некорректная сумма', (amount = 0) => amount >= 1 && amount <= 15000);

const msisdnSchema = yup
  .string()
  .required('Обязательное поле')
  .test('msisdn-test', 'Неверный формат', (msisdn = '') => {
    const cleanMsisdn = getCleanMsisdn(msisdn);
    return Boolean(msisdn) && cleanMsisdn.length === 10;
  });

const subscriberAccountSchema = yup
  .string()
  .required('Обязательное поле')
  .test('subscriberAccount-test', 'Неверный формат', (subscriberAccount = '') => {
    return Boolean(subscriberAccount) && subscriberAccount.replace(/-/g, '').length <= 10;
  });

const electronReceiptEmailSchema = yup.string().email('Некорректное значение поля');
const electronReceiptMobileSchema = yup.string().test('receipt-mobile-test', 'Неверный формат', (msisdn = '') => {
  let m = getCleanMsisdn(msisdn);
  return m.length === 0 || m.length === 10;
});

const isSbpBindSchema = yup.boolean();
const sbpAcceptCodeSchema = yup
  .string()
  .when('isSbpBind', (isSbpBind, schema) => (isSbpBind ? getAcceptCodeSchema(schema) : schema.nullable()));

const isCardBindSchema = yup.boolean();
const acceptCodeSchema = yup
  .string()
  .when('isCardBind', (isCardBind, schema) => (isCardBind ? getAcceptCodeSchema(schema) : schema.nullable()));

const branchSchema = yup.string().required('Обязательное поле');

export const schemas = {
  panSchema,
  expirationDateSchema,
  cvvSchema,
  msisdnSchema,
  amountSchema,
  electronReceiptEmailSchema,
  electronReceiptMobileSchema,
  isCardBindSchema,
  acceptCodeSchema,
  subscriberAccountSchema,
  subscriberAccountAmountSchema,
  branchSchema,
};

export const sbpSchemas = {
  msisdnSchema,
  amountSchema,
  isSbpBindSchema,
  sbpAcceptCodeSchema,
};

export const sberSchemas = { msisdnSchema, amountSchema };
