import React from 'react';
import { compose } from 'recompose';

import { withCloseInFrame, withErrorsHandle } from '../../../../Components/HOC';

class SucceedWithBindCardAlien extends React.PureComponent {
  render() {
    const { onClose } = this.props;

    onClose();

    return null;
  }
}

export default compose(withErrorsHandle, withCloseInFrame)(SucceedWithBindCardAlien);
