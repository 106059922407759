import React, { createContext, useEffect, useState } from 'react';

import { withRouter } from 'react-router';
import qs from 'qs';
import checkMobile from './checkMobile';

export const IsMobileContext = createContext(null);

const IsMobile = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const {
      location: { search },
    } = props;
    const { mobile = 'false' } = qs.parse(search, { ignoreQueryPrefix: true });
    const isMobile = mobile === 'true' || checkMobile();
    setIsMobile(isMobile);
  }, []);

  const value = {
    isMobile,
  };

  return <IsMobileContext.Provider value={value}>{props.children}</IsMobileContext.Provider>;
};
export default withRouter(IsMobile);
