import React from 'react';
import { compose } from 'recompose';

import { withErrorsHandle, withQueryParams } from '../../Components/HOC';
import AutopaymentService from '../../Services/AutopaymentService';

class AutopaymentsConfirm extends React.PureComponent {
  state = {
    pending: false,
  };

  async componentDidMount() {
    this.setState({ confirmed: false });

    let params = this.props.match.params;
    let id = params.id;
    let code = params.code;

    try {
      const response = await AutopaymentService.autopaymentСonfirm({
        id,
        code,
      });

      if (response.autopaymentId) this.setState({ confirmed: true });
    } catch (e) {}

    this.setState({ pending: true });
  }

  render() {
    if (this.state.pending) {
      if (this.state.confirmed)
        return this.showMessage('Ваша заявка принята. Результат по операции будет отправлен в SMS');
      else return this.showMessage('Ошибка подтверждения автоплатежа');
    } else {
      return this.showMessage('');
    }
  }

  showMessage(text) {
    const style = {
      textAlign: 'center',
      fontWeight: 'bold',
      padding: '20px',
    };

    return <div style={style}>{text}</div>;
  }
}

export default compose(
  withQueryParams,
  withErrorsHandle
)(AutopaymentsConfirm);
