/**
 * 'Toggler', 'Switcher's Element <=> 'Toggler's Styled Components
 *
 * @flow
 */
import styled, { css } from 'styled-components';

// rules
const themes = {
  on: css`
    left: 0;
  `,
  off: css`
    left: 23px;
  `,
};

const Toggler = styled.div`
  position: relative;

  border-radius: 18px;
  width: 24px;
  height: 24px;

  background: #fff;

  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);

  transition: left 0.2s ease-in-out;

  ${(p) => (p.on ? themes.off : themes.on)};
`;

export default Toggler;
