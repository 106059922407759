//
// @flow
//
import {
  addCardToSubscriber,
  confirmAcceptCodeOfSubscriber,
  confirmAcceptCodeOfSubscriberWithPayment,
  confirmBindCardByLoop,
  createSubscriber,
  getSubscriber,
  sendAcceptCodeToSubscriber,
  getB2bSubscriber,
  createB2bSubscriber,
  confirmSbpAcceptCodeOfSubscriber,
  addSbpTokenToSubscriber,
} from '../Api/Subscribers';
import { NotFoundError, ValidationError } from '../Constants/errors';
import { normalizeMsisdnToElevenNumbers, normalizePan, sliceExpirationDate } from '../Helpers/Normalize';

import type {
  TGetSubscriberRequest,
  TGetSubscriberResponse,
  TCreateSubscriberRequest,
  TCreateSubscriberResponse,
  TSendAcceptCodeToSubscriberRequest,
  TSendAcceptCodeToSubscriberResponse,
  TConfirmAcceptCodeOfSubscriberRequest,
  TConfirmAcceptCodeOfSubscriberResponse,
  TConfirmAcceptCodeOfSubscriberWithPaymentRequest,
  TConfirmAcceptCodeOfSubscriberWithPaymentResponse,
  TAddCardToSubscriberRaw,
  TAddCardToSubscriberResponse,
  TSubscriberConfirmBindCardByLoopRequest,
  TGetB2bSubscriberRequest,
  TGetB2bSubscriberResponse,
  TCreateB2bSubscriberRequest,
  TCreateB2bSubscriberResponse,
} from '../Constants/types';

export default class SubscriberService {
  static async getSubscriber({ msisdn }: TGetSubscriberRequest): Promise<TGetSubscriberResponse> {
    const subscriber = await getSubscriber({
      msisdn: normalizeMsisdnToElevenNumbers(msisdn),
    });

    return subscriber;
  }

  static async createSubscriber({ msisdn }: TCreateSubscriberRequest): Promise<TCreateSubscriberResponse> {
    return await createSubscriber({
      msisdn: normalizeMsisdnToElevenNumbers(msisdn),
    });
  }

  //b2b
  static async getB2bSubscriber({ msisdn }: TGetB2bSubscriberRequest): Promise<TGetB2bSubscriberResponse> {
    const subscriber = await getB2bSubscriber({
      msisdn: normalizeMsisdnToElevenNumbers(msisdn),
    });

    return subscriber;
  }

  static async createB2bSubscriber({
    msisdn,
    clientType,
  }: TCreateB2bSubscriberRequest): Promise<TCreateB2bSubscriberResponse> {
    return await createB2bSubscriber({
      msisdn: normalizeMsisdnToElevenNumbers(msisdn),
      clientType: clientType,
    });
  }

  static async sendAcceptCode({
    subscriberId,
  }: TSendAcceptCodeToSubscriberRequest): Promise<TSendAcceptCodeToSubscriberResponse> {
    return await sendAcceptCodeToSubscriber({ subscriberId });
  }

  static async confirmAcceptCode({
    subscriberId,
    cardId,
    acceptCodeId,
    acceptCode,
  }: TConfirmAcceptCodeOfSubscriberRequest): Promise<TConfirmAcceptCodeOfSubscriberResponse> {
    return await confirmAcceptCodeOfSubscriber({
      subscriberId,
      cardId,
      acceptCodeId,
      acceptCode,
    });
  }

  static confirmSbpAcceptCode({
    subscriberId,
    sbpTokenId,
    acceptCodeId,
    acceptCode,
  }: TConfirmAcceptCodeOfSubscriberRequest): Promise<TConfirmAcceptCodeOfSubscriberResponse> {
    return confirmSbpAcceptCodeOfSubscriber({
      subscriberId,
      sbpTokenId,
      acceptCodeId,
      acceptCode,
    });
  }

  static async confirmAcceptCodeWithPayment({
    subscriberId,
    paymentId,
    acceptCodeId,
    acceptCode,
  }: TConfirmAcceptCodeOfSubscriberWithPaymentRequest): Promise<TConfirmAcceptCodeOfSubscriberWithPaymentResponse> {
    return await confirmAcceptCodeOfSubscriberWithPayment({
      subscriberId,
      paymentId,
      acceptCodeId,
      acceptCode,
    });
  }

  static async getOrCreateSubscriber({ msisdn }: TGetSubscriberRequest): Promise<TGetSubscriberResponse> {
    try {
      const { subscriberId, isBlocked } = await this.getSubscriber({
        msisdn,
      });

      return {
        subscriberId,
        isBlocked,
      };
    } catch (error) {
      if (error instanceof NotFoundError) {
        const { subscriberId } = await this.createSubscriber({ msisdn });

        return {
          subscriberId,
          isBlocked: false,
        };
      }

      throw error;
    }
  }

  static async getOrCreateB2bSubscriber({
    msisdn,
    clientType,
  }: TCreateB2bSubscriberRequest): Promise<TGetB2bSubscriberResponse> {
    let retVal = { subscriberId: '', isBlocked: '', accountMsisdn: '' };

    try {
      retVal = await this.getB2bSubscriber({
        msisdn,
      });

      return {
        subscriberId: retVal.subscriberId,
        isBlocked: retVal.isBlocked,
        accountMsisdn: retVal.accountMsisdn,
      };
    } catch (error) {
      if (error instanceof NotFoundError) {
        const { subscriberId } = await this.createB2bSubscriber({
          msisdn,
          clientType,
        });

        return {
          subscriberId,
          isBlocked: false,
          accountMsisdn: retVal.accountMsisdn,
        };
      }

      throw error;
    }
  }

  static async addCardToSubscriber(requestData: TAddCardToSubscriberRaw): Promise<TAddCardToSubscriberResponse> {
    const { subscriberId, pan, expirationDate } = requestData;
    const { expirationMonth, expirationYear } = sliceExpirationDate(expirationDate);
    let card;

    try {
      card = await addCardToSubscriber({
        subscriberId,
        pan: normalizePan(pan),
        expirationMonth,
        expirationYear,
      });
    } catch (error) {
      if (error instanceof ValidationError) {
        const { fieldErrors } = error;

        if (fieldErrors && (fieldErrors.expirationMonth || fieldErrors.expirationYear)) {
          error.fieldErrors.expirationDate = expirationMonth || expirationYear;
        }

        if (fieldErrors && fieldErrors['receive.Email']) {
          error.fieldErrors.electronReceiptEmail = fieldErrors['receive.Email'];
        }

        if (fieldErrors && fieldErrors['receive.PhoneNumber']) {
          error.fieldErrors.electronReceiptMobile = fieldErrors['receive.PhoneNumber'];
        }
      }

      throw error;
    }

    return card;
  }

  static async confirmBindCardByLoop(requestData: TSubscriberConfirmBindCardByLoopRequest): Promise<void> {
    const { acceptCode, ...restOfRequestData } = requestData;

    return await confirmBindCardByLoop({
      acceptCode: acceptCode.replace(/[^\d]/g, '').padEnd(3, '0'),
      ...restOfRequestData,
    });
  }

  static addSbpTokenToSubscriber(requestData) {
    const { subscriberId, msisdn } = requestData;

    return addSbpTokenToSubscriber({
      subscriberId,
      receive: {
        email: null,
        phoneNumber: normalizeMsisdnToElevenNumbers(msisdn),
      },
      withPayment: true,
    });
  }
}
