/**
 * 'Icon', Control Component
 *
 * @flow
 */
import React from 'react';
import styled from 'styled-components';

type TProps = {
  /** Имя иконки */
  name: string,
  /** Размер иконки */
  size?: number,
};

export default function Icon(props: TProps) {
  const iconContent = {
    add: '\\e900',
    'all-services': '\\e901',
    autopayment: '\\e902',
    back: '\\e903',
    'bank-card': '\\e904',
    'big-add-traffic': '\\e905',
    'big-day-sms': '\\e906',
    'big-mytele2': '\\e907',
    'big-tele2-wallet': '\\e908',
    calendar: '\\e909',
    calling: '\\e90a',
    'chat-bot-annoyed': '\\e90b',
    'chat-bot-sad': '\\e90c',
    'chat-bot-wink': '\\e90d',
    'chat-bot': '\\e90e',
    'chevron-down': '\\e90f',
    'top-arrow': '\\e90f', //chevron-down
    close: '\\e910',
    delivery: '\\e911',
    enter: '\\e912',
    exit: '\\e913',
    'fast-action': '\\e914',
    'fast-action2': '\\e915',
    'fast-balance': '\\e916',
    'fast-my-services': '\\e917',
    'fast-my-tariff': '\\e918',
    filter: '\\e919',
    'gb-messages': '\\e91a',
    'gb-movies': '\\e91b',
    'gb-music': '\\e91c',
    'gb-social-network': '\\e91d',
    'gb-websites': '\\e91e',
    help1: '\\e91f',
    help2: '\\e920',
    help3: '\\e921',
    internet: '\\e922',
    'its-your': '\\e923',
    'left-arrow': '\\e924',
    location: '\\e925',
    mobile: '\\e926',
    'online-chat': '\\e927',
    others: '\\e928',
    'person-2': '\\e929',
    'person-bear': '\\e92a',
    // ... and other animals
    person: '\\e931',
    pickup: '\\e932',
    print: '\\e933',
    'promised-payment': '\\e934',
    'right-arrow': '\\e935',
    search: '\\e936',
    shapematego: '\\e937',
    'sm-available': '\\e938',
    'sm-information': '\\e938',
    test: '\\e938',
    'not-available': '\\e93e',
    clock: '',
  };

  const Container = styled.span`
    font-family: 'icomoon';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: ${props.size ? `${props.size}px` : 'inherit'};

    &::after {
      content: '${iconContent[props.name]}';
    }
  `;

  return <Container className={`icon-${props.name}`} />;
}
