import { CancelRequestError, ValidationError } from '../../../Constants/errors';
import { PAYMENT_STATUS } from '../../../Constants/paymentStatus';
import PaymentService from '../../../Services/PaymentService';

export default async (
  paymentId: string,
  handlers: { onSuccess: Function, onPollingEnded: Function, onPollingCancel: Function },
  cancelToken
) => {
  const { onSuccess, onPollingEnded, onPollingCancel } = handlers;

  const handleError = (reason) => {
    if (reason) {
      throw new ValidationError({
        commonErrors: [reason.description],
      });
    } else {
      throw new ValidationError({
        commonErrors: ['Платёж не прошёл.'],
      });
    }
  };

  const handleSucceedStatus = () => {
    onSuccess();
  };

  const handlePollingEnded = () => {
    onPollingEnded();
  };

  const handlePollingCancel = () => {
    onPollingCancel();
  };

  const paymentExactStatus = async () => {
    const expectedStatuses = [PAYMENT_STATUS.REJECTED, PAYMENT_STATUS.SUCCEED];
    try {
      const { status: statusByRequest, reason } = await PaymentService.getPaymentExactStatus({
        paymentId,
        expectedStatuses,
        tries: 30,
        timeout: 10000,
        cancelToken,
      });
      const status = expectedStatuses.find((expectedSts) => expectedSts === statusByRequest);

      switch (status) {
        case PAYMENT_STATUS.SUCCEED:
          handleSucceedStatus();
          break;
        case PAYMENT_STATUS.REJECTED:
          handleError(reason);
          break;
        default:
          handlePollingEnded();
      }
    } catch (e) {
      if (e instanceof CancelRequestError) {
        handlePollingCancel();
      } else {
        handleError();
      }
    }
  };

  await paymentExactStatus();
};
