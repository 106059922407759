import { Form } from 'formik';
import * as React from 'react';
import { Content, Wrapper } from '../../../../Components/Blocks/Common';

import TotalPanel from './TotalPanel';
import RequisitesPanel from './RequisitesPanel';
import ErrorMessage from './ErrorMessage';

const SubscriberAccountPaymentForm = (props) => {
  const {
    commonError,
    errors,
    values,
    payType,
    setPayType,
    branchesList,
    isSubscriberAccountDisabled,
    isAmountDisabled,
    isBranchDisabled,
  } = props;

  const isElectronReceiptValid = (values) => {
    return (
      (values.electronReceiptType === 'email' && values.electronReceiptEmail) ||
      (values.electronReceiptType === 'mobile' && values.electronReceiptMobile)
    );
  };

  return (
    <Wrapper>
      <Form style={{ height: '100%' }}>
        <ErrorMessage commonError={commonError} />

        <Content>
          <RequisitesPanel
            isCardValid={props.isCardValid}
            errors={errors}
            setFieldValue={props.setFieldValue}
            values={values}
            onCardBind={props.onCardBind}
            onBranchesListChanged={props.onBranchesListChanged}
            remainingQuantity={props.remainingQuantity}
            payType={payType}
            setPayType={setPayType}
            branchesList={branchesList}
            isSubscriberAccountDisabled={isSubscriberAccountDisabled}
            isAmountDisabled={isAmountDisabled}
            isBranchDisabled={isBranchDisabled}
          />
          <TotalPanel
            errors={errors}
            values={values}
            isValid={props.isValid && isElectronReceiptValid(values)}
            payType={payType}
          />
        </Content>
      </Form>
    </Wrapper>
  );
};

export default SubscriberAccountPaymentForm;
