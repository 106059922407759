/**
 * 'Layout's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';
import { breakpoints, images } from '../../../Constants/css';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const Header = styled.div`
  margin: 42px 0 32px 0px;

  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.titleCentered ? 'center' : 'space-between')};
  align-items: stretch;

  @media (max-width: ${breakpoints.mobile}) {
    margin: 23px 0px 24px 15px;
  }
`;

export const Logo = styled.div`
  margin: 32px 0;
  width: 64px;
  height: 24px;

  background-image: url(${images.logo});
  background-repeat: no-repeat;

  @media (max-width: ${breakpoints.mobile}) {
    margin: 24px auto;
  }
`;
