import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { CancelToken } from 'apisauce';
import Failed from '../../Components/Blocks/Failed';
import Layout from '../../Components/Blocks/Layout';
import { withCloseInFrame, withErrorsHandle, withQueryParams, withThreeDS } from '../../Components/HOC';
import scenario from './Scenario';
import PaymentStatus from './PaymentStatus';
import { PAYMENT_STATUS } from '../../Constants/paymentStatus';
import Request from '../../Components/Blocks/Request';

const cancelTokenSource = CancelToken.source();

const SberPay = (props) => {
  const {
    queryParams: { paymentId, msisdn, amount, email },
    handleError,
    criticalError,
    commonError,
    onClose,
    history,
  } = props;

  const [isInitialRequestPending, setIsInitialRequestPending] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');

  const onError = (error) => {
    setIsInitialRequestPending(false);
    handleError(error);
  };

  const onSuccess = () => {
    setIsInitialRequestPending(false);
    setPaymentStatus(PAYMENT_STATUS.SUCCEED);
  };

  const onWaitingExternalExecuting = () => {
    setIsInitialRequestPending(false);
    setPaymentStatus(PAYMENT_STATUS.WAITING_EXTERNAL_EXECUTING);
  };

  const onReturn = () => {
    let urlParams = `msisdn=${msisdn}&amount=${amount}`;
    if (email) {
      urlParams += `&email=${email}`;
    }

    cancelTokenSource.cancel();
    history.push(`/?${urlParams}`);
  };

  useEffect(() => {
    setIsInitialRequestPending(true);
    scenario(paymentId, { onSuccess, onError, onWaitingExternalExecuting }, cancelTokenSource.token);
  }, []);

  const error = criticalError || commonError;

  return (
    <Layout title={'Пополнение баланса'}>
      {isInitialRequestPending && <Request />}
      {error && <Failed message={error} onClick={onClose} buttonLabel={'Закрыть'} />}
      {!error && paymentStatus && <PaymentStatus status={paymentStatus} onReturn={onReturn} msisdn={msisdn} />}
    </Layout>
  );
};

export default compose(withQueryParams, withThreeDS, withErrorsHandle, withCloseInFrame)(SberPay);
