import { CancelRequestError, ValidationError } from '../../../Constants/errors';
import { SBP_TOKEN_STATUS } from '../../../Constants/sbpTokenStatus';
import PaymentService from '../../../Services/PaymentService';

export default async (
  sbpTokenId: string,
  handlers: { onSuccess: Function, onPollingEnded: Function, onPollingCancel: Function },
  cancelToken
) => {
  const { onSuccess, onPollingEnded, onPollingCancel } = handlers;

  const handleError = () => {
    throw new ValidationError({
      commonErrors: ['СБП не был привязан.'],
    });
  };

  const handleSucceedStatus = () => {
    onSuccess();
  };

  const handlePollingEnded = () => {
    onPollingEnded();
  };

  const handlePollingCancel = () => {
    onPollingCancel();
  };

  const handleTokenExactStatus = async () => {
    const expectedStatuses = [SBP_TOKEN_STATUS.REJECTED, SBP_TOKEN_STATUS.CONFIRMED];
    try {
      const { status: statusByRequest } = await PaymentService.getSbpBindExactStatus({
        sbpTokenId,
        expectedStatuses,
        tries: 30,
        timeout: 10000,
        cancelToken,
      });
      const status = expectedStatuses.find((expectedSts) => expectedSts === statusByRequest);

      switch (status) {
        case SBP_TOKEN_STATUS.CONFIRMED:
          handleSucceedStatus();
          break;
        case SBP_TOKEN_STATUS.REJECTED:
          handleError();
          break;
        default:
          handlePollingEnded();
      }
    } catch (e) {
      if (e instanceof CancelRequestError) {
        handlePollingCancel();
      } else {
        handleError();
      }
    }
  };

  await handleTokenExactStatus();
};
