import React from 'react';
import { FormContainer, Row } from '../../../../../Components/Blocks/Form';
import Panel from '../../../../../Components/Blocks/Panel';
import ReceiptElectron from '../../../../../Components/Controls/ReceiptElectron';

import PaymentInfo from './PaymentInfo';
import BranchesList from './BranchesList';
import CardFields from '../../../../../Components/Blocks/CardFields';
import CardsImage from '../../../../../Components/CardsImage';
import { withMobile } from '../../../../../Components/HOC';
import { BlockTitle } from '../styled';

const RequisitesPanel = (props) => {
  const {
    setFieldValue,
    values,
    branchesList,
    onBranchesListChanged,
    isMobile,
    isBranchDisabled,
    isSubscriberAccountDisabled,
    isAmountDisabled,
  } = props;

  return (
    <Panel>
      <FormContainer>
        <BlockTitle>Реквизиты для пополнения</BlockTitle>
        <PaymentInfo
          setFieldValue={setFieldValue}
          isSubscriberAccountDisabled={isSubscriberAccountDisabled}
          isAmountDisabled={isAmountDisabled}
        />
        <BranchesList
          branchesList={branchesList}
          onBranchesListChanged={onBranchesListChanged}
          disabled={isBranchDisabled}
        />
        <CardFields setFieldValue={setFieldValue} />

        <BlockTitle>Электронный чек</BlockTitle>
        <Row>
          <ReceiptElectron
            type={values.electronReceiptType}
            emailInputName={'electronReceiptEmail'}
            mobileInputName={'electronReceiptMobile'}
            receiptTypeRadioName={'electronReceiptType'}
          />
        </Row>
        {!isMobile && <CardsImage />}
      </FormContainer>
    </Panel>
  );
};

export default withMobile(RequisitesPanel);
