import { createReducer } from 'redux-act';

import { setFormPayValues } from '../actions';

const initialState = {
  msisdn: '',
  amount: '',
  pan: '',
  expirationDate: { month: '', year: '' },
  cvv: '',
  isCardBind: false,
  receive: { email: '', phoneNumber: '' },
};

export const formPayReqReducer = createReducer({}, initialState).on(setFormPayValues, (state, { name, value }) => {
  const fieldNames = ['expirationDate', 'electronReceiptEmail', 'electronReceiptMobile'];

  return {
    ...state,
    ...(!fieldNames.includes(name)
      ? { [name]: value }
      : {
          receive: {
            ...state.receive,
            ...{
              electronReceiptEmail: { email: value },
              electronReceiptMobile: { phoneNumber: value },
            }[name],
          },
          ...(name === 'expirationDate' && {
            expirationDate: {
              month: value.split('/')[0],
              year: value.split('/')[1],
            },
          }),
        }),
  };
});
