/**
 * withFetching HOC
 *
 * @flow
 */

import type { TFetchingActions } from '../Context/Fetching';

import * as React from 'react';
import { FetchingConsumer } from '../Context/Fetching';

export type TWithFetching = {
  fetching: TFetchingActions | void,
};

export default function withFetching<Props: {}>(
  Component: React.ComponentType<Props>
): React.ComponentType<$Diff<Props, TWithFetching>> {
  return function withFetchingHOC(props: Props) {
    return <FetchingConsumer>{fetching => <Component {...props} fetching={fetching} />}</FetchingConsumer>;
  };
}
