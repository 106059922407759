import { compose } from 'recompose';
import { CriticalError, NotFoundError } from '../../../../../Constants/errors';
import withCommonRequestProcess from '../../../../../Helpers/withCommonRequestProcess';
import AutopaymentService from '../../../../../Services/AutopaymentService';
import { CATEGORY_SUBSCRIPTIONFEE } from '../Form';
import { pullSubscriptionFee } from './pullSubscriptionFee';

const pullAutopayment = async (props) => {
  const { values, paymentType, setValues, setTypes, setCategories, amount, setFieldValue, clientType } = props;

  let msisdn = props.msisdn ? props.msisdn : props.values.msisdn;

  if (msisdn) {
    let categories;
    let types;

    try {
      const isB2b = Boolean(clientType);

      const [c, t] = await Promise.all([
        AutopaymentService.getCategories({ msisdn, isB2b }),
        AutopaymentService.getTypes({ msisdn, isB2b }),
      ]);

      categories = c;
      types = t;
    } catch (e) {
      let commonErrors = [`При запросе доступных услуг произошла ошибка, повторите запрос позднее.`];
      if (e instanceof NotFoundError) {
        commonErrors = e.commonErrors;
      }

      throw new CriticalError({
        commonErrors,
      });
    }

    const defaultCategoryItem = categories.find((category) => category.isDefault);
    const firstInListCategoryItem = categories.length ? categories[0] : null;
    const category = defaultCategoryItem
      ? defaultCategoryItem.billingServiceCategoryId
      : firstInListCategoryItem
      ? firstInListCategoryItem.billingServiceCategoryId
      : '';

    const defaultTypeItem = types.find((type) => type.billingServiceCategory.id === category && type.isDefault);
    const firstInListTypeItem = types.find((type) => type.billingServiceCategory.id === category);
    const type = defaultTypeItem
      ? defaultTypeItem.billingServiceId
      : firstInListTypeItem
      ? firstInListTypeItem.billingServiceId
      : '';

    setValues({
      ...values,
      amount: paymentType === 'topup' ? (Number(amount) > 3000 ? 3000 : amount) : undefined,
      type,
    });
    setTypes(types);
    setCategories(categories);

    if (category === CATEGORY_SUBSCRIPTIONFEE) {
      await pullSubscriptionFee({ ...props, msisdn });
    } else {
      setFieldValue('category', category);
    }
  }

  setFieldValue('withAutopayment', true);
};

const adapterCommonRequestProcess = (func) => {
  return (props) => {
    const { setErrors, fetching, setCommonError, onFail: onError } = props;
    func(
      {
        setErrors,
        fetching,
        setCommonError,
        onError,
      },
      props
    );
  };
};

export default compose(adapterCommonRequestProcess, withCommonRequestProcess)(pullAutopayment);
