/**
 * 'Succeeded', 'Payment's View
 *
 * @flow
 */
import * as React from 'react';
import { compose } from 'recompose';

import CardsImage from '../../CardsImage';
import Button from '../../Controls/Button';
import { withCloseInFrame, withMobile } from '../../HOC';
import { Buttons, ButtonSpace, Wrapper as DetailsWrapper } from '../DetailsPanel';
import Panel from '../Panel';
import SavePayButton from '../SavePayButton';
import Field from './Field';
import { AdditionalBox, Cards, FieldBox, Header, Label, Wrapper } from './styled';

type TProps = {
  /** Номер абонента */
  fields?: Array<{
    label: string,
    value: string,
    key: string,
  }>,
  isSubmitButtonDisabled?: boolean,
  isSubmitButtonVisible?: boolean,
  submitButtonLabel?: string,
  message: string,
  onSubmit?: Function,
  onClose: Function,
  children: any /** React.ReactNode */,
  isMobile: boolean,
};

const Succeed = (props: TProps) => {
  return (
    <Wrapper data-testid={'block-result'}>
      <Panel width="580px">
        <Header>
          <Label data-testid={'block-result-label'}>{props.message || 'Успешно!'}</Label>
        </Header>
        {props.fields && (
          <FieldBox>
            {props.fields.map((field) => (
              <Field {...field} data-testid={'block-result-field'} />
            ))}
          </FieldBox>
        )}
        {props.children ? <AdditionalBox>{props.children}</AdditionalBox> : null}
      </Panel>
      <Panel alternate>
        <DetailsWrapper>
          <SavePayButton>
            <Buttons>
              {props.isSubmitButtonVisible && (
                <>
                  <Button
                    disabled={props.isSubmitButtonDisabled}
                    label={props.submitButtonLabel || ''}
                    onClick={props.onSubmit}
                    big={!props.isMobile}
                    data-testid={'block-result-button-submit'}
                  />
                  <ButtonSpace />
                </>
              )}
              <Button
                disabled={false}
                label={'Закрыть'}
                onClick={props.onClose}
                ghost
                big={!props.isMobile}
                data-testid={'block-result-button-close'}
              />
            </Buttons>
          </SavePayButton>

          <Cards>
            <CardsImage />
          </Cards>
        </DetailsWrapper>
      </Panel>
    </Wrapper>
  );
};

export default compose(withMobile, withCloseInFrame)(React.memo(Succeed));
