/**
 * 'Failed's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';
import { breakpoints, fonts } from '../../../Constants/css';

export const Wrapper = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
`;

export const Topic = styled.div`
  margin: 15px 0 0 0;

  font-family: ${fonts.main};
  font-size: 28px;
  font-weight: bold;
  text-align: center;

  color: #1f2229;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 22px;
  }
`;

export const Message = styled.div`
  margin: 22px 0 0 0;

  font-family: ${fonts.alternate};
  font-size: 22px;
  text-align: center;

  color: #1f2229;

  white-space: pre-line;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 16px;
  }
`;

export const Controls = styled.div`
  display: flex;
  margin: 143px 0 15px 0;
  width: 291px;

  flex: 1;
  flex-basis: auto; //Fix IE11

  @media (max-width: ${breakpoints.mobile}) {
    margin: 30px 0 0 0;
  }
`;
