import { Field } from 'formik';
import React, { useState } from 'react';
import TextInput from '../TextInput/FieldTextInput';
import { ElectronReceipt, ElectronReceiptButton, ElectronReceiptInput } from './styled';
import { images } from '../../../Constants/css';

const { emailReceipt, mobileReceipt } = images;

const FieldElectronReceiptButton = (props) => {
  return (
    <Field
      {...props}
      render={({ field, form }) => (
        <ElectronReceiptButton
          isInvalid={props.isInvalid}
          isFocused={props.focused}
          onClick={() => {
            form.setFieldValue(field.name, field.value === 'email' ? 'mobile' : 'email');
          }}
        >
          <img src={field.value === 'email' ? emailReceipt : mobileReceipt} width="22px" alt="" />
        </ElectronReceiptButton>
      )}
    />
  );
};

const ReceiptElectron = (props) => {
  const [focused, setFocused] = useState(false);

  const emailLabel = focused ? 'Ваш e-mail' : 'E-mail для отправки чека';
  const mobileLabel = focused ? 'Ваш номер телефона' : 'Номер телефона для отправки чека';

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = (event) => {
    if (!event.target.value) {
      setFocused(false);
    }
  };
  return (
    <>
      <ElectronReceipt>
        <ElectronReceiptInput>
          {props.type === 'email' && (
            <TextInput
              type={'email'}
              name={props.emailInputName}
              label={emailLabel}
              required
              onFocus={onFocus}
              onBlur={onBlur}
            />
          )}
          {props.type === 'mobile' && (
            <TextInput
              type={'tel'}
              maskType={'phoneFull'}
              name={props.mobileInputName}
              label={mobileLabel}
              required
              onFocus={onFocus}
              onBlur={onBlur}
            />
          )}
        </ElectronReceiptInput>
        {!props.hideButton && <FieldElectronReceiptButton name={props.receiptTypeRadioName} focused={focused} />}
      </ElectronReceipt>
    </>
  );
};

export default ReceiptElectron;
