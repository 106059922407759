import { checkCard, checkPaymentMeans } from '../Api/PaymentsMeans';
import { normalizeMsisdnToElevenNumbers, normalizePan, sliceExpirationDate } from '../Helpers/Normalize';

export default class PaymentsMeansService {
  static async checkPaymentMeans({ msisdn, ...restParams }) {
    return checkPaymentMeans({ msisdn: normalizeMsisdnToElevenNumbers(msisdn), ...restParams });
  }

  static async checkCard({ pan, expirationDate, ...restParams }) {
    const { expirationMonth, expirationYear } = sliceExpirationDate(expirationDate);

    return checkCard({ pan: normalizePan(pan), expirationMonth, expirationYear, ...restParams });
  }
}
