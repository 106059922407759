//@flow
import React from 'react';

import Failed from '../../../Components/Blocks/Failed';
import Request from '../../../Components/Blocks/Request';
import { withCloseInFrame } from '../../../Components/HOC';
import PaymentService from '../../../Services/PaymentService';

type TProps = {
  /**ID платежа статус которого надо проверить и отобразить на экране */
  paymentId: string,
  onSuccess: void,
};

type TState = {
  error: string,
  backLinkUrl: string,
};

class SelfRegistrationResult extends React.PureComponent<TProps, TState> {
  state = {
    error: '',
    backLinkUrl: '',
  };

  async resolvePayment() {
    const { paymentId } = this.props;
    let backLinkStatus;

    try {
      if (paymentId) {
        const { status, reason, backLink } = await PaymentService.getPaymentExactStatus({
          paymentId,
          expectedStatuses: ['Rejected', 'Hold'],
        });

        if (status === 'Hold') {
          backLinkStatus = 'success';
        } else {
          if (!backLink) {
            const error = reason ? reason.description : '';
            throw new Error(error);
          }
          backLinkStatus = 'error';
        }

        this.setState({
          backLinkUrl: `${backLink}${backLinkStatus}`,
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setState({
        error: error.message,
      });
    }
  }

  redirect() {
    const { backLinkUrl } = this.state;
    window.open(backLinkUrl, '_self');
  }

  componentDidMount() {
    this.resolvePayment();
  }

  _isRequest() {
    return this.props.paymentId && !this.state.error && !this.state.backLinkUrl;
  }

  _isPaymentStatus() {
    return this.state.backLinkUrl && !this.state.error;
  }

  render() {
    const { error } = this.state;

    const { onClose } = this.props;

    return (
      <>
        {this._isRequest() && <Request />}

        {this._isPaymentStatus() && this.redirect()}

        {error && <Failed message="Оплата заказа не выполнена!" buttonLabel={'Закрыть'} onClick={onClose} />}
      </>
    );
  }
}

export default withCloseInFrame(SelfRegistrationResult);
