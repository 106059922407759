/**
 * 'MaskedInput's mask patterns
 */
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import emailMask from 'text-mask-addons/dist/emailMask';

const d = /\d/;
const s = ' ';
const block = [d, d, d, d];

const maskMaker = (mask, nonCharactersList) => {
  return (raw) => {
    const length = raw.length;
    const nonDefault = nonCharactersList.some((characterIndex) => {
      return characterIndex === length;
    });
    return nonDefault ? mask.slice(0, length) : mask;
  };
};

export type TMaskType =
  | 'integer'
  | 'decimal'
  | 'onlyDecimal'
  | 'phoneFull'
  | 'phoneLiteStatic'
  | 'phoneLite'
  | 'cardNumber'
  | 'cardMonth'
  | 'cardYear'
  | 'cardDate'
  | 'cardCVC4'
  | 'cardCVC3'
  | 'cardCVC0'
  | 'email'
  | 'acceptCodeAsAmount'
  | 'subscriberAccount';

const patterns = {
  integer: {
    mask: createNumberMask({
      prefix: '',
      includeThousandsSeparator: false,
      allowLeadingZeroes: true,
    }),
  },
  amountInteger: {
    mask: (rawValue) => {
      rawValue = rawValue.replace(/^[0]+/, '');
      let nextMask = [/[1-9]/];
      if (rawValue.length) {
        nextMask = nextMask.concat(new Array(rawValue.length).fill(/\d/));
      }
      return nextMask;
    },
  },
  decimal: {
    mask: createNumberMask({
      prefix: '',
      includeThousandsSeparator: false,
      allowDecimal: true,
    }),
  },
  onlyDecimal: {
    mask: createNumberMask({
      prefix: '',
      includeThousandsSeparator: false,
      allowDecimal: true,
      requireDecimal: true,
    }),
  },
  phoneFull: {
    mask: ['+', '7', s, '(', /9|3/, d, d, ')', s, d, d, d, '-', d, d, '-', d, d],
    /**
     *
     * Known bug with static chars
     * @see https://github.com/text-mask/text-mask/issues/689
     */
    pipe: (conformedValue, config) => {
      const { rawValue } = config;
      if (!rawValue.includes('+7 ')) {
        const phoneNumber = rawValue.replace(/[^\d]+/g, '');
        const { length } = phoneNumber;
        const modifier = length - 10;
        const nextValue = phoneNumber.split('').reduce((nextValue, char, index) => {
          if (index === 0) {
            nextValue += '+7 (';
            char = /[^93]/.test(char) ? '' : char;
          }
          if (index === 1) {
            nextValue += '';
          }
          if (index === 3 + modifier) {
            nextValue += ') ';
          }
          if (index === 6 + modifier) {
            nextValue += '-';
          }
          if (index === 8 + modifier) {
            nextValue += '-';
          }
          nextValue += char;
          return nextValue;
        }, '');
        return nextValue;
      }

      return conformedValue;
    },
  },
  phoneLiteStatic: { mask: [d, d, d, s, d, d, d, '-', d, d, '-', d, d] },
  phoneLite: {
    mask: maskMaker([d, d, d, s, d, d, d, '-', d, d, '-', d, d], [3, 7, 10]),
  },
  cardNumber: {
    mask: [...block, s, ...block, s, ...block, s, ...block, s, d, d, d],
  },
  cardMonth: { mask: [d, d], pipe: createAutoCorrectedDatePipe('mm') },
  cardYear: { mask: [d, d], pipe: createAutoCorrectedDatePipe('yy') },
  cardDate: {
    mask: [d, d, '/', d, d],
    pipe: createAutoCorrectedDatePipe('mm/yy'),
  },
  cardCVC4: { mask: [d, d, d, d] },
  cardCVC3: { mask: [d, d, d] },
  cardCVC0: { mask: [] },
  email: emailMask,
  acceptCodeAsAmount: { mask: [/[1-9]/, '.', d, d] },
  subscriberAccount: { mask: [d, d, d, '-', d, d, d, '-', d, d, d, '-', d] },
};

export default patterns;
