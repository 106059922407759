import React from 'react';
import { compose } from 'recompose';

import Failed from '../../../../Components/Blocks/Failed';
import { withCloseInFrame, withErrorsHandle } from '../../../../Components/HOC';
import { formalizeMsisdn } from '../../../../Helpers/Normalize';
import SucceededWithAutopayment from '../Autopayment';
import SucceedWithBindCardForm from './Form';

const SucceedWithBindCard = (props) => {
  const { amount, message, fields, msisdn, paymentId, criticalError, handleError, onClose, paymentType } = props;

  const [isBound, setIsBound] = React.useState(false);

  const onBindCardSuccess = () => setIsBound(true);

  return (
    <>
      {criticalError && (
        <Failed
          topic={'Привязка карты не выполнена!'}
          message={props.criticalError}
          onClick={onClose}
          buttonLabel={'Закрыть'}
        />
      )}
      {!criticalError && !isBound && (
        <SucceedWithBindCardForm
          {...{
            msisdn,
            message,
            fields,
            paymentId,
            onBindCardSuccess,
            onBindCardFail: handleError,
          }}
        />
      )}
      {isBound && (
        <SucceededWithAutopayment
          {...{
            message: 'Карта успешно привязана',
            msisdn,
            amount,
            paymentId,
            paymentType,
            fields: [
              {
                label: 'Номер телефона',
                value: formalizeMsisdn(msisdn),
                key: 'field1',
              },
            ],
          }}
        />
      )}
    </>
  );
};

export default compose(withErrorsHandle, withCloseInFrame)(SucceedWithBindCard);
