import { compose } from 'recompose';
import { ValidationError } from '../../../../Constants/errors';
import withCommonRequestProcess from '../../../../Helpers/withCommonRequestProcess';
import PaymentService from '../../../../Services/PaymentService';

const pullBranches = async (props) => {
  const { setBranches } = props;
  const { branch } = props.values;
  const techBranches = [
    47, 63, 72, 76, 77, 84, 86, 87, 105, 90, 93, 41, 49, 56, 83, 81, 80, 92, 78, 79, 75, 73, 96, 88, 107, 108, 109, 113,
    40, 94, 98, 97, 104, 195, 114, 10975,
  ];

  let branches;

  try {
    branches = await PaymentService.getBranches();
    branches = branches
      .filter(({ branchId }) => !techBranches.includes(branchId))
      .sort((a, b) => a.branchId - b.branchId);
  } catch (e) {
    if (!(e instanceof ValidationError)) {
      throw new ValidationError({
        commonErrors: [`При запросе доступных услуг произошла ошибка, повторите запрос позднее.`],
      });
    }

    throw e;
  }

  setBranches(branches);

  if (branches && branches.length !== 0) {
    const foundedBranch = branches.find((obj) => obj.branchId == branch);
    if (foundedBranch) {
      props.setFieldValue('branch', branch);
    } else {
      props.setFieldValue('branch', undefined);
    }
  }
};

const adapterCommonRequestProcess = (func) => {
  return (props) => {
    const { setErrors, fetching, setCommonError, onFail: onError } = props;
    func(
      {
        setErrors,
        fetching,
        setCommonError,
        onError,
      },
      props
    );
  };
};

export default compose(adapterCommonRequestProcess, withCommonRequestProcess)(pullBranches);
