//
// @flow
//
import { api, parseApiResponse } from '../common';
import type {
  TGetSubscriberRequest,
  TGetSubscriberResponse,
  TCreateSubscriberRequest,
  TCreateSubscriberResponse,
  TSendAcceptCodeToSubscriberRequest,
  TSendAcceptCodeToSubscriberResponse,
  TConfirmAcceptCodeOfSubscriberRequest,
  TConfirmAcceptCodeOfSubscriberResponse,
  TGetCardOfSubscriberRequest,
  TGetCardOfSubscriberResponse,
  TAddCardToSubscriberRequest,
  TAddCardToSubscriberResponse,
  TConfirmAcceptCodeOfSubscriberWithPaymentRequest,
  TConfirmAcceptCodeOfSubscriberWithPaymentResponse,
  TSubscriberConfirmBindCardByLoopRequest,
  TGetB2bSubscriberRequest,
  TGetB2bSubscriberResponse,
  TCreateB2bSubscriberRequest,
  TCreateB2bSubscriberResponse,
} from '../../Constants/types';

const SUBSCRIBERS = '/subscribers';
const B2BSUBSCRIBERS = '/b2bSubscribers';

/**
 * Получение идентификатора абонента по его msisdn
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=44728657
 */
export const getSubscriber = async function (requestData: TGetSubscriberRequest): Promise<TGetSubscriberResponse> {
  const { msisdn } = requestData;

  const response = await api.get(`${SUBSCRIBERS}?msisdn=${msisdn}`);

  return parseApiResponse(response);
};

/**
 * Создание нового абонента по его msisdn
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=44075430
 */
export const createSubscriber = async function (
  requestData: TCreateSubscriberRequest
): Promise<TCreateSubscriberResponse> {
  const { msisdn } = requestData;

  const response = await api.post(SUBSCRIBERS, { msisdn });

  return parseApiResponse(response);
};

/**
 * Получение идентификатора b2b абонента по его msisdn
 *
 * doc: https://confluence.paysystem.tech/pages/viewpage.action?pageId=52691608
 */
export const getB2bSubscriber = async function (
  requestData: TGetB2bSubscriberRequest
): Promise<TGetB2bSubscriberResponse> {
  const { msisdn } = requestData;

  const response = await api.get(`${B2BSUBSCRIBERS}?msisdn=${msisdn}`);

  return parseApiResponse(response);
};

/**
 * Создание нового b2b абонента по его msisdn
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=52691621
 */
export const createB2bSubscriber = async function (
  requestData: TCreateB2bSubscriberRequest
): Promise<TCreateB2bSubscriberResponse> {
  const { msisdn, clientType } = requestData;

  const response = await api.post(B2BSUBSCRIBERS, { msisdn, clientType });

  return parseApiResponse(response);
};

/**
 * Оправка абоненту кода подтверждения
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=44075464
 */
export const sendAcceptCodeToSubscriber = async function (
  requestData: TSendAcceptCodeToSubscriberRequest
): Promise<TSendAcceptCodeToSubscriberResponse> {
  const { subscriberId } = requestData;

  const response = await api.post(`${SUBSCRIBERS}/${subscriberId}/accept-code/send`);

  return parseApiResponse(response);
};

/**
 * Проверка у абонента кода подтверждения
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=44075469
 */
export const confirmAcceptCodeOfSubscriber = async function (
  requestData: TConfirmAcceptCodeOfSubscriberRequest
): Promise<TConfirmAcceptCodeOfSubscriberResponse> {
  const { subscriberId, cardId, acceptCodeId, acceptCode } = requestData;

  const response = await api.post(
    `${SUBSCRIBERS}/${subscriberId}/cards/${cardId}/accept-codes/${acceptCodeId}/confirm`,
    { acceptCode }
  );

  return parseApiResponse(response);
};

/**
 * Проверка у абонента кода подтверждения при оплате через СБП
 *
 * doc: https://wiki.tele2.ru/pages/viewpage.action?pageId=298024251
 */
export const confirmSbpAcceptCodeOfSubscriber = async function (requestData): Promise<*> {
  const { subscriberId, sbpTokenId, acceptCodeId, acceptCode } = requestData;

  const response = await api.post(
    `${SUBSCRIBERS}/${subscriberId}/sbpTokens/${sbpTokenId}/accept-codes/${acceptCodeId}/confirm`,
    { acceptCode }
  );

  return parseApiResponse(response);
};

/**
 * Проверка у абонента кода подтверждения после оплаты (т.е. с платежем)
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=45321706
 */
export const confirmAcceptCodeOfSubscriberWithPayment = async function (
  requestData: TConfirmAcceptCodeOfSubscriberWithPaymentRequest
): Promise<TConfirmAcceptCodeOfSubscriberWithPaymentResponse> {
  const { subscriberId, paymentId, acceptCodeId, acceptCode } = requestData;

  const response = await api.post(`${SUBSCRIBERS}/${subscriberId}/bind-card-by-successful-payment`, {
    paymentId,
    acceptCodeId,
    acceptCode,
  });

  return parseApiResponse(response);
};

/**
 * Получение списка карт абонента
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=44729241
 */
export const getCardOfSubscriber = async function (
  requestData: TGetCardOfSubscriberRequest
): Promise<TGetCardOfSubscriberResponse> {
  const { subscriberId, pan, expirationMonth, expirationYear } = requestData;

  const response = await api.get(
    `${SUBSCRIBERS}/${subscriberId}/cards?pan=${pan}&experationMonth=${expirationMonth}&expirationYear=${expirationYear}`
  );

  return parseApiResponse(response);
};

/**
 * Добавление карты к профилю абонента
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=44729250
 */
export const addCardToSubscriber = async function (
  requestData: TAddCardToSubscriberRequest
): Promise<TAddCardToSubscriberResponse> {
  const { subscriberId, ...card } = requestData;

  const response = await api.post(`${SUBSCRIBERS}/${subscriberId}/cards`, card);

  return parseApiResponse(response);
};

/**
 * Подтверждение операции привязки карты кодом в виде заблокированной суммы
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=45323551
 */
export const confirmBindCardByLoop = async function (
  requestData: TSubscriberConfirmBindCardByLoopRequest
): Promise<void> {
  const { subscriberId, ...acceptCodeData } = requestData;

  const response = await api.post(`${SUBSCRIBERS}/${subscriberId}/bind-card-by-successful-loop`, acceptCodeData);

  return parseApiResponse(response);
};

/**
 * Добавление СБП токена к профилю абонента
 *
 * doc: https://wiki.tele2.ru/pages/viewpage.action?pageId=297996785
 */
export const addSbpTokenToSubscriber = async function (requestData): Promise<*> {
  const { subscriberId, ...card } = requestData;

  const response = await api.post(`${SUBSCRIBERS}/${subscriberId}/sbptokens`, card);

  return parseApiResponse(response);
};
