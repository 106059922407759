/**
 *
 * @flow
 */
import styled from 'styled-components';
import { colors, breakpoints } from '../../../Constants/css';
import { Icon } from '../RadioIcon/styled';

const { border } = colors;

export const TabButton = styled.div`
  min-height: 52px;
  display: inline-flex

  align-items: center;
  border-radius: 6px;
  padding: 8px 11px 8px 7px;
  word-break: break-all;
  will-change: border-color;
  cursor: pointer;
  transition: 0.4s;
  @media (min-width: ${breakpoints.mobile}) {
    ${p => p.width && `width: ${p.width}`}
    padding: 8px 5px 8px 5px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 8px 5px 8px 5px;
  }

  ${p => (p.checked ? `border: 2px solid ${border.main};` : `border: 2px solid ${border.second};`)}

  &:hover {
    border-color: ${border.main};
  }

  &:hover ${Icon} {
    border-color: ${border.main};
  }
`;

export const Label = styled.div`
  margin-right: 5px;
  font-size: 14px;
`;

export const Image = styled.div`
  width: 32px;
  height: 32px;
  background-image: url(${p => p.src});
  background-repeat: no-repeat;
  background-position: center;
`;

export const LabelContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;
