import React from 'react';

import AcceptCode from '../../../../../Components/Blocks/AcceptCode';
import FieldTextInput from '../../../../../Components/Controls/TextInput/FieldTextInput';
import { formalizeMsisdn } from '../../../../../Helpers/Normalize';

const BindCode = (props) => {
  const { msisdn, remainingQuantity, isBind, fieldName } = props;

  if (isBind) {
    const hint = remainingQuantity ? `У вас осталась ${remainingQuantity} попытки ввода` : '';
    return (
      <AcceptCode label={`SMS-код отправлен на номер ${formalizeMsisdn(msisdn)}`} hint={hint}>
        <FieldTextInput name={fieldName} label={'Код'} type={'tel'} required maxLength={'4'} maskType={'integer'} />
      </AcceptCode>
    );
  }
  return null;
};

export default React.memo(BindCode);
