import { NotFoundError } from '../Constants/errors';

const interceptorUrlExceptions = ['/3ds/prepare', '/external/sbp/prepare', '/external/sberpay/prepare'];

/* проверяет объект пустой или нет */

export function isObjEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return true;
}

export const verifyInterceptedUrl = (captchaHeaders, config) => {
  const isInterceptionVerified =
    !isObjEmpty(captchaHeaders) &&
    config.method === 'post' &&
    interceptorUrlExceptions.reduce((accum, value) => accum || config.url.indexOf(value) !== -1, false);

  return isInterceptionVerified;
};

/**
 * Функция debounce "подавление дребезга"
 */
export const debounce = (fn, ms) => {
  let timeout;

  return function () {
    const fnCall = () => fn.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(fnCall, ms);
  };
};

export const checkSelectedCaptcha = (isCaptchaEnabled, selectedCaptcha, captchaToCheck) =>
  isCaptchaEnabled && selectedCaptcha === captchaToCheck;

export const pollStatus = async (fn, config) => {
  const { tries, timeout, expectedStatuses } = config;

  const MAX_COUNT = tries ? tries : 50;
  const TIMEOUT = timeout ? timeout : 1000;

  let count = 1;
  let notFoundCount = 1;

  let responseData = {};

  while (!expectedStatuses.includes(responseData.status) && count <= MAX_COUNT) {
    try {
      if (count !== 1) {
        await new Promise((next) => setTimeout(next, TIMEOUT));
      }

      responseData = await fn();
      count++;
    } catch (error) {
      // Защита в случае проблем на сервере. Допускаем только один раз
      if (error instanceof NotFoundError) {
        if (notFoundCount === 1) {
          notFoundCount++;
          continue;
        }
      }

      throw error;
    }
  }

  return responseData;
};

export const iframeCb = (action, data = {}) => {
  try {
    window.top.postMessage(JSON.stringify({ id: action, ...data }), '*');
  } catch {}
};
export const iOsCb = (action, data = {}) => {
  try {
    window.webkit.messageHandlers.callback.postMessage({ action, ...data });
  } catch {}
};
export const androidCb = (action, data) => {
  try {
    window.Android.callback(action, data);
  } catch {}
};

export const getQueryParamsString = () => {
  const queryParamsIndex = window.location.href.indexOf('?');
  const hasQueryParams = queryParamsIndex !== -1;

  if (hasQueryParams) {
    const queryParams = window.location.href.slice(queryParamsIndex + 1);

    return queryParams;
  } else {
    return null;
  }
};

export const getArrayFromString = (string, separator = ';') => (string ? string.split(separator) : []);
