import { ValidationError } from '../Constants/errors';

const withCommonRequestProcess =
  (func) =>
  async ({ setErrors, fetching: { start, stop }, setCommonError, onError }, ...args) => {
    setCommonError('');
    start();

    try {
      await func(...args);
    } catch (error) {
      console.error(error);
      if (error instanceof ValidationError) {
        // устанавливаем валидационные ошибки
        if (error.fieldErrors && Object.keys(error.fieldErrors).length > 0) {
          setErrors({
            ...error.fieldErrors,
          });
        }

        if (error.commonErrors && error.commonErrors.length > 0) {
          setCommonError(error.commonErrors.join('/n'));
        }
      } else {
        onError(error);
      }
    }

    stop();
  };

export default withCommonRequestProcess;
