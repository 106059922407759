import { compose } from 'recompose';
import { NotFoundError, ValidationError } from '../../../../../Constants/errors';
import withCommonRequestProcess from '../../../../../Helpers/withCommonRequestProcess';
import AutopaymentService from '../../../../../Services/AutopaymentService';
import PaymentService from '../../../../../Services/PaymentService';
import { CATEGORY_SUBSCRIPTIONFEE } from '../Form';
import { pullSubscriptionFee } from './pullSubscriptionFee';
import { setCardId } from './pushAutopayment';

const pullAutopayment = async (props) => {
  const {
    msisdn,
    paymentId,
    values,
    paymentType,
    setValues,
    setTypes,
    setCategories,
    amount,
    categories,
    types,
    setFieldValue,
  } = props;

  if (!values.withAutopayment && !categories.length && !types.length) {
    let categories;
    let types;

    const { cardId } = await PaymentService.getCard({ paymentId });

    try {
      const [c, t] = await Promise.all([
        AutopaymentService.getCategories({ msisdn, isB2b: false }),
        AutopaymentService.getTypes({ msisdn, isB2b: false }),
      ]);

      categories = c;
      types = t;
    } catch (e) {
      let commonErrors = [`При запросе доступных услуг произошла ошибка, повторите запрос позднее.`];
      if (e instanceof NotFoundError) {
        commonErrors = e.commonErrors;
      }

      throw new ValidationError({
        commonErrors,
      });
    }

    const defaultCategoryItem = categories.find((category) => category.isDefault);
    const firstInListCategoryItem = categories.length ? categories[0] : null;
    const category = defaultCategoryItem
      ? defaultCategoryItem.billingServiceCategoryId
      : firstInListCategoryItem
      ? firstInListCategoryItem.billingServiceCategoryId
      : '';

    const defaultTypeItem = types.find((type) => type.billingServiceCategory.id === category && type.isDefault);
    const firstInListTypeItem = types.find((type) => type.billingServiceCategory.id === category);
    const type = defaultTypeItem
      ? defaultTypeItem.billingServiceId
      : firstInListTypeItem
      ? firstInListTypeItem.billingServiceId
      : '';

    setValues({
      ...values,
      amount: paymentType === 'topup' ? (Number(amount) > 3000 ? 3000 : amount) : undefined,
      type,
    });
    setTypes(types);
    setCategories(categories);
    setCardId(cardId);

    if (category === CATEGORY_SUBSCRIPTIONFEE) {
      await pullSubscriptionFee(props);
    } else {
      setFieldValue('category', category);
    }
  }

  setFieldValue('withAutopayment', !values.withAutopayment);
};

const adapterCommonRequestProcess = (func) => {
  return (props) => {
    const { setErrors, fetching, setCommonError, onFail: onError } = props;
    func(
      {
        setErrors,
        fetching,
        setCommonError,
        onError,
      },
      props
    );
  };
};

export default compose(adapterCommonRequestProcess, withCommonRequestProcess)(pullAutopayment);
