import styled from 'styled-components';
import { breakpoints } from '../../../Constants/css';

export const FieldWidth = styled.div`
  @media (min-width: ${breakpoints.mobile}) {
    min-width: ${(p) => p.width};
  }
`;

export const CardFieldsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  > :first-child {
    margin-right: 16px;
  }
`;
