/**
 * 'AcceptCode's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';
import { breakpoints } from '../../../Constants/css';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: 0;

  flex: 1;
  flex-basis: auto; //Fix IE11
`;

export const Box = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const Label = styled.div`
  display: flex;
  margin: 0 0 0 18px;

  height: 60px;

  flex: 1;
  flex-basis: 100%; //Fix IE11
  align-items: center;

  font-size: 14px;

  color: #191818;

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0 0 15px 0;

    flex: 1;
    flex-basis: auto; //Fix IE11
    order: 1;
    /* align-self: center; */

    font-size: 13px;
  }
`;

export const Input = styled.div`
  max-width: 286px;

  flex: 1;
  flex-basis: auto; //Fix IE11

  @media (max-width: ${breakpoints.mobile}) {
    max-width: none;
    order: 2;
  }
`;

export const Hint = styled.div`
  align-self: flex-start;

  font-size: 16px;
  color: #7a7a7a;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 14px;
  }
`;
