import { useCallback, useState } from 'react';
import { useAppDispatch } from '../Redux/reducers';
import { setYandexCaptchaValues } from '../Redux/actions';

const useYandexCaptcha = (props) => {
  const dispatch = useAppDispatch();

  const [visible, setVisible] = useState(false);
  const [resetToken, setResetToken] = useState(1);

  const handleReset = useCallback(() => setResetToken((prev) => prev + 1), []);
  const handleYandexCaptchaVerify = useCallback(() => setVisible(true), []);

  const handleChallengeHidden = useCallback(() => setVisible(false), []);
  const handleSuccess = useCallback(
    (token) => {
      dispatch(setYandexCaptchaValues({ token }));
      setVisible(false);
      handleReset();
    },
    [handleReset]
  );

  return { handleYandexCaptchaVerify, visible, resetToken, handleChallengeHidden, handleSuccess, handleReset };
};

export default useYandexCaptcha;
