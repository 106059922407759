/**
 * 'DatePicker' Control Component
 *
 * @flow
 */
import React from 'react';

import { default as ReactDatePicker } from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/ru';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

import TextInput from '../TextInput';
import FieldDatePicker from './FieldDatePicker';

export { FieldDatePicker };

type TProps = {
  /** название для input поля */
  label: string,
  /** флаг активности/неактивности */
  disabled?: boolean,
  /** Callback при изменении значения  */
  onChange: (date: moment$Moment) => void,
  /** текущая выбранная дата (moment формат) */
  value: ?moment$Moment,
  /** минимальная возможная дата (moment формат) */
  minDate?: moment$Moment,
  /** имя контрола */
  name?: string,
};

export default function DatePicker(props: TProps) {
  const { disabled, onChange, value, minDate, name, ...restProps } = props;

  return (
    <div className="data-picker-container">
      <ReactDatePicker
        required
        readOnly
        disabled={disabled}
        dateFormat="DD.MM.YYYY"
        dateFormatCalendar={'MMMM YYYY'}
        locale="ru"
        minDate={minDate ? minDate : moment()}
        name={name}
        customInput={<TextInput iconName={'calendar'} iconSize={28} readOnly {...restProps} hideErrorOnFocus />}
        selected={value}
        onChange={onChange}
      />
    </div>
  );
}
