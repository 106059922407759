import React from 'react';

import { Buttons } from '../DetailsPanel';
import { ButtonBox, ButtonWrapper } from './styled';

const SavePayButton = props => {
  return (
    <ButtonWrapper>
      <ButtonBox>
        <Buttons>{props.children}</Buttons>
      </ButtonBox>
    </ButtonWrapper>
  );
};

export default React.memo(SavePayButton);
