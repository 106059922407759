import React from 'react';
import { compose } from 'recompose';

import Layout from '../../Components/Blocks/Layout';
import { withErrorsHandle, withQueryParams } from '../../Components/HOC';
import B2bResult from './AddAutopaymentResult';

class AddAutopaymentPage extends React.PureComponent {
  render() {
    return (
      <Layout title={'Подключение автоплатежа и привязка банковской карты'}>
        {this.props.queryParams.paymentid && (
          <B2bResult failedMessage={'Карта не привязана!'} paymentId={this.props.queryParams.paymentid} />
        )}
      </Layout>
    );
  }
}

export default compose(withQueryParams, withErrorsHandle)(AddAutopaymentPage);
