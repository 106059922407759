/**
 * @flow
 */
import React from 'react';
import { Icon } from './styled';

type TProps = {
  checked?: boolean,
  disabled?: boolean,
  size?: string,
};

const RadioIcon = (props: TProps) => {
  const { disabled, checked, size } = props;

  return <Icon checked={checked} disabled={disabled} size={size} />;
};

RadioIcon.defaultProps = {
  disabled: false,
  checked: false,
  size: 'small',
};

export default RadioIcon;
