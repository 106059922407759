/**
 * Bank Cards Validation
 *
 * @flow
 */
export type TRule = {
  name: string,
  pattern: RegExp,
  validLength: number[],
  cvcDigits: number,
};

const bankCards = [
  {
    name: 'amex',
    pattern: /^3[47]/,
    validLength: [15],
    cvcDigits: 4,
  },
  {
    name: 'jcb',
    pattern: /^35(2[89]|[3-8][0-9])/,
    validLength: [16],
    cvcDigits: 3,
  },
  {
    name: 'laser',
    pattern: /^(6304|670[69]|6771)/,
    validLength: [16],
    cvcDigits: 0,
  },
  {
    name: 'visa_electron',
    pattern: /^(4026|417500|4508|4844|491(3|7))/,
    validLength: [16],
    cvcDigits: -3, // отрицательное значение говорит о том, что может не быть cvv
  },
  {
    name: 'visa',
    pattern: /^4/,
    validLength: [16, 19],
    cvcDigits: 3,
  },
  {
    name: 'mir',
    pattern: /^(220[0-4])/,
    validLength: [16, 17, 18, 19],
    cvcDigits: 3,
  },
  {
    name: 'mastercard',
    pattern: /^(5[1-5]\d{14})|(2(((2)((2[1-9])|([3-9][0-9])))|([3-6][0-9][0-9])|(7)(([0-1][0-9])|(20)))\d{12})$/,
    validLength: [16],
    cvcDigits: 3,
  },
  {
    name: 'maestro',
    pattern: /^(5[06-8]|639|67)/,
    validLength: [15, 16, 17, 18, 19],
    cvcDigits: -3,
  },
  {
    name: 'discover',
    pattern: /^(6011|64|65)/,
    validLength: [16],
    cvcDigits: 0,
  },
  {
    name: 'Dankort 5019 [15] 4571 (Visa co-branded) [15]',
    pattern: /^(5019|4571)/,
    validLength: [16],
    cvcDigits: 3,
  },
  {
    name: 'Verve - 506099–506198, 650002–650027',
    pattern: /^(506099|5061|6500[0-2][2-7])/,
    validLength: [16, 19],
    cvcDigits: 3,
  },
  {
    name: 'MC maestro, остаток от Verve **-506198',
    pattern: /^506199/,
    validLength: [16, 17, 18, 19],
    cvcDigits: -3,
  },
  {
    name: 'RuPay - 60, 6521',
    pattern: /^(60|6521)/,
    validLength: [16],
    cvcDigits: 3,
  },
  {
    name: 'China UnionPay - 62',
    pattern: /^62/,
    validLength: [16, 17, 18, 19],
    cvcDigits: 3,
  },
  {
    name: 'InterPayment - 636',
    pattern: /^636/,
    validLength: [16, 17, 18, 19],
    cvcDigits: 3,
  },
  {
    name: 'InstaPayment - 637–639',
    pattern: /^63[7-9]/,
    validLength: [16],
    cvcDigits: 3,
  },
  {
    name: 'diners_club_carte_blanche',
    pattern: /^30[0-5]/,
    validLength: [14],
    cvcDigits: 0,
  },
  {
    name: 'diners_club_international',
    pattern: /^36/,
    validLength: [14],
    cvcDigits: 0,
  },
];

export default bankCards;
