import SubscriberService from '../../../../Services/SubscriberService';
import AcceptCodeService from '../../../../Services/AcceptCodeService';

let paymentId = null;
let acceptCodeId = null;
let subscriberId = null;

export const setPaymentId = (nextPaymentId) => {
  paymentId = nextPaymentId;
};

export const setAcceptCodeId = (nextAcceptCodeId) => {
  acceptCodeId = nextAcceptCodeId;
};
export const setSubscriberId = (nextSubscriberId) => {
  subscriberId = nextSubscriberId;
};

export default async (values, { props, setFieldTouched, setFieldValue }) => {
  let { acceptCode } = values;
  let { isAlien } = props;

  try {
    await SubscriberService.confirmBindCardByLoop({
      subscriberId,
      paymentId,
      acceptCodeId,
      acceptCode,
    });

    let isAutopayment = props.categoryId && props.amount && props.serviceId;

    props.onLoopSuccess(paymentId, isAutopayment, isAlien);
  } catch (error) {
    const { remainingQuantity } = await AcceptCodeService.getAcceptCodeStatus({
      acceptCodeId,
    });
    props.setRemainingQuantity(remainingQuantity);
    throw error;
  }
};
