import { withFormik as withFormikOriginal } from 'formik';
import yup from 'yup';

export default function withFormik(formikOptions, schemas) {
  let { mapPropsToValues, validationSchema, ...restFormikOptions } = formikOptions;
  if (schemas) {
    validationSchema = ({ values }) => {
      return yup.object(
        Object.keys(values).reduce((acc, key) => {
          if (schemas[`${key}Schema`]) {
            acc[key] = schemas[`${key}Schema`];
          }

          return acc;
        }, {})
      );
    };
  }

  if (!mapPropsToValues) {
    mapPropsToValues = ({ values }) =>
      Object.keys(values).reduce((acc, key) => {
        acc[key] = values[key];

        return acc;
      }, {});
  }

  return (Component) =>
    withFormikOriginal({
      mapPropsToValues,
      validationSchema,
      ...restFormikOptions,
    })(Component);
}
