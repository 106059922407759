import { compose } from 'recompose';
import { ValidationError } from '../../../../../../Constants/errors';
import withCommonRequestProcess from '../../../../../../Helpers/withCommonRequestProcess';
import AcceptCodeService from '../../../../../../Services/AcceptCodeService';
import SubscriberService from '../../../../../../Services/SubscriberService';

let subscriberId;
let acceptCodeId;

export const setSubscriberId = nextSubscriberId => (subscriberId = nextSubscriberId);
export const setAcceptCodeId = nextAcceptCodeId => (acceptCodeId = nextAcceptCodeId);

const bindCard = async (values, formikBag) => {
  const { paymentId, onBindCardSuccess, setRemainingQuantity } = formikBag.props;
  const { acceptCode } = values;

  try {
    await SubscriberService.confirmAcceptCodeWithPayment({
      subscriberId,
      acceptCodeId,
      paymentId,
      acceptCode,
    });

    onBindCardSuccess();
  } catch (error) {
    if (error instanceof ValidationError) {
      const { remainingQuantity } = await AcceptCodeService.getAcceptCodeStatus({ acceptCodeId });
      setRemainingQuantity(remainingQuantity);
    }

    throw error;
  }
};

const adapterCommonRequestProcess = func => (values, formikBag) => {
  const { fetching, setCommonError, onBindCardFail: onError } = formikBag.props;
  const { setErrors } = formikBag;
  func({ fetching, setCommonError, onError, setErrors }, values, formikBag);
};

export default compose(
  adapterCommonRequestProcess,
  withCommonRequestProcess
)(bindCard);
