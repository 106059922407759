import AutopaymentService from '../../../Services/AutopaymentService';

export const CATEGORY_PERIODIC = 1;
export const CATEGORY_THRESHOLD = 2;
export const CATEGORY_ACCOUNT = 3;
export const CATEGORY_SUBSCRIPTIONFEE = 4;

const pushAutopayments = async values => {
  const { cardId, msisdn, amount, firstDateUtc, billingServiceId, category } = values;

  switch (category) {
    case CATEGORY_THRESHOLD: {
      await AutopaymentService.setThreshold({
        msisdn,
        cardId,
        amount,
        billingServiceId,
      });
      break;
    }
    case CATEGORY_PERIODIC: {
      await AutopaymentService.setPeriodic({
        msisdn,
        cardId,
        amount,
        billingServiceId,
        firstDateUtc,
      });
      break;
    }
    case CATEGORY_SUBSCRIPTIONFEE: {
      await AutopaymentService.setSubscriptionFee({
        msisdn,
        billingServiceId,
        amount,
        cardId,
      });
      break;
    }
    case CATEGORY_ACCOUNT: {
      await AutopaymentService.setAccount({
        billingServiceId,
        cardId,
      });
      break;
    }
    default: {
    }
  }
};

export default pushAutopayments;
