//
// @flow
//

import type {
  TGetCategoriesRequest,
  TGetCategoriesResponse,
  TGetTypesRequest,
  TGetTypesResponse,
  TSetThresholdRequest,
  TSetThresholdResponse,
  TSetPeriodicRequest,
  TSetPeriodicResponse,
  TSetAccountRequest,
  TSetAccountResponse,
  TGetSubscriptionFeeRequest,
  TSetSubscriptionFeeRequest,
  TGetSubscriptionFeeResponse,
  TSetSubscriptionFeeResponse,
  TAutopaymentСonfirmRequest,
  TAutopaymentСonfirmResponse,
} from '../Constants/types';

import {
  getCategories,
  getSubscriptionFee,
  getTypes,
  setPeriodic,
  setSubscriptionFee,
  setThreshold,
  autopaymentСonfirm,
  setAccount,
} from '../Api/Autopayments';
import { convertIntegerRublesToKopecks } from '../Helpers/Currency';
import { normalizeMsisdnToElevenNumbers } from '../Helpers/Normalize';

export default class AutopaymentService {
  static async getCategories({ msisdn, isB2b }: TGetCategoriesRequest): Promise<TGetCategoriesResponse> {
    return await getCategories({
      msisdn: normalizeMsisdnToElevenNumbers(msisdn),
      isB2b: isB2b,
    });
  }

  static async getTypes({ msisdn, isB2b }: TGetTypesRequest): Promise<TGetTypesResponse> {
    return await getTypes({
      msisdn: normalizeMsisdnToElevenNumbers(msisdn),
      isB2b: isB2b,
    });
  }

  static async setAccount({ billingServiceId, cardId }: TSetAccountRequest): Promise<TSetAccountResponse> {
    return await setAccount({
      billingServiceId,
      cardId,
    });
  }

  static async setThreshold({
    amount,
    msisdn,
    ...restRequestData
  }: TSetThresholdRequest): Promise<TSetThresholdResponse> {
    return await setThreshold({
      amount: convertIntegerRublesToKopecks(amount),
      msisdn: normalizeMsisdnToElevenNumbers(msisdn),
      ...restRequestData,
    });
  }

  static async setPeriodic({ amount, msisdn, ...restRequestData }: TSetPeriodicRequest): Promise<TSetPeriodicResponse> {
    return await setPeriodic({
      amount: convertIntegerRublesToKopecks(amount),
      msisdn: normalizeMsisdnToElevenNumbers(msisdn),
      ...restRequestData,
    });
  }

  static async getSubscriptionFee({ msisdn }: TGetSubscriptionFeeRequest): Promise<TGetSubscriptionFeeResponse> {
    return await getSubscriptionFee({
      msisdn: normalizeMsisdnToElevenNumbers(msisdn),
    });
  }

  static async setSubscriptionFee({
    msisdn,
    amount,
    ...restRequestData
  }: TSetSubscriptionFeeRequest): Promise<TSetSubscriptionFeeResponse> {
    return await setSubscriptionFee({
      msisdn: normalizeMsisdnToElevenNumbers(msisdn),
      amount: convertIntegerRublesToKopecks(amount),
      ...restRequestData,
    });
  }

  static async autopaymentСonfirm({ id, code }: TAutopaymentСonfirmRequest): Promise<TAutopaymentСonfirmResponse> {
    return await autopaymentСonfirm({
      id: id,
      code: code,
    });
  }
}
