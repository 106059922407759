/**
 * 'CardBinder's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';
import { breakpoints, fonts } from '../../../../../Constants/css';

export const Wrapper = styled.div`
  display: flex;

  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;

  align-items: center;
`;

export const Title = styled.div`
  font-family: ${fonts.alternate};
  font-weight: bold;
  font-size: 16px;
`;

export const Separator = styled.div`
  flex: 1;
  flex-basis: auto; //Fix IE11
`;

export const AcceptCodeBox = styled.div`
  display: flex;
  margin: 15px 0 0 0;

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const Input = styled.div`
  max-width: 160px;

  flex: 1;
  flex-basis: auto; //Fix IE11

  @media (max-width: ${breakpoints.mobile}) {
    max-width: 100%;

    order: 2;
  }
`;
export const Label = styled.div`
  display: flex;
  margin: 0 0 0 18px;

  flex: 1;
  flex-basis: auto; //Fix IE11
  align-items: center;

  font-size: 14px;

  color: #191818;

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0 0 15px 0;

    flex: 1;
    flex-basis: auto; //Fix IE11
    order: 1;
    align-self: center;

    font-size: 13px;
  }
`;

export const Hint = styled.div`
  margin: 4px 0 0 0px;
  align-self: flex-start;
  font-size: 16px;
  color: #7a7a7a;
`;
