import React from 'react';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha/index';
import { yandexCaptchaSiteKey } from '../../../Constants';

const YandexCaptcha = (props) => {
  const { onSuccess, visible, resetToken, onChallengeHidden, onTokenExpired } = props;

  return (
    <InvisibleSmartCaptcha
      key={resetToken}
      sitekey={yandexCaptchaSiteKey}
      language="ru"
      onSuccess={onSuccess}
      onChallengeHidden={onChallengeHidden}
      onTokenExpired={onTokenExpired}
      visible={visible}
      shieldPosition="bottom-right"
    />
  );
};

export default YandexCaptcha;
