import { compose } from 'recompose';
import { convertIntegerRublesToKopecks } from '../../../../Helpers/Currency';
import { normalizeMsisdnToElevenNumbers } from '../../../../Helpers/Normalize';
import PaymentService from '../../../../Services/PaymentService';
import withCommonRequestProcess from '../../../../Helpers/withCommonRequestProcess';

const sberPay = async (props) => {
  const { values } = props;
  const { msisdn, amount, email } = values;
  const { channel } = props;

  const { paymentId } = await PaymentService.prepareSberPay(
    {
      msisdn,
      amount,
    },
    { channel }
  );

  props.onSberPaySuccess(paymentId, {
    msisdn: normalizeMsisdnToElevenNumbers(msisdn),
    amount: convertIntegerRublesToKopecks(amount),
    email,
  });
};

const adapterCommonRequestProcess = (func) => {
  return (props) => {
    const { setErrors, fetching, setCommonError, onError } = props;
    func(
      {
        setErrors,
        fetching,
        setCommonError,
        onError,
      },
      props
    );
  };
};

export default compose(adapterCommonRequestProcess, withCommonRequestProcess)(sberPay);
