/**
 * 'Select', Control Component
 *
 * @flow
 */
import React from 'react';
import ReactSelect from 'react-select';
import 'react-select/dist/react-select.css';
import './reset.css';

import { Arrow, SelectWrapper, SelectedValue } from './styled';
import { MainStyle, MenuContainerStyle, MenuStyle } from './reactSelectStyles';

import Option from './Option';

export type TOption = {
  label: string,
  value: string,
};

type TProps = {
  /** Значение по умолчанию */
  value: string,
  /** Значение списка в виде
   * [{label: 'Display Label', value: 'option value'}, ...]
   **/
  options: TOption[],
  /** Callback при изменении значения  */
  onChange: (option: TOption) => void,
};

export default function Select(props: TProps) {
  const disabled = Boolean(props.disabled);
  return (
    <SelectWrapper disabled={disabled}>
      <ReactSelect
        style={MainStyle}
        menuContainerStyle={MenuContainerStyle}
        menuStyle={MenuStyle}
        valueRenderer={(option) => <SelectedValue disabled={disabled}>{option.label}</SelectedValue>}
        optionComponent={Option}
        arrowRenderer={(p) => <Arrow {...p} />}
        clearable={false}
        placeholder={null}
        disabled={disabled}
        searchable={Boolean(props.searchable)}
        {...props}
      />
    </SelectWrapper>
  );
}
