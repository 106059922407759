// @flow

import { Field } from 'formik';
import React, { useState } from 'react';
import Select from './index';
import { Wrapper, Label } from './styled';
import type { TOption } from './index';
import type { FieldProps } from 'formik';

type TValue = {
  label: string,
  value: string,
};
type TProps = {
  name: string,
  options: TOption[],
  onChange: (value: TValue) => any,
  label: String,
  searchable: Boolean,
};

const FieldSelect = (props: TProps) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Field
      name={props.name}
      render={({ field: { onBlur, onChange, ...field }, form, meta }: FieldProps) => {
        return (
          <Wrapper data-testid={`select-${props.name}`}>
            {props.label && <Label selected={!!field.value || isFocused}>{props.label}</Label>}
            <Select
              {...field}
              {...props}
              onFocus={() => {
                setIsFocused(true);
              }}
              onBlur={(...args) => {
                setIsFocused(false);
                onBlur && onBlur(args);
              }}
              onChange={(value: TValue) => {
                if (props.onChange) {
                  props.onChange(value);
                } else {
                  form.setFieldValue(props.name, value.value);
                }
              }}
            />
          </Wrapper>
        );
      }}
    />
  );
};
export default FieldSelect;
