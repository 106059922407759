import { ClientJS } from 'clientjs';

const clientJs = new ClientJS();

export const browserColorDepth = window.screen.colorDepth;
export const browserLanguage = navigator.language || navigator.userLanguage;
export const browserScreenHeight = window.screen.height;
export const browserScreenWidth = window.screen.width;
export const browserTZ = new Date().getTimezoneOffset();
export const deviceChannel = '02';
export const windowWidth = window.innerWidth;
export const windowHeight = window.innerHeight;
export const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const defaultChannel = 'web';

export const browserInfoHeaders = {
  'X-Browser-Color-Depth': browserColorDepth,
  'X-Browser-Language': browserLanguage,
  'X-Browser-Screen-Height': browserScreenHeight,
  'X-Browser-Screen-Width': browserScreenWidth,
  'X-Browser-TZ': browserTZ,
  'X-Device-Channel': deviceChannel,
  'X-Window-Width': windowWidth,
  'X-Window-Height': windowHeight,
  'X-Browser-Fingerprint': clientJs.getFingerprint(),
  'X-Browser-OS': clientJs.getOS(),
  'X-Browser-OS-Version': clientJs.getOSVersion(),
  'X-Browser-Is-Mobile': clientJs.isMobile(),
  'X-Browser-Screen-Print': clientJs.getScreenPrint(),
  'X-Browser-Plugins': clientJs.getPlugins(),
  'X-Browser-Java-Enabled': clientJs.isJava(),
  'X-Browser-TimeZone': browserTimeZone,
};
