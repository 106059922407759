import React from 'react';
import { compose, lifecycle, withHandlers, withStateHandlers } from 'recompose';

import Request from '../../Components/Blocks/Request';
import ErrorPanel from '../../Components/ErrorPanel';

import useSlideUpWidget from '../../hooks/useSlideUpWidget/useSlideUpWidget';
import { images } from '../../Constants/css';
import { BankImage, CloseButton, Header, HeaderImage, Text, ListItem, SearchInput, Wrapper, NotFound } from './styled';

const SlideUpWidget = (props) => {
  const { onClose, filteredBanks, onSearch, onClickBank, isLoading, isError } = props;

  const handleClickItem = (value) => {
    onClickBank(value);
  };

  const handleSearch = (event) => {
    event.persist();
    onSearch(event.target.value);
  };

  const getHref = (item) => {
    let href = item.dboLink;

    if (typeof href === 'string') {
      const addition = (href.includes('?') ? '&' : '?') + 'mode=itms-app';
      href = href + addition;
    }

    return href;
  };

  const renderContent = () =>
    filteredBanks && filteredBanks.length > 0 ? (
      filteredBanks.map((item) => (
        <ListItem
          href={getHref(item)}
          key={item.bankName}
          onClick={() => handleClickItem(item.bankName)}
          target="_parent"
        >
          <BankImage src={item.logoURL} />
          <div>{item.bankName}</div>
        </ListItem>
      ))
    ) : (
      <NotFound>По Вашему запросу результатов не найдено</NotFound>
    );

  return (
    <Wrapper>
      <Header>
        <CloseButton onClick={onClose}>×</CloseButton>
        <HeaderImage src={images.sbpLogoWithText} />
      </Header>

      {isError && (
        <div>
          <ErrorPanel text="Произошла ошибка, попробуйте ещё раз" />
        </div>
      )}
      {isLoading && (
        <div style={{ marginTop: '60px' }}>
          <Request />
        </div>
      )}
      {!isError && !isLoading && (
        <>
          <Text>Выберите банк для подтверждения оплаты</Text>
          <SearchInput onChange={handleSearch} placeholder="Введите название банка" />
          {renderContent()}
        </>
      )}
    </Wrapper>
  );
};

export default compose(
  withStateHandlers(
    {
      banks: [],
      filteredBanks: [],
      isLoading: false,
      isError: false,
    },
    {
      setBanks: () => (banks) => ({ banks }),
      setFilteredBanks: () => (filteredBanks) => ({ filteredBanks }),
      setIsLoading: () => (isLoading) => ({ isLoading }),
      setIsError: () => (isError) => ({ isError }),
    }
  ),
  withHandlers({
    filterBanksByLS: () => (bankList, LSbanks) => {
      const foundedBanksFromLC = LSbanks.reduce((banks, bankName) => {
        const foundedBank = bankList.find((bank) => bank.bankName === bankName);
        if (foundedBank) {
          banks.push(foundedBank);
        }
        return banks;
      }, []);
      const restBanks = bankList.filter((bank) => {
        const wasFounded = foundedBanksFromLC.find((foundedBank) => foundedBank.bankName === bank.bankName);
        return !wasFounded;
      });
      return [...foundedBanksFromLC, ...restBanks];
    },
    getNextUsedBanks: () => (value, prevUsedBanks) => {
      let nextUsedBanks = [...prevUsedBanks];
      if (nextUsedBanks.includes(value)) {
        nextUsedBanks = nextUsedBanks.filter((bank) => bank !== value);
      }
      if (nextUsedBanks.length === 3) {
        nextUsedBanks.splice(-1);
      }
      nextUsedBanks.unshift(value);

      return nextUsedBanks;
    },
  }),
  withHandlers({
    getBanks: (props) => async (url) => {
      const { getBankList } = useSlideUpWidget();

      try {
        props.setIsError(false);
        props.setIsLoading(true);

        const bankList = await getBankList(url);
        props.setBanks(bankList);

        if (!Array.isArray(bankList)) {
          throw new Error();
        }

        let filteredBanks = bankList;

        if (localStorage) {
          const lastUsedBanks = JSON.parse(localStorage.getItem('lastUsedBanks'));
          if (Array.isArray(lastUsedBanks) && lastUsedBanks.length > 0) {
            filteredBanks = props.filterBanksByLS(bankList, lastUsedBanks);
          }
          props.setBanks(filteredBanks);
        }
        props.setFilteredBanks(filteredBanks);
      } catch (error) {
        props.setIsError(true);
      } finally {
        props.setIsLoading(false);
      }
    },
    onSearch: (props) => (value) => {
      const formattedValue = value.toLowerCase().trim();

      const filteredBanks = props.banks.filter((bank) => {
        const formattedBankName = bank.bankName.toLowerCase().trim();
        return formattedBankName.includes(formattedValue);
      });

      props.setFilteredBanks(filteredBanks);
    },
    onClickBank: (props) => (value) => {
      if (!localStorage) return;

      let nextUsedBanks = [value];

      const lastUsedBanks = JSON.parse(localStorage.getItem('lastUsedBanks'));
      if (Array.isArray(lastUsedBanks)) {
        nextUsedBanks = props.getNextUsedBanks(value, lastUsedBanks);
      }

      localStorage.setItem('lastUsedBanks', JSON.stringify(nextUsedBanks));
    },
  }),
  lifecycle({
    componentDidMount() {
      const { url, getBanks } = this.props;
      if (url) {
        getBanks(url);
      }
    },
  })
)(SlideUpWidget);
