/**
 * withQueryParams HOC
 *
 * @flow
 */
import React from 'react';
import qs from 'qs';

export default function withQueryParams(Component: React$ElementType) {
  return class withQueryParamsHOC extends React.Component<*> {
    render() {
      const {
        location: { search },
      } = this.props;

      const queryParams = qs.parse(search, { ignoreQueryPrefix: true });

      return (
        <Component
          {...{
            ...this.props,
            queryParams,
          }}
        />
      );
    }
  };
}
