/**
 * 'Autopayment's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';
import { breakpoints } from '../../../../../../Constants/css';

export const Msisdn = styled.div`
  min-width: 200px;
  margin: 0;

  flex: 1;
  flex-basis: auto; //Fix IE11

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0;

    align-self: stretch;
  }
`;

export const Row = styled.div`
  display: flex;

  align-items: center;
`;
