import { createReducer } from 'redux-act';

import { setCaptchaValues } from '../actions';

const initialState = {
  action: '',
  googletoken: '',
};

export const captchaReqReducer = createReducer({}, initialState).on(setCaptchaValues, (state, payload) => {
  return {
    ...state,
    ...payload,
  };
});
