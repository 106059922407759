import { Form } from 'formik';
import React from 'react';

import CardFields from '../../../../Components/Blocks/CardFields';
import { Content, Wrapper } from '../../../../Components/Blocks/Common';
import { Oferta, Wrapper as DetailWrapper } from '../../../../Components/Blocks/DetailsPanel';
import { FormContainer, Row } from '../../../../Components/Blocks/Form';
import Panel from '../../../../Components/Blocks/Panel';
import SavePayButton from '../../../../Components/Blocks/SavePayButton';
import SecureBadges from '../../../../Components/Blocks/SecureBadges';
import SecureSign from '../../../../Components/Blocks/SecureSign';
import CardsImage from '../../../../Components/CardsImage';
import Button from '../../../../Components/Controls/Button';
import ReceiptElectron from '../../../../Components/Controls/ReceiptElectron';
import ErrorPanel from '../../../../Components/ErrorPanel';
import { b2bClients } from '../../../../Constants/clientTypes';
import IsCardBind from './IsCardBind';
import { BlockTitle, CheckCardMessage, CheckCardSum } from './styled';
import WithLoop from './WithLoop';

const BindCardForm = (props) => {
  const { clientType = '', values, isAlien, buttonLabel } = props;
  const b2bClient = b2bClients.includes(clientType.toLowerCase());

  const { electronReceiptType, electronReceiptEmail, electronReceiptMobile } = values;
  const b2bValid = b2bClient
    ? electronReceiptType === 'email'
      ? !!electronReceiptEmail
      : !!electronReceiptMobile
    : true;

  const isDisabledButton =
    !props.isValid ||
    (!props.values.auth && props.values.isCardBind && props.remainingQuantityConfirm === 0) ||
    !b2bValid;

  return (
    <Wrapper>
      <Form>
        {props && props.commonError && (
          <div style={{ margin: '0px 0px 25px 0px' }}>
            <ErrorPanel text={props.commonError} />
          </div>
        )}
        <Content>
          <Panel>
            <FormContainer>
              <BlockTitle>Новая карта</BlockTitle>
              <CardFields setFieldValue={props.setFieldValue} />

              <BlockTitle>Электронный чек</BlockTitle>
              <Row>
                <ReceiptElectron
                  type={props.values.electronReceiptType}
                  emailInputName={'electronReceiptEmail'}
                  mobileInputName={'electronReceiptMobile'}
                  receiptTypeRadioName={'electronReceiptType'}
                  hideButton={isAlien}
                />
              </Row>

              {isAlien && (
                <CheckCardMessage>
                  Чтобы проверить карту, мы спишем с нее <CheckCardSum>1 ₽</CheckCardSum> и вернем обратно.
                </CheckCardMessage>
              )}

              {props.values.withLoop && <WithLoop remainingQuantity={props.remainingQuantity} />}

              {props.values.isCardBind && (
                <IsCardBind values={props.values} remainingQuantityConfirm={props.remainingQuantityConfirm} />
              )}

              {/* {!b2bClient && (
                <Row>
                  <GiftSection />
                </Row>
              )} */}
              {!props.isMobile && (
                <div style={{ margin: '24px 0 0 0' }}>
                  <CardsImage />
                </div>
              )}
            </FormContainer>
          </Panel>

          <Panel alternate>
            <DetailWrapper>
              <SavePayButton>
                <Button label={buttonLabel} type={'submit'} disabled={isDisabledButton} big={!props.isMobile} />
              </SavePayButton>
              <SecureSign />
              <SecureBadges />
              <Oferta>
                Нажимая «{`${buttonLabel}`}», вы подтверждаете согласие с{' '}
                <a href={'https://tele2.ru/payoferta'} target={'_blank'}>
                  Условиями предоставления услуги
                </a>
              </Oferta>
              {props.isMobile && <CardsImage />}
            </DetailWrapper>
          </Panel>
        </Content>
      </Form>
    </Wrapper>
  );
};

export default React.memo(BindCardForm);
