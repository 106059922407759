import React from 'react';
import { compose } from 'recompose';
import Failed from '../../Components/Blocks/Failed';
import Layout from '../../Components/Blocks/Layout';
import Request from '../../Components/Blocks/Request';
import { withCloseInFrame, withErrorsHandle, withFrame, withQueryParams, withThreeDS } from '../../Components/HOC';
import scenario from './Scenario';

class SinglePayment3DS extends React.Component {
  state = {
    pending: false,
  };

  onThreeDSSuccess = async (threeDSData) => {
    await this.props.threeDS.redirect(threeDSData);
  };

  onPaymentSucceed = () => {
    const {
      queryParams: { paymentid },
    } = this.props;

    this.props.history.push(`/singlepayment3dsresult?paymentid=${paymentid}`);
  };

  onError = (error) => {
    this.setState({ pending: false });
    this.props.handleError(error);
  };

  onFrameSuccess = async (threeDSData) => await this.props.handlePostFrame(threeDSData);

  componentDidMount() {
    const {
      queryParams: { paymentid, paymentId },
    } = this.props;

    const { onPaymentSucceed, onThreeDSSuccess, onError, onFrameSuccess } = this;

    this.setState({ pending: true });

    scenario(paymentid || paymentId, onPaymentSucceed, onThreeDSSuccess, onError, onFrameSuccess);
  }

  render() {
    const error = this.props.criticalError || this.props.commonError;

    return (
      <Layout title={'Пополнение баланса'}>
        {error && <Failed message={error} onClick={this.props.onClose} buttonLabel={'Закрыть'} />}

        {this.state.pending && <Request />}
      </Layout>
    );
  }
}

export default compose(withQueryParams, withThreeDS, withErrorsHandle, withFrame, withCloseInFrame)(SinglePayment3DS);
