import PaymentService from '../../../../Services/PaymentService';
import { ValidationError } from '../../../../Constants/errors';

const withCommonHandle = (func) => async (values, formikBag) => {
  const { props, setErrors } = formikBag;
  props.setCommonError('');
  props.fetching.start();

  try {
    await func(values, formikBag);
  } catch (error) {
    if (error instanceof ValidationError) {
      // устанавливаем валидационные ошибки
      if (error.fieldErrors && Object.keys(error.fieldErrors).length > 0) {
        setErrors({
          ...error.fieldErrors,
        });
      }

      if (error.commonErrors && error.commonErrors.length > 0) {
        props.setCommonError(error.commonErrors.join('/n'));
      }
    } else {
      props.onError(error);
    }
  }

  props.fetching.stop();
};

const topUp = async (values, { props }) => {
  //debugger;
  const { branch, electronReceiptType, amount, pan, expirationDate, cvv, electronReceiptEmail, electronReceiptMobile } =
    values;

  let subscriberAccount = values.subscriberAccount.replace(/-/g, '');
  const callbackRoute = 'subscriberAccountPayment';

  const { paymentId } = await PaymentService.prepareThreeDSSubscriberAccount({
    subscriberAccount,
    branchId: branch,
    amount,
    pan,
    expirationDate,
    cvv,
    receipt: {
      email: electronReceiptType === 'email' ? (electronReceiptEmail ? electronReceiptEmail : null) : null,
      mobile: electronReceiptType === 'mobile' ? (electronReceiptMobile ? electronReceiptMobile : null) : null,
    },
    callbackRoute,
  });

  const handleErrorStatus = (reason) => {
    if (reason) {
      throw new ValidationError({
        commonErrors: [reason.description],
      });
    } else {
      throw new ValidationError({
        commonErrors: ['Произошла техническая ошибка, повторите запрос позднее.'],
      });
    }
  };

  const handleSucceedStatus = () => {
    props.onAreqSucceed(paymentId);
  };

  const handleWaiting3DsStatus = async () => {
    const threeDSData = await PaymentService.getThreeDS({ paymentId });
    await props.onThreeDSSuccess(threeDSData);
  };

  const handleWaitingFrameStatus = async () => {
    const frameData = await PaymentService.getFrame({ paymentId });
    await props.onFrameSuccess(frameData);

    const { status, reason } = await PaymentService.getPaymentExactStatus({
      paymentId,
      expectedStatuses: ['Waiting3Ds', 'Succeed', 'Pending', 'Rejected'],
      tries: 10,
    });

    switch (status) {
      case 'Waiting3Ds': {
        await handleWaiting3DsStatus();
        break;
      }
      case 'Succeed': {
        handleSucceedStatus();
        break;
      }
      case 'Pending': {
        await paymentExactStatus('WaitingFrame');
        break;
      }
      case 'WaitingFrame': {
        await PaymentService.confirmFrameThreeDS({ paymentId, threeDSCompInd: 'N' });
        await paymentExactStatus('WaitingFrame');
        break;
      }
      default:
        handleErrorStatus(reason);
    }
  };

  const paymentExactStatus = async (excl) => {
    const expectedStatuses = ['Waiting3Ds', 'WaitingFrame', 'Rejected', 'Succeed'].filter((sts) => sts !== excl);
    const { status: statusByRequest, reason } = await PaymentService.getPaymentExactStatus({
      paymentId,
      expectedStatuses,
    });
    const status = expectedStatuses.find((expectedSts) => expectedSts === statusByRequest);

    switch (status) {
      case 'Waiting3Ds':
        await handleWaiting3DsStatus();
        break;
      case 'WaitingFrame':
        await handleWaitingFrameStatus();
        break;
      case 'Succeed':
        handleSucceedStatus();
        break;
      default:
        handleErrorStatus(reason);
    }
  };

  await paymentExactStatus();
};

export default withCommonHandle(topUp);
