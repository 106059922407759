import SubscriberService from '../../../../Services/SubscriberService';

const withCommonHandle = func => async props => {
  await func(props);
};

const acceptCode = async props => {
  const { subscriberId, setFieldValue } = props;

  const { acceptCodeId } = await SubscriberService.sendAcceptCode({
    subscriberId,
  });

  setFieldValue('acceptCodeId', acceptCodeId);
};

export default withCommonHandle(acceptCode);
