/**
 * 'Request's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';

import { breakpoints, fonts } from '../../../Constants/css';

export const Wrapper = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
`;

export const Label = styled.div`
  font-family: ${fonts.main}
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  
  color: #1f2229;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 22px;
  }
`;

export const IconBox = styled.div`
  margin: 61px 0 0 0;

  @media (max-width: ${breakpoints.mobile}) {
    margin-top: 55px;
  }
`;
