import { api, parseApiResponse } from '../common';

/**
 * Получение статуса привязки токена СБП по его идентификатору
 */

const ROOT = `/sbpTokens`;

export const getSbpBindStatus = async function ({ sbpTokenId }) {
  const response = await api.get(`${ROOT}/${sbpTokenId}/status`);

  return parseApiResponse(response);
};
