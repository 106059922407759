/**
 * 'ErrorPanel's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';
import { breakpoints } from '../../Constants/css';

export const Wrapper = styled.div`
  width: 100%;
  padding: 10px 19px;
  min-width: 100px;
  bottom: 100%;
  overflow: hidden;

  background-color: #f5d3d8;

  font-size: 14px;
  color: #d92b48;
  text-align: center;

  white-space: pre-line;

  @media (min-width: 641px) and (max-width: ${breakpoints.desktop}) {
    max-width: 580px;
  }
`;

export const Link = styled.a`
  outline: none;
  color: #d92b48;

  &:hover {
    color: #c10b29;
  }
`;
