import * as R from 'ramda';
import { compose, lifecycle, withProps, withStateHandlers } from 'recompose';
import yup from 'yup';
import { withFetching, withMobile } from '../../../Components/HOC';
import withFormik from '../../../Components/HOC/withFormikHelper';
import { schemas as commonSchemas } from '../../../Helpers/validation';
import { bindCardAlienValidationSchema, bindCardValidationSchema } from './validation';
import scenario from './Scenario';
import BindCardForm from './View';
import { cvvInputRef, expirationDateInputRef } from './View/refs';

export default compose(
  withMobile,
  withFetching,
  withStateHandlers(
    {
      remainingQuantity: '',
      commonError: '',
      remainingQuantityConfirm: '',
    },
    {
      setCommonError: () => (commonError) => ({ commonError }),
      setRemainingQuantity: () => (remainingQuantity) => ({ remainingQuantity }),
      setRemainingQuantityConfirm: () => (remainingQuantityConfirm) => ({
        remainingQuantityConfirm,
      }),
    }
  ),
  withProps((props) => ({
    values: {
      pan: '',
      expirationDate: '',
      cvv: '',
      acceptCode: null,
      withLoop: false,
      electronReceiptEmail: props.email || '',
      electronReceiptMobile: '',
      electronReceiptType: 'email',

      auth: props.auth || false,
      isCardBind: false,
      acceptCodeConfirm: '',

      cardId: '',
      subscriberId: '',

      threeDSData: null,

      msisdn: props.msisdn,
    },
  })),
  withFormik({
    handleSubmit: scenario,
    validationSchema: ({ isAlien, values }) => {
      const finalSchemas = isAlien
        ? { ...commonSchemas, ...bindCardAlienValidationSchema }
        : { ...commonSchemas, ...bindCardValidationSchema };

      const validationSchema = yup.object(
        Object.keys(values).reduce((acc, key) => {
          if (finalSchemas[`${key}Schema`]) {
            acc[key] = finalSchemas[`${key}Schema`];
          }

          return acc;
        }, {})
      );

      return validationSchema;
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { acceptCode: prevAcceptCode, withLoop: prevWithLoop, ...prevValues } = prevProps.values;
      const { props } = this;
      const { acceptCode, withLoop, ...values } = props.values;
      if (!R.equals(prevValues, values)) {
        props.setFieldValue('withLoop', false, false);
        props.setFieldValue('acceptCode', '', false);
        props.setFieldTouched('acceptCode', false);
        props.setRemainingQuantity('');
        props.setCommonError('');
        if (props.values.isCardBind) {
          props.setFieldTouched('acceptCodeConfirm', false);
        }
      }

      if (
        prevProps.values.pan !== props.values.pan ||
        prevProps.values.expirationDate !== props.values.expirationDate ||
        prevProps.values.cvv !== props.values.cvv
      ) {
        props.setFieldValue('isCardBind', false, false);
        props.setFieldValue('acceptCodeConfirm', '', false);
        props.setFieldTouched('acceptCodeConfirm', false);
        props.setFieldValue('cardId', '', false);
        props.setFieldValue('subscriberId', '', false);
        props.setFieldValue('threeDSData', null, false);
        props.setRemainingQuantityConfirm('');
      }

      /** It can't check formik's errors to set focus on next input,
       * because setting values and validating are separated operations,
       * so current value and error in one operation will be inconsistent.
       * This is how formik work under the hood.
       * That's why we need to call validation manually
       */
      if (prevProps.values.pan !== props.values.pan && commonSchemas.panSchema.isValidSync(props.values.pan)) {
        expirationDateInputRef.current.input.focus();
      }

      if (
        prevProps.values.expirationDate !== props.values.expirationDate &&
        commonSchemas.expirationDateSchema.isValidSync(props.values.expirationDate)
      ) {
        cvvInputRef.current.input.focus();
      }
    },
    componentDidMount() {
      const { props } = this;
      const touched = Object.keys(props.values).reduce((touched, key) => {
        if (props.values[key]) {
          touched[key] = true;
        }
        return touched;
      }, {});
      props.setTouched({ ...touched });
    },
  })
)(BindCardForm);
