import styled from 'styled-components';
import { breakpoints } from '../../../Constants/css';

export const Wrapper = styled.div`
  display: flex;
  margin: 18px 0 0 0;
  align-items: center;
  justify-content: center;

  > img {
    margin-right: 12px
    width: 21px;
    height: 21px;
  }

  > span {
    font-size: 14px;
    color: #7b7b7b;
  }

  @media(max-width: ${breakpoints.mobile}) {
    margin: 28px 0 0 0;
  }
`;
