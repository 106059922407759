/**
 * 'Panel', Block Component
 *
 * @flow
 */
import React from 'react';

import { Wrapper } from './styled';

type TProps = {
  /** Вложенные компоненты */
  children: any /** React.ReactNode */,
  /** Свойства визуального оформления */
  alternate?: boolean,
  width?: string,
};

class Panel extends React.PureComponent<TProps, *> {
  render() {
    const { children, alternate, width } = this.props;

    return (
      <Wrapper {...{ alternate }} width={width}>
        {children}
      </Wrapper>
    );
  }
}

export default Panel;
