import CardService from '../../../../Services/CardService';
import SubscriberService from '../../../../Services/SubscriberService';
import { CriticalError, ValidationError } from '../../../../Constants/errors';
import { setSubscriberId, setAcceptCodeId } from './topup';

const withCommonHandle = func => async props => {
  const { setErrors } = props;

  props.setCommonError('');
  props.fetching.start();

  try {
    await func(props);
  } catch (error) {
    if (error instanceof ValidationError) {
      // устанавливаем валидационные ошибки
      if (error.fieldErrors && Object.keys(error.fieldErrors).length > 0) {
        setErrors({
          ...error.fieldErrors,
        });
      }

      if (error.commonErrors && error.commonErrors.length > 0) {
        props.setCommonError(error.commonErrors.join('/n'));
      }
    } else {
      props.onError(error);
    }
  }

  props.fetching.stop();
};

const acceptCode = async props => {
  if (!props.values.isCardBind && props.isCardValid && !props.errors.msisdn) {
    const { msisdn, pan, expirationDate } = props.values;

    await CardService.getCardIdForBind({
      pan,
      expirationDate,
    });

    const { subscriberId, isBlocked } = await SubscriberService.getOrCreateSubscriber({
      msisdn,
    });

    if (isBlocked) {
      throw new CriticalError({
        commonErrors: [`Номер ${msisdn} заблокирован для сохранения карты`],
      });
    }

    const { cardId } = await SubscriberService.addCardToSubscriber({
      subscriberId,
      pan,
      expirationDate,
    });

    const { acceptCodeId } = await SubscriberService.sendAcceptCode({
      subscriberId,
    });

    setSubscriberId(subscriberId);
    setAcceptCodeId(acceptCodeId);

    props.setFieldTouched('acceptCode', false);
    props.setValues({
      ...props.values,
      acceptCode: '',
      isCardBind: true,
      cardId: cardId,
    });
  } else {
    props.setRemainingQuantity('');
    props.setFieldTouched('acceptCode', false);
    props.setValues({ ...props.values, acceptCode: '', isCardBind: false });
  }
};

export default withCommonHandle(acceptCode);
