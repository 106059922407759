/**
 * 'Succeeded's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';
import { breakpoints, fonts, images } from '../../../Constants/css';

export const Wrapper = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.desktop}) {
    flex-direction: column;
    margin: 0 auto;
  }
`;

export const Content = styled.div`
  display: flex;

  flex: 1;
  flex-basis: auto; //Fix IE11

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const Header = styled.div`
  display: flex;

  flex: 1;
  flex-basis: auto; //Fix IE11
  align-items: center;

  @media (max-width: ${breakpoints.mobile}) {
    width: auto;
  }
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;

  background-image: url(${images.correctInput});
  background-repeat: no-repeat;
`;

export const Label = styled.div`
  font-family: ${fonts.alternate};
  font-weight: bold;
  font-size: 22px;
`;

export const AdditionalBox = styled.div`
  margin: 41px 0 0 0;

  @media (max-width: ${breakpoints.mobile}) {
    margin-top: 24px;
  }
`;

export const Cards = styled.div`
  display: flex;
  align-items: end;

  flex: 1;
  flex-basis: auto;

  margin-top: 20px;

  @media (max-width: ${breakpoints.mobile}) {
    margin-top: 0;
  }
`;

export const Margin = styled.div`
  margin-bottom: 30px;
`;

export const FieldBox = styled.div`
  @media (min-width: ${breakpoints.desktop}) {
    display: flex;
    width: 100%;
  }
`;
