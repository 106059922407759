import { compose } from 'recompose';
import { ValidationError } from '../../../../Constants/errors';
import { androidCb, iframeCb, iOsCb } from '../../../../Helpers';
import withCommonRequestProcess from '../../../../Helpers/withCommonRequestProcess';
import AcceptCodeService from '../../../../Services/AcceptCodeService';
import PaymentService from '../../../../Services/PaymentService';
import SubscriberService from '../../../../Services/SubscriberService';

let subscriberId;
let acceptCodeId;

export const setSubscriberId = (nextSubscriberId) => (subscriberId = nextSubscriberId);
export const setAcceptCodeId = (nextAcceptCodeId) => (acceptCodeId = nextAcceptCodeId);

const topUp = async (props) => {
  const { values, channel, authToken } = props;
  const { isMobile, ...restValues } = values;
  const {
    electronReceiptType,
    msisdn,
    amount,
    pan,
    expirationDate,
    cvv,
    electronReceiptEmail,
    electronReceiptMobile,
    acceptCode,
    cardId,
  } = restValues;

  if (restValues.isCardBind) {
    try {
      await SubscriberService.confirmAcceptCode({
        subscriberId,
        cardId,
        acceptCodeId,
        acceptCode,
      });
    } catch (error) {
      if (error instanceof ValidationError) {
        const { remainingQuantity } = await AcceptCodeService.getAcceptCodeStatus({
          acceptCodeId,
        });

        props.setRemainingQuantity(remainingQuantity);
      }

      throw error;
    }
  }

  const callbackRoute = 'payment';

  const { paymentId } = await PaymentService.prepareThreeDS(
    {
      msisdn,
      amount,
      pan,
      expirationDate,
      cvv,
      receipt: {
        email: electronReceiptType === 'email' ? (electronReceiptEmail ? electronReceiptEmail : null) : null,
        mobile: electronReceiptType === 'mobile' ? (electronReceiptMobile ? electronReceiptMobile : null) : null,
      },
      callbackRoute,
    },
    isMobile,
    { channel, 'Auth-Token': authToken || '' }
  );

  if (window.top !== window.self) {
    iframeCb('savePaymentId', { paymentId });
  }

  if (window.Android) {
    androidCb('savePaymentId', paymentId);
  }

  if (window.webkit) {
    iOsCb('savePaymentId', { paymentId });
  }

  const handleWaiting3DsStatus = async () => {
    const threeDSData = await PaymentService.getThreeDS({ paymentId });
    await props.onThreeDSSuccess(threeDSData);
  };

  const handleSucceedStatus = () => {
    props.onAreqSucceed(paymentId);
  };

  const handleStatusError = (reason) => {
    if (reason) {
      throw new ValidationError({
        commonErrors: [reason.description],
      });
    } else {
      throw new ValidationError({
        commonErrors: ['Произошла техническая ошибка, повторите запрос позднее.'],
      });
    }
  };

  const handleWaitingFrameStatus = async () => {
    const frameData = await PaymentService.getFrame({ paymentId });
    await props.onFrameSuccess(frameData);

    const { status, reason } = await PaymentService.getPaymentExactStatus({
      paymentId,
      expectedStatuses: ['Succeed', 'Waiting3Ds', 'Pending', 'Rejected'],
      tries: 10,
    });

    switch (status) {
      case 'Succeed': {
        handleSucceedStatus();
        break;
      }
      case 'Waiting3Ds': {
        await handleWaiting3DsStatus();
        break;
      }
      case 'Pending':
        await paymentExactStatus('WaitingFrame');
        break;
      case 'WaitingFrame':
        await PaymentService.confirmFrameThreeDS({ paymentId, threeDSCompInd: 'N' });
        await paymentExactStatus('WaitingFrame');
        break;
      default:
        handleStatusError(reason);
    }
  };

  const paymentExactStatus = async (excl) => {
    const expectedStatuses = ['Waiting3Ds', 'WaitingFrame', 'Rejected', 'Succeed'].filter((sts) => sts !== excl);
    const { status: statusByRequest, reason } = await PaymentService.getPaymentExactStatus({
      paymentId,
      expectedStatuses,
    });
    const status = expectedStatuses.find((expectedSts) => expectedSts === statusByRequest);

    switch (status) {
      case 'Waiting3Ds':
        await handleWaiting3DsStatus();
        break;
      case 'WaitingFrame':
        await handleWaitingFrameStatus();
        break;
      case 'Succeed':
        handleSucceedStatus();
        break;
      default:
        handleStatusError(reason);
    }
  };

  await paymentExactStatus();
};

const adapterCommonRequestProcess = (func) => {
  return (props) => {
    const { setErrors, fetching, setCommonError, onError } = props;
    func(
      {
        setErrors,
        fetching,
        setCommonError,
        onError,
      },
      props
    );
  };
};

export default compose(adapterCommonRequestProcess, withCommonRequestProcess)(topUp);
