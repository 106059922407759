/**
 * 'Title', Control Component
 *
 * @flow
 */
import React from 'react';

import { Header } from './styled';

type TProps = {
  /** Текст */
  text: string,
  /** Нижнее обрамление */
  underlined?: boolean,
  /** Выравнивание текста по левой стороне (при отображение closeIcon) */
  alignLeft?: String,
};

export default function Title(props: TProps) {
  const { text, underlined = false, alignLeft } = props;

  return (
    <Header underlined={underlined} alignLeft={alignLeft}>
      <span>{text}</span>
    </Header>
  );
}
