import { compose } from 'recompose';
import { ValidationError } from '../../../../../Constants/errors';
import { convertKopecksToRublesAsDecimal } from '../../../../../Helpers/Currency';
import withCommonRequestProcess from '../../../../../Helpers/withCommonRequestProcess';
import AutopaymentService from '../../../../../Services/AutopaymentService';
import { CATEGORY_SUBSCRIPTIONFEE } from '../Form';

export const pullSubscriptionFee = async props => {
  let subscriptionFee;

  const { msisdn, values, setValues, setSubscriptionFee } = props;

  try {
    await new Promise(next => setTimeout(next, 2000));
    subscriptionFee = await AutopaymentService.getSubscriptionFee({ msisdn });
    subscriptionFee.amount = convertKopecksToRublesAsDecimal(subscriptionFee.amount);
  } catch (e) {
    if (!(e instanceof ValidationError)) {
      throw new ValidationError({
        commonErrors: [`При запросе доступных услуг произошла ошибка, повторите запрос позднее.`],
      });
    }

    throw e;
  }

  setSubscriptionFee(subscriptionFee);
  setValues({
    ...values,
    amount: subscriptionFee.amount,
    category: CATEGORY_SUBSCRIPTIONFEE,
  });
};

const adapterCommonRequestProcess = func => {
  return props => {
    const { setErrors, fetching, setCommonError, onFail: onError } = props;
    func(
      {
        setErrors,
        fetching,
        setCommonError,
        onError,
      },
      props
    );
  };
};

export default compose(
  adapterCommonRequestProcess,
  withCommonRequestProcess
)(pullSubscriptionFee);
