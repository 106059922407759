//@flow

import React from 'react';
import { Field, type FieldProps } from 'formik';

import moment from 'moment';

import DatePicker from './index';

type TProps = {
  name: string,
  label: string,
};

const FieldDatePicker = (props: TProps) => (
  <Field
    name={props.name}
    render={({ field, form }: FieldProps) => (
      <DatePicker
        {...field}
        {...props}
        value={field.value ? moment(field.value) : null}
        onChange={(nextDate: moment$Moment) => {
          form.setFieldValue(field.name, nextDate ? nextDate.toDate() : '');
          form.setFieldTouched(field.name, true);
        }}
      />
    )}
  />
);

export default FieldDatePicker;
