/**
 * 'Field', 'Succeeded's Element
 *
 * @flow
 */
import React from 'react';

import { Label, Value, Wrapper } from './styled';

type TProps = {
  label: string,
  value: string,
};

class Field extends React.PureComponent<TProps, *> {
  render() {
    const { label, value } = this.props;

    return (
      <Wrapper>
        <Label>{label}</Label>
        <Value>{value}</Value>
      </Wrapper>
    );
  }
}

export default Field;
