/**
 * 'TextInput's Styled Components
 *
 * @flow
 */
import styled, { css } from 'styled-components';
import { colors, fonts, images, rules } from '../../../Constants/css';

const { border, fail, text, common } = colors;

// rules
const themes = {
  bordered: css`
    border-width: 1px;
    border-style: solid;
    border-color: ${border.normal};
    border-radius: 8px;
  `,
  disabled: css`
    background-color: #f9fafb;
    color: ${text.disabled};
    cursor: not-allowed;
  `,
  input: {
    active: css`
      padding-top: 20px;
      padding-left: 16px;
    `,
    focused: css`
      border-color: ${common.lightBlue};
      box-shadow: 0px 0px 0px 4px rgba(63, 203, 255, 0.2);
    `,
    correct: css`
      border-color: #42a674;
    `,
    invalid: css`
      border-color: ${fail};
      box-shadow: 0px 0px 4px 0px rgba(255, 117, 140, 0.5);
    `,
    hovered: css`
      border-color: ${border.hover};
    `,
  },
  label: {
    active: css`
      font-size: 13px;
      white-space: normal;
      top: 5px;
    `,
    fixed: css`
      position: absolute;
      width: 24px;
      height: 24px;
      top: 18px;
      right: 16px;
      z-index: 1;
    `,
  },
};

export const CorrectLabel = styled.div`
  ${themes.label.fixed};

  background-image: url(${images.correctInput});
  background-repeat: no-repeat;
`;

export const ErrorLabel = styled.div`
  ${themes.label.fixed};

  background-image: url(${images.failInput});
  background-repeat: no-repeat;
`;

export const ErrorText = styled.span`
  margin: 0;
  padding-left: 16px;
  width: 100%;
  min-width: 120px;
  bottom: 100%;
  left: 0;

  font-size: 13px;
  font-family: ${fonts.alternate};
  color: ${fail};

  overflow: hidden;
  z-index: 3;
  ${(p) => p.hide && rules.hidden};
`;

export const IconWrapper = styled.span`
  position: absolute;
  top: 25%;
  right: 16px;
`;

export const Wrapper = styled.div`
  min-height: 82px;
`;

export const InputWrapper = styled.div`
  position: relative;

  :hover > input {
    :not(:disabled) {
      ${themes.input.hovered}
    }
  }

  > label {
    position: absolute;
    max-width: 80%;
    top: 20px;
    left: 16px;

    font-family: ${fonts.alternate};
    color: #8f9399;
    white-space: nowrap;

    overflow: hidden;

    ${(p) => p.isActive && themes.label.active};
    ${(p) => p.disabled && themes.disabled}
  }

  > input {
    display: block;
    width: 100%;
    height: 60px;
    min-width: 90px;

    padding: 0 50px 0 16px;

    font-family: ${fonts.alternate};
    font-size: 100%;
    color: #000;
    vertical-align: middle;

    outline: none;
    z-index: 2;

    ${themes.bordered};

    ${(p) => p.borderless && rules.borderless};
    ${(p) => p.isActive && themes.input.active};
    ${(p) => p.isFocused && themes.input.focused};
    ${(p) => p.errorText && themes.input.invalid};
    ${(p) => p.correct && themes.input.correct};
    ${(p) => p.disabled && themes.disabled}
  }

  > input:placeholder-shown {
    ${(p) => !p.errorText && !p.isFocused && themes.bordered};
  }

  > input::placeholder {
    opacity: 0.5;
  }
`;
