/**
 * HOC withErrorsHandle.
 * Обрабатывает валидационные ошибки и критические ошибки.
 * Чтобы обработать валидационные ошибки - необходимо обернуть withFormik
 *
 * flow пока не работает, т.к. непонятно как указать, что HOC принимает данные сверху и использует их
 */

import * as React from 'react';

import { ValidationError } from '../../Constants/errors';
import { type FormikProps } from 'formik';

type TState = {
  commonError: string,
  criticalError: string,
};

type TOwnProps<Props> = {
  ...$Exact<Props>,
  ...FormikProps<any>,
};

export type TInjectedProps = {
  ...$Exact<TState>,
  clearError: Function,
  handleError: Function,
  setCriticalError: Function,
};

const withErrorsHandle = <Props: {}>(
  Component: React.ComponentType<Props>
): React.ComponentType<$Diff<TOwnProps<Props>, TInjectedProps>> =>
  class withErrorsHandleHOC extends React.Component<TOwnProps<Props>, TState> {
    state = {
      commonError: '',
      criticalError: '',
    };

    handleError = (error) => {
      if (typeof error === 'string') {
        this.setState({
          commonError: error,
        });

        return;
      }

      if (error instanceof ValidationError) {
        // устанавливаем валидационные ошибки
        if (this.props.setErrors && error.fieldErrors && Object.keys(error.fieldErrors).length > 0) {
          this.props.setErrors({
            ...error.fieldErrors,
          });
        }
        // устанавливаем общие ошибки
        if (error.commonErrors) {
          this.setState({
            commonError: error.commonErrors.join('/n'),
          });
        }
      } else {
        this.setCriticalError(error);
      }
    };

    setCriticalError = (error) => {
      let criticalError = '';

      if (typeof error === 'string') {
        criticalError = error;
      } else if (error && error.commonErrors) {
        criticalError = error.commonErrors.join('/n');
      } else {
        criticalError = error.message;
      }
      this.setState({ criticalError });
    };

    clearError = () => {
      this.setState({ commonError: '', criticalError: '' });
    };

    render() {
      return (
        <Component
          {...this.props}
          {...this.state}
          handleError={this.handleError}
          setCriticalError={this.setCriticalError}
          clearError={this.clearError}
        />
      );
    }
  };

export default withErrorsHandle;
