import React from 'react';
import { QRCode } from './styled';
import Button from '../../Components/Controls/Button';

const WebView = (props) => {
  const { image, onClose, imageType } = props;
  const imageUrl = `data:${imageType};base64,${image}`;
  const cleanImage = imageUrl.replace(/(\r\n|\n|\r)/gm, '');
  return (
    <>
      <div>После сканирования QR-кода вы перейдете в приложение своего банка</div>
      <QRCode image={cleanImage} />
      <Button label={'Закрыть'} onClick={onClose} ghost big data-testid={'block-result-button-close'} />
    </>
  );
};

export default WebView;
