import { createReducer } from 'redux-act';

import { setYandexCaptchaValues } from '../actions';

const initialState = {
  token: '',
};

export const yandexCaptchaReqReducer = createReducer({}, initialState).on(setYandexCaptchaValues, (state, payload) => {
  return {
    ...state,
    ...payload,
  };
});
