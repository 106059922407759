import PaymentsMeansService from '../../../../Services/PaymentsMeansService';

export default async (props) => {
  const {
    isCheckPaymentsFetched,
    values,
    setIsCheckPaymentsFetched,
    setIsSberPayButtonVisible,
    isSberPayButtonVisible,
  } = props;
  const { msisdn } = values;

  try {
    if (!isCheckPaymentsFetched && !isSberPayButtonVisible) {
      setIsCheckPaymentsFetched(true);

      await PaymentsMeansService.checkPaymentMeans({ msisdn, status: 3 });
      setIsSberPayButtonVisible(true);
    }
  } catch {}
};
