import React from 'react';

import { Buttons, Oferta } from '../../../../../Components/Blocks/DetailsPanel';
import Button from '../../../../../Components/Controls/Button';
import { SBPButton, SBPLabel } from '../styled';
import { images } from '../../../../../Constants/css';
import SavePayButton from '../../../../../Components/Blocks/SavePayButton';

const SBPInfo = ({ isMobile, isValid, onClickSBPPay }) => {
  const label = (
    <SBPLabel>
      Оплатить
      <img src={images.sbpLogo} height="36px" alt="" />
      <img src={images.sbpText} height="11px" alt="" />
    </SBPLabel>
  );

  return (
    <>
      <SBPButton>
        <SavePayButton>
          <Buttons>
            <Button
              width="225px"
              type={'submit'}
              onClick={onClickSBPPay}
              label={label}
              alternate
              disabled={!isValid}
              big={!isMobile}
            />
          </Buttons>
        </SavePayButton>
      </SBPButton>
      <Oferta>
        Нажимая «Оплатить СБП», вы подтверждаете согласие с{' '}
        <a href={'https://tele2.ru/payoferta'} target={'_blank'}>
          Условиями предоставления услуги
        </a>
      </Oferta>
    </>
  );
};

export default React.memo(SBPInfo);
