import React from 'react';

import AcceptCode from '../../../../Components/Blocks/AcceptCode';
import { Row } from '../../../../Components/Blocks/Form';
import FieldTextInput from '../../../../Components/Controls/TextInput/FieldTextInput';

const WithLoop = props => {
  const { remainingQuantity } = props;

  return (
    <Row>
      <AcceptCode
        label={'Введите проверочную сумму заблокированных денежных средств'}
        hint={remainingQuantity ? `Кол-во попыток осталось: ${remainingQuantity}` : ''}
      >
        <FieldTextInput name={'acceptCode'} label={'Сумма'} type={'tel'} maskType={'acceptCodeAsAmount'} required />
      </AcceptCode>
    </Row>
  );
};

export default React.memo(WithLoop);
