export const FORM_PAY_NAMESPACE = '[form-pay-namespace]';
export const CAPTCHA_NAMESPACE = '[captcha-namespace]';
export const YANDEX_CAPTCHA_NAMESPACE = '[yandex-captcha-namespace]';
export const RESPONSE_NAMESPACE = '[response-namespace]';
export const PAYMENT_STATUS = '[payment-status-namespace]';

export * from './reCaptcha';
export * from './sbp';
export * from './yandexCaptcha';
export * from './captcha';
export * from './card';
