import '../../Libs/SlideUpWigdet/slide-up-widget';

const createInstance = () => new window.SlideUpWidget(window.localStorage, window.navigator);

const useSlideUpWidget = () => {
  const showBankSelector = (url: string) => {
    const slideUpWidgetInstance = createInstance();
    slideUpWidgetInstance.showBankSelector(url);
  };

  const getBankList = async (url: string) => {
    const slideUpWidgetInstance = createInstance();
    const bankList = await slideUpWidgetInstance.getBankList(url);

    return bankList;
  };

  return { showBankSelector, getBankList };
};

export default useSlideUpWidget;
