import React from 'react';
import { compose } from 'recompose';
import Failed from '../../../../Components/Blocks/Failed';
import Succeed from '../../../../Components/Blocks/Succeed';
import { withCloseInFrame, withErrorsHandle } from '../../../../Components/HOC';
import { formalizeMsisdn } from '../../../../Helpers/Normalize';
import SucceedWithAutopaymentForm from './Form';

class SucceedWithAutopayment extends React.Component {
  state = {
    isSuccess: false,
    autopaymentAmount: null,
  };
  onSuccess = (autopaymentAmount) => {
    this.setState({ isSuccess: true, autopaymentAmount });
  };
  render() {
    const { message, fields, msisdn, paymentId, amount, criticalError, handleError, onClose, paymentType } = this.props;
    const { isSuccess, autopaymentAmount } = this.state;
    const { onSuccess } = this;

    return (
      <React.Fragment>
        {criticalError && (
          <Failed
            topic={'Автоплатеж не подключен!'}
            message={criticalError}
            onClick={onClose}
            buttonLabel={'Закрыть'}
          />
        )}
        {!criticalError && !isSuccess && (
          <SucceedWithAutopaymentForm
            {...{
              msisdn,
              message,
              fields,
              paymentId,
              amount,
              onSuccess,
              onFail: handleError,
              paymentType,
            }}
          />
        )}
        {!criticalError && isSuccess && (
          <Succeed
            {...{
              message: 'Заявка на подключение Автоплатежа принята',
              fields: [
                {
                  label: 'Номер телефона',
                  value: formalizeMsisdn(msisdn),
                  key: 'field1',
                },
                {
                  label: 'Сумма автоплатежа',
                  value: `${autopaymentAmount} ₽`,
                  key: 'field2',
                },
              ],
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default compose(withErrorsHandle, withCloseInFrame)(SucceedWithAutopayment);
