import acceptCode from './acceptCode';
import { ValidationError } from '../../../../Constants/errors';

const unAuthAutoPayment = async (values, formikBag) => {
  const { setFieldValue } = formikBag;
  const { subscriberInfo } = values;
  const { subscriber, cardId, cardData } = subscriberInfo;

  setFieldValue('cardId', cardId);
  setFieldValue('subscriberId', subscriber.subscriberId);
  try {
    await acceptCode({
      cardId,
      subscriberId: subscriber.subscriberId,
      setFieldValue: setFieldValue,
    });
    setFieldValue('isCardBind', true);
  } catch {
    throw new ValidationError({
      commonErrors: [`Номер ${cardData.msisdn} заблокирован для сохранения карты`],
    });
  }
};

export default unAuthAutoPayment;
