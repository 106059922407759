import * as React from 'react';
import { withMobile } from '../../../../../Components/HOC';
import { Wrapper, Topic, Note } from '../../../../../Components/Blocks/DetailsPanel';
import Panel from '.././../../../../Components/Blocks/Panel';
import CardInfo from './CardInfo';
import { TotalTopicBox } from '../styled';
import CardsImage from '../../../../../Components/CardsImage';

const TotalPanel = (props) => {
  const { errors, values, isValid, isMobile } = props;
  return (
    <Panel alternate>
      <Wrapper>
        <Topic>
          <TotalTopicBox>
            <div>Итого к зачислению</div>
            <div>{`${!errors.amount ? values.amount : '0'} ₽`}</div>
          </TotalTopicBox>
        </Topic>
        <Note>Без комиссии</Note>
        <CardInfo isValid={isValid} />
        {isMobile && <CardsImage />}
      </Wrapper>
    </Panel>
  );
};

export default withMobile(TotalPanel);
