import { compose } from 'recompose';
import { ValidationError } from '../../../../Constants/errors';
import PaymentService from '../../../../Services/PaymentService';
import withCommonRequestProcess from '../../../../Helpers/withCommonRequestProcess';
import SubscriberService from '../../../../Services/SubscriberService';
import AcceptCodeService from '../../../../Services/AcceptCodeService';
import { androidCb, iOsCb, iframeCb } from '../../../../Helpers';

let subscriberId;
let acceptCodeId;
let sbpTokenId;

export const setSubscriberId = (nextSubscriberId) => (subscriberId = nextSubscriberId);
export const setAcceptCodeId = (nextAcceptCodeId) => (acceptCodeId = nextAcceptCodeId);
export const setTokenId = (nextSbpTokenId) => (sbpTokenId = nextSbpTokenId);

const sbpPay = async (props) => {
  const { values, channel } = props;
  const { isMobile, ...restValues } = values;
  const { msisdn, amount, isSbpBind, sbpAcceptCode } = restValues;

  if (isSbpBind) {
    try {
      await SubscriberService.confirmSbpAcceptCode({
        subscriberId,
        sbpTokenId,
        acceptCodeId,
        acceptCode: sbpAcceptCode,
      });
    } catch (error) {
      if (error instanceof ValidationError) {
        const { remainingQuantity } = await AcceptCodeService.getAcceptCodeStatus({
          acceptCodeId,
        });

        props.setSbpRemainingQuantity(remainingQuantity);
      }

      throw error;
    }
  }

  let payment;

  if (isSbpBind) {
    payment = await PaymentService.prepareSBPPayToken({ msisdn, amount, paymentId: sbpTokenId }, { channel });
  } else {
    payment = await PaymentService.prepareSBP(
      {
        msisdn,
        amount,
      },
      isMobile
    );
  }

  const { paymentId } = payment;

  if (window.top !== window.self) {
    iframeCb('savePaymentId', { paymentId });
  }

  if (window.Android) {
    androidCb('savePaymentId', paymentId);
  }

  if (window.webkit) {
    iOsCb('savePaymentId', { paymentId });
  }

  const { status, reason } = await PaymentService.getPaymentExactStatus({
    paymentId,
    expectedStatuses: ['WaitingExternalExecuting', 'Rejected'],
  });
  if (status === 'WaitingExternalExecuting') {
    const deepLink = await PaymentService.getSBPDeepLink({ paymentId });

    props.onSBPDeepLink(deepLink, { ...restValues, paymentId, sbpTokenId });
  } else if (reason) {
    throw new ValidationError({
      commonErrors: [reason.description],
    });
  } else {
    throw new ValidationError({
      commonErrors: ['Произошла техническая ошибка, повторите запрос позднее.'],
    });
  }
};

const adapterCommonRequestProcess = (func) => {
  return (props) => {
    const { setErrors, fetching, setCommonError, onError } = props;
    func(
      {
        setErrors,
        fetching,
        setCommonError,
        onError,
      },
      props
    );
  };
};

export default compose(adapterCommonRequestProcess, withCommonRequestProcess)(sbpPay);
