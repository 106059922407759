/**
 * withTele2Fonts HOC
 *
 * @flow
 */
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  @font-face {
    font-family: 'tele2_displayserif-regular';
    src: url('/assets/fonts/tele2_displayserif-bold.eot');
    src: url('/assets/fonts/tele2_displayserif-bold.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/tele2_displayserif-bold.woff') format('woff'),
      url('/assets/fonts/tele2_displayserif-bold.ttf') format('truetype'),
      url('/assets/fonts/tele2_displayserif-bold.svg#boldbold') format('svg');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'tele2_displayserif-regular';
    src: url('/assets/fonts/tele2_displayserif-regular.eot');
    src: url('/assets/fonts/tele2_displayserif-regular.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/tele2_displayserif-regular.woff') format('woff'),
      url('/assets/fonts/tele2_displayserif-regular.ttf') format('truetype'),
      url('/assets/fonts/tele2_displayserif-regular.svg#regularregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'tele2_textsans_regular';
    src: url('/assets/fonts/tele2_textsans-bold.eot');
    src: url('/assets/fonts/tele2_textsans-bold.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/tele2_textsans-bold.woff') format('woff'),
      url('/assets/fonts/tele2_textsans-bold.ttf') format('truetype'),
      url('/assets/fonts/tele2_textsans-bold.svg#tele2_textsansbold') format('svg');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'tele2_textsans_regular';
    src: url('/assets/fonts/tele2_textsans-regular.eot');
    src: url('/assets/fonts/tele2_textsans-regular.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/tele2_textsans-regular.woff') format('woff'),
      url('/assets/fonts/tele2_textsans-regular.ttf') format('truetype'),
      url('/assets/fonts/tele2_textsans-regular.svg#tele2_textsans_regularregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  color: #1f2229;
  font: 16px/1.375 'tele2_textsans_regular', sans-serif;

  @font-face {
    font-family: 'icomoon';
    src: url('/assets/fonts/icomoon.eot?dxy2th');
    src: url('/assets/fonts/icomoon.eot?dxy2th#iefix') format('embedded-opentype'),
      url('/assets/fonts/icomoon.ttf?dxy2th') format('truetype'),
      url('/assets/fonts/icomoon.woff?dxy2th') format('woff'),
      url('/assets/fonts/icomoon.svg?dxy2th#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
`;

export default function withTele2Fonts(Component: React$ElementType) {
  return function withTele2FontsHOC(props: *) {
    return (
      <Wrapper>
        <Component {...props} />
      </Wrapper>
    );
  };
}
