import React from 'react';
import { Row } from '../../../../../Components/Blocks/Form';
import FieldSelect from '../../../../../Components/Controls//Select/FieldSelect';

import { Condition, FieldWidth } from '../styled';

const BranchesList = (props) => {
  const { branchesList, onBranchesListChanged, disabled } = props;
  return (
    <Row>
      <FieldWidth width="520px">
        <Condition>
          <FieldSelect
            name={'branch'}
            options={branchesList}
            onChange={onBranchesListChanged}
            label="Регион"
            disabled={disabled}
            searchable
          />
        </Condition>
      </FieldWidth>
    </Row>
  );
};

export default BranchesList;
