import React from 'react';
import { compose } from 'recompose';

import Succeed from '../../../../Components/Blocks/Succeed';
import { withCloseInFrame, withErrorsHandle } from '../../../../Components/HOC';

class SucceedWithSubscriberAccount extends React.PureComponent {
  render() {
    const { message, amount } = this.props;

    const fields = [
      {
        label: 'Сумма платежа',
        value: `${amount} ₽`,
        key: 'field1',
      },
    ];

    return (
      <Succeed
        {...{
          fields,
          message,
          isSubmitButtonDisabled: true,
          isSubmitButtonVisible: false,
        }}
      />
    );
  }
}

export default compose(
  withErrorsHandle,
  withCloseInFrame
)(SucceedWithSubscriberAccount);
