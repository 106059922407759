import React from 'react';
import Button from '../../Components/Controls/Button';
import { MobileButtonBox, MobileText } from './styled';

const MobileView = ({ onCheckout }) => {
  return (
    <>
      <MobileText>Нажмите кнопку ниже для перехода в приложение своего банка</MobileText>
      <MobileButtonBox>
        <Button label={'Оплатить'} onClick={onCheckout} />
      </MobileButtonBox>
    </>
  );
};

export default MobileView;
