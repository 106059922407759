/**
 * http://stackoverflow.com/questions/33870684/why-doesnt-instanceof-work-on-instances-of-error-subclasses-under-babel-node
 *
 * @param cls
 * @returns {ExtendableBuiltin}
 * @constructor
 */
function ExtendableBuiltin(cls) {
  function ExtendableBuiltin() {
    cls.apply(this, arguments);
  }

  ExtendableBuiltin.prototype = Object.create(cls.prototype);
  Object.setPrototypeOf(ExtendableBuiltin, cls);

  return ExtendableBuiltin;
}

const COMMON_MESSAGE = 'Произошла техническая ошибка, повторите запрос позднее.';

/**
 * Базовый класс для описания ошибок, возникающих при работе с удаленными
 * серверами
 */
export class ExternalException extends ExtendableBuiltin(Error) {
  constructor(body) {
    super(...arguments);

    this.name = 'ExternalException';
    this.message = 'Не удалось выполнить операцию';
    this.traceActivityID = (body && body.traceActivityID) || '';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error().stack;
    }
  }
}

/**
 * Базовая критическая ошибка без продолжения бизнес сценариев
 */
export class CriticalError extends ExternalException {
  /**
   * @param body тело ошибки
   */
  constructor(body) {
    super(...arguments);

    this.name = 'CriticalError';
    this.commonErrors = body && body.commonErrors;
  }
}

/**
 * Ошибка стороннего сервиса
 * и/или
 * Неправильно переданные параметры серверу (Ошибка валидации)
 */
export class ValidationError extends ExternalException {
  /**
   * @param commonErrors массив ошибок, полученный от стороннего сервиса
   * @param body тело ошибки
   */
  constructor(body) {
    super(...arguments);

    this.name = 'ValidationError';
    this.commonErrors = body && body.commonErrors;
    this.fieldErrors = body && body.fieldErrors;
  }
}

/**
 * Запрошенная сущность не может быть найдена на сервере
 */
export class NotFoundError extends ExternalException {
  /**
   * @param body тело ошибки
   */
  constructor(body) {
    super(...arguments);

    this.name = 'NotFoundError';
    this.commonErrors = body && body.commonErrors;
  }
}

/**
 * Конфликт сущностей на сервере
 */
export class ConflictError extends ExternalException {
  /**
   * @param body тело ошибки
   */
  constructor(body) {
    super(...arguments);

    this.name = 'ConflictError';
    this.commonErrors = body && body.commonErrors;
  }
}

/**
 * Внутренняя ошибка сервера
 */
export class InternalServerError extends ExternalException {
  /**
   * @param body тело ошибки
   */
  constructor(body) {
    super(...arguments);

    this.name = 'InternalServerError';
    this.commonErrors = process.env.NODE_ENV === 'development' ? body && body.commonErrors : [COMMON_MESSAGE];
  }
}

/**
 * Ошибка поключения к сети
 */
export class NetworkError extends ExternalException {
  constructor() {
    super(...arguments);

    this.name = 'NetworkError';
    this.commonErrors = ['Ошибка сети. Сервис временно не отвечает или нет подключения к сети интернет.'];
  }
}

/**
 * Тело ошибки не соответстывует стандарту
 */
export class InvalidBodyError extends ExternalException {
  /**
   * @param body тело ошибки
   */
  constructor(body) {
    super(...arguments);

    this.name = 'InvalidBodyError';
    this.commonErrors = process.env.NODE_ENV === 'development' ? ['Тело ошибки не определено'] : [COMMON_MESSAGE];
    this.data = body;
  }
}

/**
 * Ошибка подтверждения по смс
 */
export class SmsConfirmError extends ExternalException {
  constructor() {
    super(...arguments);

    this.name = 'SmsConfirmError';
    this.commonErrors = ['Количество попыток ввода SMS кода исчерпано'];
  }
}

/**
 * Неизвестный статус платежа
 */
export class UnknownPaymentStatusError extends ExternalException {
  constructor() {
    super(...arguments);

    this.name = 'UnknownPaymentStatusError';
  }
}

export class CancelRequestError extends ExternalException {
  constructor() {
    super(...arguments);

    this.name = 'CancelRequestError';
    this.commonErrors = ['Операция отменена'];
  }
}
