import React from 'react';
import { SBPInfoDescription } from '../styled';

const SBPInfo = () => {
  return (
    <SBPInfoDescription>
      <div>
        <p>
          Система быстрых платежей – сервис, позволяющий клиентам банков-участников СБП совершать переводы по номеру
          мобильного телефона, а также производить оплату по QR-коду.
        </p>
      </div>
    </SBPInfoDescription>
  );
};

export default SBPInfo;
