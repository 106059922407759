import React from 'react';
import { FieldDatePicker } from '../../Controls/DatePicker';

import { AmountBox, AmountInput, AmountLabel } from './styled';

const DateField = () => {
  return (
    <AmountBox style={{ marginTop: 20 }}>
      <AmountLabel>Дата первого платежа:</AmountLabel>
      <AmountInput>
        <FieldDatePicker label={'Выберите дату'} name={'firstDateUtc'} />
      </AmountInput>
    </AmountBox>
  );
};

export default DateField;
