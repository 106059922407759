import React from 'react';
import Result from '../../Components/Blocks/Result';
import { withCloseInFrame } from '../../Components/HOC';

const PaymentStatus = (props) => {
  let topic;
  let message;

  if (props.status === 'Pending') {
    topic = 'Ваш запрос принят.';
    message = 'Результат операции будет отправлен в SMS.';
  } else if (props.status === 'Succeed') {
    topic = 'Ваш платеж успешно выполнен';
  }

  return <Result topic={topic} message={message} buttonLabel={'Закрыть'} onClick={props.onClose} />;
};

export default withCloseInFrame(PaymentStatus);
