import styled from 'styled-components';
import { breakpoints } from '../../../../Constants/css';

export const SubscriberAccount = styled.div`
  margin: 0 12px 0 0;

  @media (min-width: ${breakpoints.mobile}) {
    min-width: 250px;
  }

  flex: 1;
  flex-basis: auto; //Fix IE11

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0;

    align-self: stretch;
  }
`;

export const Amount = styled.div`
  @media (min-width: ${breakpoints.mobile}) {
    min-width: 250px;
  }

  flex: 1;
  flex-basis: auto; //Fix IE11

  @media (max-width: ${breakpoints.mobile}) {
    align-self: stretch;
  }
`;

export const FieldWidth = styled.div`
  min-height: 82px;
  @media (min-width: ${breakpoints.mobile}) {
    min-width: ${(p) => p.width};
  }
`;

export const TotalTopicBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardInfoButtonBox = styled.div`
  margin-top: 30px;
`;

export const Condition = styled.div`
  flex: 1;
  flex-basis: auto; //Fix IE11
`;

export const BlockTitle = styled.h2`
  margin: 0 0 20px 0;
  font-size: 15px;
`;
