/**
 * 'Request' Control Component
 *
 * @flow
 */
import React from 'react';
import { Icon } from 'react-icons-kit';
import { time } from 'react-icons-kit/ikons/time';

import { Wrapper, Label, IconBox } from './styled';

export default function Request() {
  return (
    <Wrapper data-testid={'block-request'}>
      <Label data-testid={'block-request-label'}>Запрос в обработке</Label>
      <IconBox>
        <Icon icon={time} size={60} />
      </IconBox>
    </Wrapper>
  );
}
