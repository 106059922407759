//@flow
import React from 'react';

import Failed from '../../../Components/Blocks/Failed';
import Request from '../../../Components/Blocks/Request';
import { withCloseInFrame } from '../../../Components/HOC';
import { convertKopecksToRublesAsInteger } from '../../../Helpers/Currency';
import { formalizeMsisdn } from '../../../Helpers/Normalize';
import PaymentService from '../../../Services/PaymentService';
import SucceedWithAutopayment from '../Succeed/Autopayment';
import SucceedWithBindCard from '../Succeed/BindCard';
import SucceedWithSubscriberAccount from '../Succeed/SubscriberAccount';
import SucceedWithCardBindAlien from '../Succeed/BindCardAlien';

const SucceededWithAutopayment = SucceedWithAutopayment;
const SucceededWithCardBind = SucceedWithBindCard;
const SucceededWithSubscriberAccount = SucceedWithSubscriberAccount;
const SucceededWithCardBindAlien = SucceedWithCardBindAlien;

type TProps = {
  /**ID платежа статус которого надо проверить и отобразить на экране */
  paymentId: string,
  /**Текст успешного сообщения в случае, если платеж в статусе Succed */
  successMessage: string,
  /**Текст неуспешного сообщения в случае, если платеж в статусе Rejected */
  failedMessage: string,
  /**Обработчик закрытия окна формы */
  onClose: Function,
  /**Отображать ли сумму платежа в информации
   * TODO: Необходимо продумать как управлять полями отображения информации о платеже гибче
   */
  withoutAmountInfo: boolean,
  /** Тип платежа: привязка карты или пополнение баланса */
  paymentType: string,
  /** Is redirect route: bindcard */
  isBindCard: boolean,
  /** Is redirect route: subscriberAccountPayment */
  isSubscriberAccountPayment: ?boolean,
  /** Is redirect route: bindcardalien */
  isBindCardAlien: boolean,
};

type TState = {
  payment: ?{
    msisdn: string,
    amount: number,
  },
  isCardBound: boolean,
  error: string,
};

class Result extends React.PureComponent<TProps, TState> {
  state = {
    payment: null,
    error: '',
    isCardBound: false,
  };

  async resolvePayment() {
    const { paymentId, isBindCard, isBindCardAlien } = this.props;
    let payment;
    let isCardBound = false;

    try {
      if (paymentId) {
        const { status, reason } = await PaymentService.getPaymentExactStatus({
          paymentId,
          expectedStatuses: ['Succeed', 'Rejected'],
        });

        if (status === 'Succeed') {
          payment = await PaymentService.getPaymentInfo({ paymentId });
        } else {
          const error = reason ? reason.description : '';
          throw new Error(error);
        }
        try {
          await PaymentService.getCardLoop({ paymentId });
          isCardBound = true;
        } catch (error) {
          if (isBindCard || isBindCardAlien) throw error;
        }

        this.setState({
          payment: {
            msisdn: payment.msisdn,
            amount: convertKopecksToRublesAsInteger(parseInt(payment.amount, 10)),
          },
          isCardBound,
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setState({
        error: error.message,
      });
    }
  }

  componentDidMount() {
    this.resolvePayment();
  }

  _isRequest() {
    return this.props.paymentId && !this.state.error && !this.state.payment;
  }

  _isPaymentInfo() {
    return this.state.payment && !this.state.error;
  }

  render() {
    const { isCardBound, payment, error } = this.state;
    const { msisdn = '', amount = '' } = payment || {};

    const {
      paymentId,
      successMessage,
      failedMessage,
      withoutAmountInfo,
      onClose,
      paymentType,
      isSubscriberAccountPayment,
      isAlien,
    } = this.props;

    const fields = [
      {
        label: 'Номер телефона',
        value: formalizeMsisdn(msisdn),
        key: 'field1',
      },
    ];

    if (!withoutAmountInfo) {
      fields.push({
        label: 'Сумма платежа',
        value: `${amount} ₽`,
        key: 'field2',
      });
    }

    var Succeed;

    if (isSubscriberAccountPayment) {
      Succeed = (
        <SucceededWithSubscriberAccount
          {...{
            message: successMessage,
            amount,
          }}
        />
      );
    } else if (isAlien) {
      Succeed = <SucceededWithCardBindAlien />;
    } else {
      Succeed = isCardBound ? (
        <SucceededWithAutopayment
          {...{
            message: successMessage,
            msisdn,
            amount,
            paymentId,
            fields,
            paymentType,
          }}
        />
      ) : (
        <SucceededWithCardBind
          {...{
            message: successMessage,
            msisdn,
            paymentId,
            fields,
            paymentType,
            amount,
          }}
        />
      );
    }

    return (
      <>
        {this._isRequest() && <Request />}

        {this._isPaymentInfo() && Succeed}

        {error && (
          <Failed
            topic={'Операция не выполнена!'}
            message={error ? error : failedMessage}
            buttonLabel={'Закрыть'}
            onClick={onClose}
          />
        )}
      </>
    );
  }
}

export default withCloseInFrame(Result);
