import React from 'react';

import FieldTextInput from '../../Controls/TextInput/FieldTextInput';
import { Row } from '../Form';
import { cardFieldsWidth } from './const';
import { CardFieldsWrapper, FieldWidth } from './styled';

const CardFields = (props) => {
  const { setFieldValue } = props;

  const onBlur = (event) => {
    setFieldValue('pan', event.target.value.trim(), false);

    // Fix problem with react-text-mask lib, that add space by cardNumber mask
    event.target.value = event.target.value.trim();
  };

  return (
    <>
      <Row>
        <FieldWidth width="520px">
          <FieldTextInput
            type={'tel'}
            name={'pan'}
            maskType={'cardNumber'}
            label={'Номер банковской карты'}
            required
            autoComplete={'cc-number'}
            onBlur={onBlur}
          />
        </FieldWidth>
      </Row>
      <CardFieldsWrapper>
        {cardFieldsWidth.map((field) => (
          <FieldWidth key={field.key} width="250px">
            <FieldTextInput
              type={field.type}
              name={field.name}
              maskType={field.maskType}
              label={field.label}
              autoComplete={field.autoComplete}
              placeholder={field.placeholder}
              maxLength={field.maxLength}
              ref={field.ref}
              required
            />
          </FieldWidth>
        ))}
      </CardFieldsWrapper>
    </>
  );
};

export default React.memo(CardFields);
