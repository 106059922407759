/**
 * 'Button's Control Component
 *
 * @flow
 */
import React from 'react';

import { Wrapper } from './styled';

type TProps = {
  /** Текст кнопки */
  label: string | JSX.Element,
  /** Признак "альтернативного стиля" кнопки */
  alternate?: boolean,
  /** Признак "деактивированной" кнопки */
  disabled?: boolean,
  /** Признак "скругленного стиля" кнопки */
  rounded?: boolean,
  /** Признак "безрамочного стиля" кнопки */
  borderless?: boolean,
  width?: string,
};

function Button(props: TProps) {
  return (
    <Wrapper type={'button'} {...props}>
      {props.label}
    </Wrapper>
  );
}

export default React.memo(Button);
