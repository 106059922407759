import React from 'react';
import { compose } from 'recompose';

import { withErrorsHandle, withQueryParams } from '../../Components/HOC';

import Layout from '../../Components/Blocks/Layout';
import B2bResult from './B2bResult';

class BindCardPage extends React.Component {
  render() {
    return (
      <Layout title={'Привязка банковской карты'}>
        {this.props.queryParams.paymentid && (
          <B2bResult failedMessage={'Карта не привязана!'} paymentId={this.props.queryParams.paymentid} />
        )}
      </Layout>
    );
  }
}

export default compose(
  withQueryParams,
  withErrorsHandle
)(BindCardPage);
