import styled from 'styled-components';
import { breakpoints } from '../../../Constants/css';

export const Wrapper = styled.div`
  display: flex;

  flex: 1;
  flex-basis: auto; //Fix IE11
  flex-direction: column;
  height: 100%;

  @media (max-width: ${breakpoints.mobile}) {
    border: 0;
  }
`;

export const Topic = styled.div`
  font-size: 22px;
  font-weight: normal;

  color: #1f2229;
`;

export const Note = styled.div`
  font-size: 16px;

  color: #8f9399;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: ${breakpoints.desktop}) {
    flex-basis: auto; //Fix IE11
  }

  flex-basis: auto; //Fix IE11
  flex-direction: column;
`;

export const ButtonSpace = styled.div`
  height: 20px;
`;

export const Oferta = styled.div`
  margin: 36px 0 0 0;

  font-size: 16px;

  color: #8f9399;

  @media (max-width: ${breakpoints.mobile}) {
    margin: 32px 0 0 0;
    font-size: 14px;
  }

  > a {
    outline: none;

    color: #8f9399;

    &:hover {
      color: #444852;
    }
  }
`;

export const LinkBox = styled.div`
  margin: 19px 0 0 0;
`;
