//
// @flow
//
import { compose } from 'recompose';

export function sliceExpirationDate(expirationDate: string): { expirationMonth: string, expirationYear: string } {
  return {
    expirationMonth: expirationDate.slice(0, 2),
    expirationYear: expirationDate.slice(-2),
  };
}

export function normalizePan(pan: string = ''): string {
  return deleteSpaceCharacters(pan);
}

export function normalizeMsisdnToTenNumbers(msisdn: string = ''): string {
  return compose(deleteFirstSevenCharacter, deleteNonDigitsCharacters)(msisdn);
}

export function normalizeMsisdnToElevenNumbers(msisdn: string = ''): string {
  return compose(addFirstSevenCharacter, normalizeMsisdnToTenNumbers)(msisdn);
}

export function formalizeMsisdn(msisdn: string = ''): string {
  const m = msisdn.replace(/^\+7/g, '').replace(/[^\d]/g, '');

  return `+7 ${m[0] + m[1] + m[2]} ${m[3] + m[4] + m[5]} ${m[6] + m[7]} ${m[8] + m[9]}`;
}

export function deleteSpaceCharacters(text: string = ''): string {
  return text.replace(/\s/g, '');
}

export function deleteFirstSevenCharacter(text: string = ''): string {
  return text.replace(/^7/g, '');
}

export function deleteFirstSevenCharacterAccordingToLength(text: string = ''): string {
  if (text.length === 11) {
    return text.replace(/^7/g, '');
  } else {
    return text;
  }
}

export function addFirstSevenCharacter(text: string = ''): string {
  return `7${text}`;
}

export function deleteNonDigitsCharacters(text: string = ''): string {
  return text.replace(/[^\d]/g, '');
}
