import { compose } from 'recompose';
import withCommonRequestProcess from '../../../../../Helpers/withCommonRequestProcess';
import AutopaymentService from '../../../../../Services/AutopaymentService';
import { CATEGORY_PERIODIC, CATEGORY_SUBSCRIPTIONFEE, CATEGORY_THRESHOLD } from '../Form';

let cardId;

export const setCardId = nextCardId => (cardId = nextCardId);

const pushAutopayments = async (values, formikBag) => {
  const { msisdn, onSuccess } = formikBag.props;
  const { amount, firstDateUtc, type: billingServiceId, category } = values;

  switch (category) {
    case CATEGORY_THRESHOLD: {
      await AutopaymentService.setThreshold({
        msisdn,
        cardId,
        amount,
        billingServiceId,
      });
      break;
    }
    case CATEGORY_PERIODIC: {
      await AutopaymentService.setPeriodic({
        msisdn,
        cardId,
        amount,
        billingServiceId,
        firstDateUtc: new Date(firstDateUtc).toISOString(),
      });
      break;
    }
    case CATEGORY_SUBSCRIPTIONFEE: {
      await AutopaymentService.setSubscriptionFee({
        msisdn,
        billingServiceId,
        amount,
        cardId,
      });
      break;
    }
    default: {
    }
  }

  onSuccess(amount);
};

const adapterCommonRequestProcess = func => (values, formikBag) => {
  const { fetching, setCommonError, onFail: onError } = formikBag.props;
  const { setErrors } = formikBag;
  func({ fetching, setCommonError, onError, setErrors }, values, formikBag);
};

export default compose(
  adapterCommonRequestProcess,
  withCommonRequestProcess
)(pushAutopayments);
