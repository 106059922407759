/**
 * 'Panel's Styled Components
 *
 * @flow
 */
import styled, { css } from 'styled-components';
import { breakpoints, colors, rules } from '../../../Constants/css';

// rules
const themes = {
  alternate: css`
    max-width: 443px;
    min-width: 235px;

    @media (max-width: ${breakpoints.mobile}) {
      padding-bottom: 31px;
      max-width: ${breakpoints.mobile};
    }

    @media (min-width: 641px) and (max-width: ${breakpoints.desktop}) {
      max-width: 580px;
      border-top: none;
    }

    @media (min-width: ${breakpoints.desktop}) {
      width: 443px;
      border-left: none;
    }
  `,
};

export const Wrapper = styled.div`
  /* border: 2px solid ${colors.common.black}; */
  padding: 30px 30px 58px;
  max-width: 580px;
  @media (min-width: ${breakpoints.tablet}) {
    ${(p) => p.width && `width: ${p.width};`}
  }

  flex: 1;
  flex-basis: auto; //Fix IE11
  flex-basis: auto; //IE11 fix

  background-color: #fff;

  @media (max-width: ${breakpoints.mobile}) {
    ${rules.borderless};
    padding: 0 15px 14px 15px;
  }

  ${(p) => p.alternate && themes.alternate};
`;
