import { ValidationError } from '../../../../Constants/errors';
import PaymentService from '../../../../Services/PaymentService';

const withCommonHandle = (func) => async (values, formikBag) => {
  const { props, setErrors } = formikBag;
  props.setCommonError('');
  props.fetching.start();

  try {
    await func(values, formikBag);
  } catch (error) {
    if (error instanceof ValidationError) {
      // устанавливаем валидационные ошибки
      if (error.fieldErrors && Object.keys(error.fieldErrors).length > 0) {
        setErrors({
          ...error.fieldErrors,
        });
      }

      if (error.commonErrors && error.commonErrors.length > 0) {
        props.setCommonError(error.commonErrors.join('/n'));
      }
    } else {
      props.onError(error);
    }
  }

  props.fetching.stop();
};

const topUp = async ({ isMobile, ...values }, { props }) => {
  const { electronReceiptType, pan, expirationDate, cvv, electronReceiptEmail, electronReceiptMobile } = values;
  const { onThreeDSSuccess, onAreqSucceed, onFrameSuccess, payId, channel } = props;

  const callbackRoute = 'selfregistration';

  const { paymentId } = await PaymentService.hold(
    {
      pan,
      expirationDate,
      cvv,
      receipt: {
        email: electronReceiptType === 'email' ? (electronReceiptEmail ? electronReceiptEmail : null) : null,
        mobile: electronReceiptType === 'mobile' ? (electronReceiptMobile ? electronReceiptMobile : null) : null,
      },
      callbackRoute,
      paymentId: payId,
    },
    { channel }
  );

  const handleWaiting3DsStatus = async () => {
    const threeDSData = await PaymentService.getThreeDS({ paymentId });
    await onThreeDSSuccess(threeDSData);
  };

  const handleHoldStatus = () => {
    onAreqSucceed(paymentId);
  };

  const handleStatusError = (reason, backLink) => {
    if (backLink) {
      return handleHoldStatus();
    }
    if (reason) {
      throw new ValidationError({
        commonErrors: [reason.description],
      });
    } else {
      throw new ValidationError({
        commonErrors: ['Произошла техническая ошибка, повторите запрос позднее.'],
      });
    }
  };

  const handleWaitingFrameStatus = async () => {
    const frameData = await PaymentService.getFrame({ paymentId });
    await onFrameSuccess(frameData);

    const { status, reason, backLink } = await PaymentService.getPaymentExactStatus({
      paymentId,
      expectedStatuses: ['Waiting3Ds', 'Pending', 'Rejected', 'Hold'],
      tries: 10,
    });

    switch (status) {
      case 'Hold':
        handleHoldStatus();
        break;
      case 'Waiting3Ds':
        await handleWaiting3DsStatus();
        break;
      case 'Pending':
        await paymentExactStatus('WaitingFrame');
        break;
      case 'WaitingFrame':
        await PaymentService.confirmFrameThreeDS({ paymentId, threeDSCompInd: 'N' });
        await paymentExactStatus('WaitingFrame');
        break;
      default:
        handleStatusError(reason, backLink);
    }
  };

  const paymentExactStatus = async (excl) => {
    const expectedStatuses = ['Waiting3Ds', 'WaitingFrame', 'Rejected', 'Hold'].filter((sts) => sts !== excl);
    const {
      status: statusByRequest,
      reason,
      backLink,
    } = await PaymentService.getPaymentExactStatus({
      paymentId,
      expectedStatuses,
    });
    const status = expectedStatuses.find((expectedSts) => expectedSts === statusByRequest);

    switch (status) {
      case 'Waiting3Ds':
        await handleWaiting3DsStatus();
        break;
      case 'WaitingFrame':
        await handleWaitingFrameStatus();
        break;
      case 'Hold':
        handleHoldStatus();
        break;
      default:
        handleStatusError(reason, backLink);
    }
  };

  await paymentExactStatus();
};

export default withCommonHandle(topUp);
