/**
 * withCloseInFrame HOC
 *
 * @flow
 */
import React from 'react';
import { androidCb, iframeCb, iOsCb } from '../../Helpers';

export default function withCloseInFrame(Component: React$ElementType) {
  return class withFetchingHOC extends React.Component<*, *> {
    /**
     * Метод оправки "хозяину" iFrame события о закрытии окна приложения
     */
    handleClose = () => {
      if (window.top !== window.self) {
        console.log('Post message to parent window for closing Form');
        return iframeCb('tele2.paymentDialog.close');
      }

      if (window.webkit) {
        iOsCb('close');
      }

      if (window.Android) {
        androidCb('close');
      }
    };

    render() {
      return <Component onClose={this.handleClose} {...this.props} />;
    }
  };
}
