/**
 * 'Layout', Block Component
 *
 * @flow
 */
import React from 'react';
import { Icon } from 'react-icons-kit';
import { iosCloseEmpty as CloseIcon } from 'react-icons-kit/ionicons/iosCloseEmpty';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import styled from 'styled-components';

import Title from '../../Controls/Title';
import { withCloseInFrame, withQueryParams } from '../../HOC';
import { Container, Header, Wrapper, Logo } from './styled';
import { convertStringToBoolean } from '../../../Helpers/Currency';

type TProps = {
  /** Заголовок */
  title: string,
  /** Вложенные элементы */
  children: any /** React.ReactNode */,
  /** Обработчик закрытия окна */
  onClose: Function,
  /** Query параметры */
  queryParams: Object,
};

const StyledIcon = styled(Icon)`
  cursor: pointer;
  display: flex;

  justify-content: center;
  color: #000;

  :hover {
    color: #444852;
  }

  @media (max-width: 640px) {
    > svg {
      width: 50px;
      height: 50px;
    }

    width: 50px;
    height: 50px;
  }
`;

const Layout = (props: TProps) => {
  const isCloseable = convertStringToBoolean(props.queryParams.closeable);
  return (
    <Wrapper>
      <Container>
        {/* <Logo /> */}
        <Header titleCentered={props.titleCentered}>
          <Title text={props.title} alignLeft={isCloseable} />
          {isCloseable ? <StyledIcon icon={CloseIcon} size={64} onClick={props.onClose} /> : null}
        </Header>
        {props.children}
      </Container>
    </Wrapper>
  );
};

export default compose(withRouter, withQueryParams, withCloseInFrame)(React.memo(Layout));
