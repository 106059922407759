import PaymentsMeansService from '../../../../Services/PaymentsMeansService';

export default async (props) => {
  const { values, isCheckCardFetched, isSberPayButtonVisible, setIsSberPayButtonVisible, setIsCheckCardFetched } =
    props;
  const { pan, expirationDate } = values;

  try {
    if (!isCheckCardFetched && !isSberPayButtonVisible) {
      setIsCheckCardFetched(true);

      await PaymentsMeansService.checkCard({ pan, expirationDate });
      setIsSberPayButtonVisible(true);
    }
  } catch {}
};
