import { createReducer } from 'redux-act';

import { setResponse } from '../actions';

const initialState = [];

export const responseListReducer = createReducer({}, initialState).on(setResponse, (state, payload) => [
  ...state,
  payload,
]);
