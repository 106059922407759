/**
 * 'Switcher', Control Component
 *
 * @flow
 */
import React from 'react';

import Box from './Box';
import Toggler from './Toggler';

type TProps = {
  /** Состояние "включен/выключен" */
  on: boolean,
  /** Состояние "недоступен" */
  disabled?: boolean,
  /** Событие переключения состояния */
  onClick: () => *,
};

export default function Switcher(props: TProps) {
  const { on, disabled, onClick } = props;
  let isSwitched = disabled ? false : on;

  return (
    <Box {...{ on: isSwitched, disabled, onClick }}>
      <Toggler on={isSwitched} />
    </Box>
  );
}
