import SubscriberService from '../../../../Services/SubscriberService';
import { CriticalError } from '../../../../Constants/errors';
import { setSubscriberId, setAcceptCodeId, setTokenId } from './sbpPay';
import { compose } from 'recompose';
import withCommonRequestProcess from '../../../../Helpers/withCommonRequestProcess';

const acceptSbpCode = async (props) => {
  if (!props.values.isSbpBind && !props.errors.msisdn) {
    const { msisdn } = props.values;

    const { subscriberId, isBlocked } = await SubscriberService.getOrCreateSubscriber({
      msisdn,
    });

    if (isBlocked) {
      throw new CriticalError({
        commonErrors: [`Номер ${msisdn} заблокирован для сохранения`],
      });
    }

    const { sbpTokenId } = await SubscriberService.addSbpTokenToSubscriber({
      subscriberId,
      msisdn,
    });

    const { acceptCodeId } = await SubscriberService.sendAcceptCode({
      subscriberId,
    });

    setSubscriberId(subscriberId);
    setAcceptCodeId(acceptCodeId);
    setTokenId(sbpTokenId);

    props.setFieldTouched('sbpAcceptCode', false);
    props.setValues({
      ...props.values,
      sbpAcceptCode: '',
      isSbpBind: true,
    });
  } else {
    props.setSbpRemainingQuantity('');
    props.setFieldTouched('sbpAcceptCode', false);
    props.setValues({
      ...props.values,
      sbpAcceptCode: '',
      isSbpBind: false,
    });
  }
};

const adapterCommonRequestProcess = (func) => {
  return (props) => {
    const { setErrors, fetching, setCommonError, onError } = props;
    func(
      {
        setErrors,
        fetching,
        setCommonError,
        onError,
      },
      props
    );
  };
};

export default compose(adapterCommonRequestProcess, withCommonRequestProcess)(acceptSbpCode);
