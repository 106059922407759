import * as React from 'react';
import { Oferta, Buttons } from '../../../../../Components/Blocks/DetailsPanel';
import { CardInfoButtonBox } from '../styled';
import Button from '../../../../../Components/Controls/Button';
import SavePayButton from '../../../../../Components/Blocks/SavePayButton';
import SecureBadges from '../../../../../Components/Blocks/SecureBadges';
import SecureSign from '../../../../../Components/Blocks/SecureSign';
import { withMobile } from '../../../../../Components/HOC';

const CardInfo = (props) => {
  return (
    <>
      <CardInfoButtonBox>
        <SavePayButton>
          <Buttons>
            <Button label={'Оплатить'} type={'submit'} disabled={!props.isValid} big={!props.isMobile} width="158px" />
          </Buttons>
        </SavePayButton>
      </CardInfoButtonBox>
      <SecureSign />
      <SecureBadges />
      <Oferta>
        Нажимая «Оплатить», вы подтверждаете согласие с{' '}
        <a href={'https://tele2.ru/payoferta'} target={'_blank'}>
          Условиями предоставления услуги
        </a>
      </Oferta>
    </>
  );
};

export default withMobile(CardInfo);
