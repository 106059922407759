import React from 'react';

import Switcher from '../../../../../Components/Controls/Switcher';
import { BindBox, BindHint, BindText, BindWrapper } from '../styled';

const Bind = (props) => {
  const { onClick, showHint, hintText, bindText, disabled, value } = props;

  return (
    <BindWrapper>
      <BindBox disabled={disabled}>
        <Switcher onClick={onClick} on={value} disabled={disabled} />
        <BindText>{bindText}</BindText>
      </BindBox>
      {showHint && <BindHint>{hintText}</BindHint>}
    </BindWrapper>
  );
};

export default React.memo(Bind);
