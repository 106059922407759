/**
 * 'SecureBadges' Component
 *
 * @flow
 */
import React from 'react';
import { images } from '../../../Constants/css';
import { Wrapper } from './styled';

const SecureBadges = () => {
  return (
    <Wrapper>
      <div>
        <img src={images.pciSecure} alt={''} height={'36px'} />
        <img src={images.visaSecure} alt={''} height={'36px'} />
      </div>
      <div>
        <img src={images.masterCardSecure} alt={''} height={'36px'} />
        <img src={images.mirSecure} alt={''} height={'36px'} />
      </div>
    </Wrapper>
  );
};

export default SecureBadges;
