import React, { memo } from 'react';
import Bind from './Bind';
import BindCode from './BindCode';

const CardBind = (props) => {
  const { values, errors, onCardBind, remainingQuantity, isCardValid } = props;

  const showHint = isCardValid && !Boolean(errors.msisdn) && !values.isCardBind;
  const disabled = !isCardValid || Boolean(errors.msisdn);

  return (
    <>
      <Bind
        value={values.isCardBind}
        disabled={disabled}
        showHint={showHint}
        onClick={onCardBind}
        hintText="Не забудьте сохранить карту"
        bindText="Привязать карту для быстрого пополнения баланса"
      />
      <BindCode
        msisdn={values.msisdn}
        isBind={values.isCardBind}
        fieldName="acceptCode"
        remainingQuantity={remainingQuantity}
      />
    </>
  );
};

export default memo(CardBind);
