/**
 * 'RadioButton', Control Component
 *
 * @flow
 */
import React from 'react';
import { TabButton, Label, Image, LabelContainer } from './styled';
import RadioIcon from '../RadioIcon';

type TProps = {
  label: string,
  checked?: boolean,
  width?: string,
  onChange: () => *,
  image?: string,
  name?: string,
};

const RadioButton = (props: TProps) => {
  const { label = '', checked, onChange, width, image, name = '' } = props;

  const onKeyDown = e => {
    if (e.keyCode === 32) {
      onChange();
    }
  };

  return (
    <TabButton
      width={width}
      checked={checked}
      onClick={onChange}
      role="radio"
      tabIndex="0"
      onKeyDown={onKeyDown}
      data-testid={`radio-button-${name}`}
    >
      {image && <Image src={image} />}
      <LabelContainer>
        <Label>{label}</Label>
        <RadioIcon checked={checked} />
      </LabelContainer>
    </TabButton>
  );
};

RadioButton.defaultProps = {
  label: '',
  disabled: false,
  checked: false,
  width: '',
  image: null,
};

export default RadioButton;
