/**
 * 'Autopayment's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';
import { fonts } from '../../../../../../Constants/css';

export const Wrapper = styled.div`
  display: flex;

  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;

  align-items: center;
`;

export const Title = styled.div`
  font-family: ${fonts.alternate};
  font-weight: bold;
  font-size: 16px;
`;

export const Separator = styled.div`
  flex: 1;
  flex-basis: auto; //Fix IE11
`;

export const Notice = styled.div`
  padding-bottom: 27px;
  max-width: 300px;
  width: calc(100% - 50px);

  font-family: ${fonts.alternate};
  font-size: 16px;
  color: #8f9399;

  > strong,
  a {
    font-size: 16px;
    color: #8f9399;
  }
`;
