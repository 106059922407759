import yup from 'yup';

const withLoopSchema = yup.boolean();
const acceptCodeSchema = yup
  .string()
  .when('withLoop', (withLoop, schema) =>
    withLoop ? schema.required('Обязательно поле').nullable() : schema.nullable()
  );
const acceptCodeConfirmSchema = yup
  .string()
  .when('isCardBind', (isCardBind, schema) =>
    isCardBind
      ? schema
          .required('Введите 4-х значный код из sms')
          .min(4, 'Введите 4-х значный код из sms')
          .max(4, 'Введите 4-х значный код из sms')
      : schema.nullable()
  );
const electronReceiptEmailSchema = yup.string().required('Обязательное поле').email('Некорректное значение поля');

export const bindCardValidationSchema = {
  withLoopSchema,
  acceptCodeSchema,
  acceptCodeConfirmSchema,
};

export const bindCardAlienValidationSchema = {
  withLoopSchema,
  acceptCodeSchema,
  acceptCodeConfirmSchema,
  electronReceiptEmailSchema,
};
