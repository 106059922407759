//
// @flow
//
import { css } from 'styled-components';

const URL = '/assets/images/';

export const breakpoints = {
  desktop: '1117px',
  tablet: '768px',
  mobile: '640px',
};

export const fonts = {
  main: 'tele2_displayserif-regular',
  alternate: 'tele2_textsans_regular',
};

export const colors = {
  themes: {
    main: '#1f2229',
    alternate: '#1f2229',
  },
  common: {
    grey: '#f0f1f2',
    black: '#1f2229',
    lightBlue: '#3fcbff',
  },
  text: {
    main: '#1f2229',
    disabled: '#a2abb4',
  },
  fail: '#d81b3b',
  bg: {
    disabled: '#f2f3f4',
  },
  border: {
    main: '#000',
    second: '#8f9399',
    disabled: '#a7abaf',
    normal: '#d3d9dF',
    hover: '#a2abb4',
  },
};

export const images = {
  checkbox: `${URL}checkbox.svg`,
  titleGreen: `${URL}title-green2.gif`,
  correctInput: `${URL}correct_input.png`,
  failInput: `${URL}fail_input.svg`,
  paySave: `${URL}pay-save.svg`,
  bgOpener: `${URL}bg-opener.png`,
  mirCard: `${URL}mir-logo.svg`,
  maestroCard: `${URL}maestro.svg`,
  masterVisaCard: `${URL}master_visa_card.png`,
  visaCard: `${URL}visa-inc-logo.svg`,
  masterCard: `${URL}mc-vrt-pos.svg`,
  emailReceipt: `${URL}email.svg`,
  mobileReceipt: `${URL}mobile.svg`,
  visaSecure: `${URL}visa-secure.svg`,
  masterCardSecure: `${URL}mastercard-mono.svg`,
  mirSecure: `${URL}mir-secure.svg`,
  pciSecure: `${URL}pci-secure.svg`,
  logo: `${URL}tele2-logo.svg`,
  sbpLogo: `${URL}sbp-logo.svg`,
  sbpText: `${URL}sbp-text.svg`,
  sbpLogoWithText: `${URL}sbp-logo-with-text.svg`,
  sberPayLogo: `${URL}sber-pay-logo.svg`,
};

export const rules = {
  borderless: css`
    border: 0 !important;

    :hover {
      border: 0 !important;
    }
  `,
  hidden: css`
    display: none;
  `,
};
