/**
 * 'Autopayment's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';
import { breakpoints } from '../../../../Constants/css';

export const GiftWrapper = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${breakpoints.mobile}) {
    margin: 24px 0 0 0;
  }
`;

export const GiftImage = styled.img`
  width: 58px;
  height: 58px;
`;

export const GiftMessage = styled.p`
  vertical-align: middle;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #1f2229;
  margin: 0px;
  flex: 1;
  flex-basis: 0px;

  > a {
    outline: none;
    color: #1f2229;

    &:hover {
      color: #444852;
    }
  }
`;

export const BlockTitle = styled.h2`
  margin: 0 0 20px 0;
  font-size: 15px;
`;

export const CheckCardMessage = styled.p`
  margin: 0 0 18px 0;

  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
`;

export const CheckCardSum = styled.span`
  white-space: nowrap;
`;
