import * as R from 'ramda';
import { compose, lifecycle, withProps, withStateHandlers } from 'recompose';
import yup from 'yup';
import { withFetching, withMobile } from '../../../Components/HOC';
import withFormik from '../../../Components/HOC/withFormikHelper';
import { schemas } from '../../../Helpers/validation';
import scenario from './Scenario';
import SelfRegistrationForm from './View';
import { cvvInputRef, expirationDateInputRef } from './View/refs';

export default compose(
  withMobile,
  withFetching,
  withStateHandlers(
    {
      commonError: '',
    },
    {
      setCommonError: () => (commonError) => ({ commonError }),
    }
  ),
  withProps((props) => ({
    values: {
      pan: '',
      expirationDate: '',
      cvv: '',
      electronReceiptEmail: props.email || '',
      electronReceiptMobile: '',
      electronReceiptType: 'email',

      threeDSData: null,

      channel: props.channel,
    },
  })),
  withFormik({
    handleSubmit: scenario,
    validationSchema: ({ values }) => {
      const { cvvSchema, panSchema, expirationDateSchema } = schemas;
      const schema = { cvvSchema, panSchema, expirationDateSchema };
      const validationSchema = yup.object(
        Object.keys(values).reduce((acc, key) => {
          if (schema[`${key}Schema`]) {
            acc[key] = schema[`${key}Schema`];
          }

          return acc;
        }, {})
      );

      return validationSchema;
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { ...prevValues } = prevProps.values;
      const { props } = this;
      const { ...values } = props.values;
      if (!R.equals(prevValues, values)) {
        props.setCommonError('');
      }

      if (
        prevProps.values.pan !== props.values.pan ||
        prevProps.values.expirationDate !== props.values.expirationDate ||
        prevProps.values.cvv !== props.values.cvv
      ) {
        props.setFieldValue('threeDSData', null, false);
      }

      /** It can't check formik's errors to set focus on next input,
       * because setting values and validating are separated operations,
       * so current value and error in one operation will be inconsistent.
       * This is how formik work under the hood.
       * That's why we need to call validation manually
       */
      if (prevProps.values.pan !== props.values.pan && schemas.panSchema.isValidSync(props.values.pan)) {
        expirationDateInputRef.current.input.focus();
      }

      if (
        prevProps.values.expirationDate !== props.values.expirationDate &&
        schemas.expirationDateSchema.isValidSync(props.values.expirationDate)
      ) {
        cvvInputRef.current.input.focus();
      }
    },
    componentDidMount() {
      const { props } = this;
      const touched = Object.keys(props.values).reduce((touched, key) => {
        if (props.values[key]) {
          touched[key] = true;
        }
        return touched;
      }, {});
      props.setTouched({ ...touched });
    },
  })
)(SelfRegistrationForm);
