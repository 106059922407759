//@flow

import * as React from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-advanced';

export type TFetchingActions = {
  delay: () => Promise<void>,
  start: () => Promise<void>,
  stop: () => Promise<void>,
};

type TState = {
  inProgress: boolean,
};

type TProps = {
  children: React.Node,
};

const Wrapper = styled.div`
  min-width: 260px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Spinner = function() {
  return (
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

const defaultValue = {
  start: async () => {},
  stop: async () => {},
  delay: async () => {},
};

const FetchingContext = React.createContext(defaultValue);

export const FetchingConsumer = FetchingContext.Consumer;

export default class FetchingProvider extends React.Component<TProps, TState> {
  providerValues: TFetchingActions;

  constructor(props: TProps) {
    super(props);

    this.providerValues = {
      delay: this.delay,
      start: this.start,
      stop: this.stop,
    };
  }

  state = {
    inProgress: false,
  };

  /**
   * Промисифицированный setState
   *
   * @private
   */
  asyncSetState = async (nextState: {}): Promise<void> => {
    return new Promise(resolve => {
      this.setState(nextState, resolve());
    });
  };

  /**
   * Задержка выполнения
   */
  delay = async (ms: number = 1000): Promise<void> => {
    return new Promise(next => setTimeout(next, ms));
  };

  /**
   * Старт асинхронного процесса
   */
  start = async () => {
    await this.asyncSetState({
      inProgress: true,
    });
  };

  /**
   * Остановка асинхронного процесса с успешным исходом
   */
  stop = async (): Promise<void> => {
    await this.asyncSetState({
      inProgress: false,
    });
  };

  render() {
    return (
      <Wrapper className={'layout'}>
        <Loader
          show={this.state.inProgress}
          message={<Spinner />}
          style={{ height: '100%', minHeight: '100vh' }}
          backgroundStyle={{ zIndex: 40, fontSize: 30 }}
        >
          <FetchingContext.Provider value={this.providerValues}>{this.props.children}</FetchingContext.Provider>
        </Loader>
      </Wrapper>
    );
  }
}
