import { ValidationError } from '../../../../Constants/errors';
import CardService from '../../../../Services/CardService';
import SubscriberService from '../../../../Services/SubscriberService';

const findSubscriber = async (values, formikBag) => {
  let subscriber;
  const { props, setFieldValue } = formikBag;
  const { pan, expirationDate, cvv, electronReceiptType, electronReceiptEmail, electronReceiptMobile } = values;
  let { msisdn, clientType, auth } = props;
  const cardData = {
    pan,
    expirationDate,
    cvv,
    receipt: {
      email: electronReceiptType === 'email' ? (electronReceiptEmail ? electronReceiptEmail : null) : null,
      mobile: electronReceiptType === 'mobile' ? (electronReceiptMobile ? electronReceiptMobile : null) : null,
    },
  };

  await CardService.getCardIdForBind({
    pan,
    expirationDate,
  });

  //b2b
  if (clientType) {
    subscriber = await SubscriberService.getOrCreateB2bSubscriber({
      msisdn,
      clientType,
    });
  } else {
    subscriber = await SubscriberService.getOrCreateSubscriber({
      msisdn,
    });
  }

  if (subscriber.isBlocked) {
    throw new ValidationError({
      commonErrors: [`Номер ${msisdn} заблокирован для сохранения карты`],
    });
  }

  const { cardId } = await SubscriberService.addCardToSubscriber({
    subscriberId: subscriber.subscriberId,
    ...cardData,
  });

  //b2b
  if (clientType) {
    if (!subscriber.accountMsisdn) {
      subscriber = await SubscriberService.getB2bSubscriber({
        msisdn,
      });
    }
    msisdn = subscriber.accountMsisdn;
  }

  const { categoryId, amount, serviceId } = props;

  const isAutopayment = categoryId && amount && serviceId;

  const subscriberInfo = {
    isAutopayment,
    auth: !isAutopayment ? true : auth,
    subscriber,
    cardId,
    clientType,
    cardData: {
      ...cardData,
      msisdn,
    },
  };
  setFieldValue('subscriberInfo', subscriberInfo);
  return subscriberInfo;
};

export default findSubscriber;
