import React from 'react';

import AutopaymentFields from '../../../../../../Components/Blocks/AutopaymentFields';
import Succeed from '../../../../../../Components/Blocks/Succeed';
import Switcher from '../../../../../../Components/Controls/Switcher';
import ErrorPanel from '../../../../../../Components/ErrorPanel';
import { Row, Separator, Title, Wrapper } from './styled';

const SucceedWithAutopaymentForm = (props) => {
  const { fields, message, categoriesList, typesList, onToggle, values, onCategoryChanged, subscriptionFee } = props;

  const isSubmitButtonDisabled = !props.isValid || !values.type || !values.category;

  return (
    <Wrapper>
      {props.commonError && (
        <div style={{ margin: '0px 0px 25px 0px' }}>
          <ErrorPanel text={props.commonError} />
        </div>
      )}
      <Succeed
        {...{
          fields,
          message,
          isSubmitButtonDisabled: isSubmitButtonDisabled,
          isSubmitButtonVisible: props.values.withAutopayment,
          submitButtonLabel: 'Подключить автоплатеж',
          onSubmit: props.handleSubmit,
        }}
      >
        <Wrapper>
          <Row>
            <Title>Подключить автоплатёж</Title>
            <Separator />
            <Switcher
              {...{
                on: values.withAutopayment,
                onClick: onToggle,
              }}
            />
          </Row>
          {/* <Row>
            <Notice>При подключении вы будете получать обратно 10% на свой счет</Notice>
          </Row> */}
          {values.withAutopayment && (
            <AutopaymentFields
              categoriesList={categoriesList}
              typesList={typesList}
              values={values}
              onCategoryChanged={onCategoryChanged}
              subscriptionFee={subscriptionFee}
            />
          )}
        </Wrapper>
      </Succeed>
    </Wrapper>
  );
};

export default React.memo(SucceedWithAutopaymentForm);
