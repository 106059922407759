/**
 * 'Select's Styles for 'ReactSelect'
 *
 * @flow
 */
export const MainStyle = {
  position: 'relative',
  outline: 'none',
  borderRadius: '8px',
  width: '100%',
  height: '60px',
  paddingRight: '13px',
  verticalAlign: 'top',
  boxShadow: 'none',
};

export const MenuContainerStyle = {
  marginTop: '4px',
  paddingTop: '10px',
  paddingBottom: '10px',
  outline: 'none',
  border: 'none',
  boxShadow:
    '0px 50px 30px -40px rgba(31, 34, 41, 0.02), 0px 2px 8px rgba(31, 34, 41, 0.04), 0px 17px 40px rgba(31, 34, 41, 0.07)',
  borderRadius: '8px',
  maxHeight: '520px',
  background: '#fff',
  zIndex: '10',
};

export const MenuStyle = {
  height: 'auto',
  maxHeight: '500px',
};
