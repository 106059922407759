import makeLoopAuth from './makeLoopAuth';
import makeThreeDSAuth from './makeThreeDSAuth';
import findSubscriber from './findSubscriber';
import unAuthAutoPayment from './unAuthAutoPayment';
import { ValidationError } from '../../../../Constants/errors';

import topup from './topup';

export default async (values, formikBag) => {
  formikBag.props.setCommonError('');
  formikBag.props.fetching.start();
  const { isCardBind } = values;
  try {
    if (isCardBind) {
      await topup(values, formikBag.props);
      await makeThreeDSAuth(values, formikBag);
    } else {
      if (values.withLoop) {
        await makeLoopAuth(values, formikBag);
      } else {
        const subscriberInfo = await findSubscriber(values, formikBag);
        const { isAutopayment, auth } = subscriberInfo;
        if (isAutopayment && !auth) {
          await unAuthAutoPayment({ subscriberInfo, ...values }, formikBag);
        } else {
          await makeThreeDSAuth({ subscriberInfo, ...values }, formikBag);
        }
      }
    }
  } catch (error) {
    if (error instanceof ValidationError) {
      // устанавливаем валидационные ошибки
      if (error.fieldErrors && Object.keys(error.fieldErrors).length > 0) {
        formikBag.setErrors({
          ...error.fieldErrors,
        });
      }

      if (error.commonErrors && error.commonErrors.length > 0) {
        formikBag.props.setCommonError(error.commonErrors.join('/n'));
      }
    } else {
      formikBag.props.onError(error);
    }
  }

  formikBag.props.fetching.stop();
};
