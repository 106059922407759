/**
 * 'Title's Components
 *
 * @flow
 */
import styled, { css } from 'styled-components';

import { breakpoints, fonts, colors, images } from '../../../Constants/css';

const underlined = css`
  > span {
    background-image: url(${images.titleGreen});
    background-repeat: repeat-x;
    background-position: 0 85%;
    background-size: auto 12px;
  }
`;

export const Header = styled.h1`
  text-align: ${(p) => (p.alignLeft ? 'left' : 'center')};
  font-family: ${fonts.main};
  font-size: 38px;
  font-weight: bold;
  margin: 0px;

  color: ${colors.text.main};

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 28px;
  }

  ${(p) => p.underlined && underlined};
`;
