/**
 * 'CardsImage' Control Component
 *
 * @flow
 */
import React from 'react';
import styled from 'styled-components';
import { breakpoints, images } from '../../Constants/css';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px

  & > img:not(:last-child) {
    margin-right: 12px;
  }

  @media (max-width: ${breakpoints.desktop}) {
    margin-top: 30px;
  }
`;
const CardsImage = () => {
  return (
    <Wrapper>
      <img src={images.mirCard} height={'16px'} alt={''} />
      <img src={images.masterCard} height={'21px'} alt={''} />
      <img src={images.visaCard} height={'17px'} alt={''} />
    </Wrapper>
  );
};

export default CardsImage;
