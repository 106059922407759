import { api, parseApiResponse } from '../common';

/**
 * Проверка возможности оплаты по SberPay по msisdn
 */

const ROOT = `/paymentsMeans`;

export const checkPaymentMeans = async function (params) {
  const response = await api.get(`${ROOT}/checkPaymentMeans`, params);

  return parseApiResponse(response);
};

/**
 * Проверка возможности оплаты по SberPay по pan
 */

export const checkCard = async function (params) {
  const response = await api.get(`${ROOT}/checkCard`, params);

  return parseApiResponse(response);
};
