import styled, { css } from 'styled-components';
import React from 'react';
import { breakpoints, colors } from '../../../../Constants/css';
import Button from '../../../../Components/Controls/Button';

export const Msisdn = styled.div`
  margin: 0 12px 0 0;

  @media (min-width: ${breakpoints.mobile}) {
    min-width: 250px;
  }

  flex: 1;
  flex-basis: auto; //Fix IE11

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0;

    align-self: stretch;
  }
`;

export const Amount = styled.div`
  @media (min-width: ${breakpoints.mobile}) {
    min-width: 250px;
  }

  flex: 1;
  flex-basis: auto; //Fix IE11

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0;

    align-self: stretch;
  }
`;

/** Bind row */
export const BindWrapper = styled.div`
  min-height: 45px;
  margin: 2px 0 4px 0;

  @media (max-width: ${breakpoints.mobile}) {
    margin: 2px 0 8px 0;
  }
`;

export const BindBox = styled.div`
  display: flex;
  align-items: center;

  color: ${(p) => (p.disabled ? '#8f9399' : '#1f2229')};

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 14px;
  }
`;

export const BindText = styled.p`
  margin-left: 12px;
  margin-top: 0;
  margin-bottom: 0;
`;

export const BindHint = styled.div`
  margin-top: 5px;
  font-size: 12px;

  color: ${colors.fail};
`;

export const FieldWidth = styled.div`
  @media (min-width: ${breakpoints.mobile}) {
    min-width: ${(p) => p.width};
  }
`;

export const TotalTopicBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TotalTopicAmount = styled.span`
  font-weight: bold;
`;

export const SBPTextBox = styled.div`
  margin-top: 30px;
`;

export const CardInfoButtonBox = styled.div`
  margin-top: 16px;
`;

export const SBPWrapper = styled.div`
  margin-top: 6px;
`;

export const SBPButton = styled.div`
  margin-top: 13px;
`;

export const SBPLink = styled.a`
  font-weight: bold;
  color: #000;
  text-decoration: none;
  &:hover {
    color: #444852;
  }
`;

export const SBPLabel = styled.div`
  display: flex
  justify-content: center;
  align-items: center;

  > img {
    margin-left: 8px;
    & + img {
      margin-left: 3px
    }
  }
`;

export const SBPInfoDescription = styled.div`
  a {
    outline: none;
    color: #1f2229;

    &:hover {
      color: #444852;
    }
  }
`;

export const BlockTitle = styled.h2`
  margin: 0 0 20px 0;
  font-size: 15px;
`;

const sberPayButtonDisabled = css`
  cursor: default;
  opacity: 0.6;
`;

export const SberPayButton = styled((props) => <Button {...props} />)`
  background-image: linear-gradient(150deg, rgb(242, 234, 0) 0%, rgb(3, 211, 29) 35%, rgb(15, 182, 254) 100%);

  ${(p) => p.disabled && sberPayButtonDisabled};
`;
