import styled from 'styled-components';
import { breakpoints } from '../../../Constants/css';

export const Wrapper = styled.div`
  display: flex;

  flex: 1;
  flex-direction: column;
  min-height: 564px;
  flex-basis: auto; //Fix IE11
  margin-bottom: 25px;

  > form {
    height: 100%;
  }
`;
export const Content = styled.div`
  display: flex;

  flex: 1;

  height: 100%;

  @media (max-width: ${breakpoints.desktop}) {
    flex-direction: column;
    flex-basis: auto; //Fix IE11
  }
`;
