//@flow

import { ValidationError } from '../../../Constants/errors';
import PaymentService from '../../../Services/PaymentService';

export default async (
  paymentId: string,
  onSuccess: Function,
  onThreeDS: Function,
  onError: Function,
  onFrameSuccess: Function
) => {
  const handleWaiting3DsStatus = async () => {
    const threeDSData = await PaymentService.getThreeDS({ paymentId });
    onThreeDS(threeDSData);
  };

  const handleWaitingFrameStatus = async () => {
    const frameData = await PaymentService.getFrame({ paymentId });
    await onFrameSuccess(frameData);

    const { status, reason } = await PaymentService.getPaymentExactStatus({
      paymentId,
      expectedStatuses: ['Waiting3Ds', 'Succeed', 'Pending', 'Rejected'],
      tries: 10,
    });

    switch (status) {
      case 'Waiting3Ds': {
        await handleWaiting3DsStatus();
        break;
      }
      case 'Succeed': {
        onSuccess();
        break;
      }
      case 'Pending': {
        await paymentExactStatus('WaitingFrame');
        break;
      }
      case 'WaitingFrame': {
        await PaymentService.confirmFrameThreeDS({ paymentId, threeDSCompInd: 'N' });
        await paymentExactStatus('WaitingFrame');
        break;
      }
      default:
        if (reason) {
          throw new ValidationError({
            commonErrors: [reason.description],
          });
        } else {
          throw new ValidationError({
            commonErrors: ['Произошла техническая ошибка, повторите запрос позднее.'],
          });
        }
    }
  };

  const paymentExactStatus = async (excl) => {
    try {
      const expectedStatuses = ['Waiting3Ds', 'WaitingFrame', 'Rejected', 'Succeed'].filter((sts) => sts !== excl);
      const { status: statusByRequest, reason } = await PaymentService.getPaymentExactStatus({
        paymentId,
        expectedStatuses,
      });
      const status = expectedStatuses.find((expectedSts) => expectedSts === statusByRequest);

      switch (status) {
        case 'Waiting3Ds':
          await handleWaiting3DsStatus();
          break;
        case 'Succeed':
          onSuccess();
          break;
        case 'WaitingFrame':
          await handleWaitingFrameStatus();
          break;
        default:
          throw new Error(reason ? reason.description : '');
      }
    } catch (error) {
      onError(error);
    }
  };

  await paymentExactStatus();
};
