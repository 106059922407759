import React from 'react';
import { Row } from '../../../../../Components/Blocks/Form';
import FieldTextInput from '../../../../../Components/Controls/TextInput/FieldTextInput';

import { Amount, SubscriberAccount } from '../styled';

import { amountInputRef } from './refs';

const PaymentInfo = (props) => {
  const { setFieldValue, isAmountDisabled, isSubscriberAccountDisabled } = props;

  const onBlurHandler = (e) => {
    const { value } = e.target;
    if (value[value.length - 1] === '.') {
      const newValue = parseInt(value.toString().split('').slice(0, -1).join(''));
      setFieldValue('amount', newValue, false);
    }
  };
  return (
    <Row>
      <SubscriberAccount>
        <FieldTextInput
          name={'subscriberAccount'}
          label={'Номер лицевого счета'}
          maskType={'subscriberAccount'}
          maxLength={13}
          required
          disabled={isSubscriberAccountDisabled}
        />
      </SubscriberAccount>
      <Amount>
        <FieldTextInput
          type={'tel'}
          name={'amount'}
          maskType={'decimal'}
          label={'Сумма, ₽ (от 1 до 15000)'}
          required
          maxLength={8}
          ref={amountInputRef}
          onBlur={onBlurHandler}
          disabled={isAmountDisabled}
        />
      </Amount>
    </Row>
  );
};

export default PaymentInfo;
