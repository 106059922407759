import React, { createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { CancelToken } from 'apisauce';
import { branch, compose, lifecycle, renderNothing, withHandlers, withProps, withStateHandlers } from 'recompose';

import Layout from '../../Components/Blocks/Layout';
import { withMobile, withCloseInFrame, withErrorsHandle, withQueryParams } from '../../Components/HOC';
import Field from '../../Components/Blocks/Succeed/Field';
import { FieldWrapper, Container } from './styled';
import MobileView from './MobileView';
import WebView from './WebView';
import Panel from '../../Components/Blocks/Panel';
import Failed from '../../Components/Blocks/Failed';
import SlideUpWidget from './SlideUpWidget';

import scenario from './Scenario';
import { formalizeMsisdn } from '../../Helpers/Normalize';
import { convertStringToBoolean } from '../../Helpers/Currency';
import SBPResult from './SBPResult';

const SBPDeepLink = (props) => {
  const {
    isMobile,
    isWidgetOpen,
    handleCheckout,
    handleCloseWidget,
    criticalError,
    commonError,
    isPaymentSucceed,
    isBindConfirmed,
    isPollingEnded,
    handleClose,
  } = props;

  const state = props.location.state;
  const { deepLink, values } = state;
  const { image, url, imageMediaType } = deepLink;

  const content = isMobile ? (
    <>
      <MobileView onCheckout={handleCheckout} />
      {isWidgetOpen && <SlideUpWidget onClose={handleCloseWidget} url={url} />}
    </>
  ) : (
    <WebView onClose={handleClose} image={image} imageType={imageMediaType} />
  );

  const topic = isPaymentSucceed && !isBindConfirmed ? 'Платёж успешно проведён' : undefined;
  const error = criticalError || commonError;

  const isCloseable = convertStringToBoolean(props.queryParams.closeable);

  const hasResult = isPollingEnded || isPaymentSucceed || isBindConfirmed;

  return (
    <Layout title={'Подтверждение оплаты'} titleCentered={!isCloseable}>
      <Panel width="730px">
        <Container>
          {!error && !hasResult && (
            <>
              <FieldWrapper>
                <Field
                  label="Номер телефона"
                  value={formalizeMsisdn(values.msisdn)}
                  data-testid={'block-result-field'}
                />
                <Field label="Сумма" value={`${values.amount} ₽`} data-testid={'block-result-field'} />
              </FieldWrapper>
              {content}
            </>
          )}

          {error && <Failed message={error} topic={topic} onClick={handleClose} buttonLabel="Закрыть" />}

          {!error && hasResult && (
            <SBPResult
              isPaymentSucceed={isPaymentSucceed}
              isBindConfirmed={isBindConfirmed}
              isPollingEnded={isPollingEnded}
              onClose={handleClose}
            />
          )}
        </Container>
      </Panel>
    </Layout>
  );
};

export default compose(
  withRouter,
  branch((props) => {
    if (!props.location.state) {
      props.history.push('/');
      return true;
    }
    return false;
  }, renderNothing),
  withErrorsHandle,
  withCloseInFrame,
  withStateHandlers(
    { isWidgetOpen: false, isPaymentSucceed: false, isBindConfirmed: false, isPollingEnded: false },
    {
      setIsWidgetOpen: () => (isWidgetOpen) => ({ isWidgetOpen }),
      setIsPaymentSucceed: () => (isPaymentSucceed) => ({ isPaymentSucceed }),
      setIsBindConfirmed: () => (isBindConfirmed) => ({ isBindConfirmed }),
      setIsPollingEnded: () => (isPollingEnded) => ({ isPollingEnded }),
    }
  ),
  withProps((props) => {
    const cancelTokenSourceRef = createRef();
    cancelTokenSourceRef.current = CancelToken.source();

    return {
      cancelTokenSource: cancelTokenSourceRef.current,
      state: props.location.state,
    };
  }),
  withHandlers({
    handleCheckout: (props) => () => props.setIsWidgetOpen(true),
    handleCloseWidget: (props) => () => props.setIsWidgetOpen(false),
    handlePaymentSucceed: (props) => () => props.setIsPaymentSucceed(true),
    handleBindSucceed: (props) => () => {
      props.setIsPaymentSucceed(false);
      props.setIsBindConfirmed(true);
    },
    handlePollingEnded: (props) => () => {
      props.setIsPaymentSucceed(false);
      props.setIsBindConfirmed(false);
      props.setIsPollingEnded(true);
    },
    handleError: (props) => (error) => props.handleError(error),
    handleClose: (props) => () => {
      props.cancelTokenSource.cancel();
      props.onClose();
    },
  }),
  lifecycle({
    componentDidMount() {
      const { location } = this.props;
      const { values } = location.state;

      if (values.isSbpBind) {
        scenario(this.props);
      }
    },
    componentWillUnmount() {
      const { cancelTokenSource } = this.props;

      cancelTokenSource.cancel();
    },
  }),
  withMobile,
  withQueryParams
)(SBPDeepLink);
