const MobileDetect = require('mobile-detect');

const checkMobile = () => {
  let hasTouchScreen = false;
  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    hasTouchScreen = navigator['msMaxTouchPoints'] > 0;
  } else {
    let mQ = window.matchMedia && matchMedia('(pointer:coarse)');
    if (mQ && mQ.media === '(pointer:coarse)') {
      hasTouchScreen = !!mQ.matches;
    } else if ('orientation' in window) {
      hasTouchScreen = true;
    }
  }

  const md = new MobileDetect(window.navigator.userAgent);

  const isMobileDetected = md.mobile() || md.phone() || md.tablet();
  return Boolean((hasTouchScreen && md.isPhoneSized(600)) || isMobileDetected);
};

export default checkMobile;
