//
// @flow
//
import { getAcceptCodeStatus } from '../Api/AcceptCodes';
import { SmsConfirmError } from '../Constants/errors';

import type { TGetAcceptCodeStatusRequest, TGetAcceptCodeStatusResponse } from '../Constants/types';

export default class AcceptCodeService {
  static async getAcceptCodeStatus({
    acceptCodeId,
  }: TGetAcceptCodeStatusRequest): Promise<TGetAcceptCodeStatusResponse> {
    const answer = await getAcceptCodeStatus({
      acceptCodeId,
    });

    if (answer && answer.remainingQuantity === 0) {
      throw new SmsConfirmError();
    } else {
      return answer;
    }
  }
}
