/**
 * 'Option's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';

export const Wrapper = styled.span`
  display: block;
  overflow: hidden;
  padding: 14px 20px;
  height: 1%;
  margin-right: 10px;
  margin-left: 4px;
  margin-top: 1px;
  margin-bottom: -1px;

  border-radius: 8px;
  white-space: nowrap;
  background: ${p => (p.isSelected ? '#1f2229' : p.isFocused ? '#edf0f2' : 'inherit')};
  color: ${p => (p.isSelected ? '#fff' : '#000')};

  cursor: default;
`;
