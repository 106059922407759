import React from 'react';
import Result from '../../Components/Blocks/Result';
import { withCloseInFrame } from '../../Components/HOC';
import { PAYMENT_STATUS } from '../../Constants/paymentStatus';
import { deleteFirstSevenCharacter, formalizeMsisdn } from '../../Helpers/Normalize';

const PaymentStatus = (props) => {
  let topic;
  let message;
  let label;
  let onClick;

  if (props.status === PAYMENT_STATUS.SUCCEED) {
    topic = 'Ваш платеж успешно выполнен';
    label = 'Закрыть';
    onClick = props.onClose;
  }
  if (props.status === PAYMENT_STATUS.WAITING_EXTERNAL_EXECUTING) {
    const formattedMsisdn = formalizeMsisdn(deleteFirstSevenCharacter(props.msisdn));
    topic = 'Ваш запрос принят.';
    message = `Вам отправлено PUSH или SMS сообщение для оплаты.
    
    Если сообщение не поступило - убедитесь, что номер ${formattedMsisdn} 
    активен в СберБанк Онлайн или воспользуйтесь другим способом оплаты`;
    label = 'Вернуться';
    onClick = props.onReturn;
  }

  return <Result topic={topic} message={message} buttonLabel={label} onClick={onClick} />;
};

export default withCloseInFrame(PaymentStatus);
