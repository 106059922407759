import { useDispatch } from 'react-redux';
import { combineReducers } from 'redux';

import { captchaReqReducer } from './captchaReq';
import { formPayReqReducer } from './formPayReq';
import { responseListReducer } from './responseList';
import { yandexCaptchaReqReducer } from './yandexCaptchaReq';

const rootReducer = combineReducers({
  formPayReq: formPayReqReducer,
  captchaReq: captchaReqReducer,
  responseList: responseListReducer,
  yandexCaptchaReq: yandexCaptchaReqReducer,
});

export const useAppDispatch = () => useDispatch();
export default rootReducer;
