import React from 'react';
import Result from '../../Components/Blocks/Result';
import Icon from 'react-icons-kit';
import { time } from 'react-icons-kit/ikons/time';

const SBPResult = (props) => {
  const { onClose, isPollingEnded, isPaymentSucceed, isBindConfirmed } = props;

  let topic;
  let message;

  if (isPollingEnded) {
    topic = 'Ваш запрос принят';
    message = 'Результат операции будет отправлен в SMS';
  }

  if (isPaymentSucceed) {
    topic = 'Платёж успешно проведён';
    message = (
      <div>
        <Icon style={{ marginBottom: '10px' }} icon={time} size={30} />
        <div>Ожидайте результат привязки СБП</div>
      </div>
    );
  }

  if (isBindConfirmed) {
    topic = 'Платёж успешно проведён';
    message = 'Привязка СБП прошла успешно';
  }

  return <Result topic={topic} message={message} buttonLabel="Закрыть" onClick={onClose} />;
};

export default SBPResult;
