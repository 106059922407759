import React from 'react';
import CardFields from '../../../../../Components/Blocks/CardFields';
import { FormContainer, Row } from '../../../../../Components/Blocks/Form';
import Panel from '../../../../../Components/Blocks/Panel';
import CardsImage from '../../../../../Components/CardsImage';
import RadioTab from '../../../../../Components/Controls/RadioButton';
import RadioTabGroup from '../../../../../Components/Controls/RadioTabGroup';
import ReceiptElectron from '../../../../../Components/Controls/ReceiptElectron';
import { withMobile } from '../../../../../Components/HOC';
import { BlockTitle, SBPWrapper } from '../styled';
import PaymentInfo from './PaymentInfo';
import SBPInfo from './SBPInfo';
import CardBind from './CardBind';
import SBPBind from './SBPBind';

const cardLogo = '/assets/images/bank-card.svg';
const sbpLogo = '/assets/images/sbp-logo.svg';

const RequisitesPanel = (props) => {
  const {
    isCardValid,
    errors,
    setFieldValue,
    values,
    onCardBind,
    remainingQuantity,
    setPayType,
    payType,
    isMobile,
    isSbpActive,
    isCardActive,
    elementIsDisabled,
    onSbpBind,
    sbpRemainingQuantity,
  } = props;

  const cardContent = (
    <>
      <CardFields setFieldValue={setFieldValue} />
      {!elementIsDisabled && (
        <CardBind
          values={values}
          errors={errors}
          onCardBind={onCardBind}
          remainingQuantity={remainingQuantity}
          isCardValid={isCardValid}
        />
      )}

      <BlockTitle>Электронный чек</BlockTitle>
      <Row>
        <ReceiptElectron
          type={values.electronReceiptType}
          emailInputName={'electronReceiptEmail'}
          mobileInputName={'electronReceiptMobile'}
          receiptTypeRadioName={'electronReceiptType'}
          isMobile={isMobile}
        />
      </Row>

      {!isMobile && <CardsImage />}
    </>
  );

  const sbpContent = (
    <SBPWrapper>
      <SBPBind values={values} errors={errors} onSbpBind={onSbpBind} remainingQuantity={sbpRemainingQuantity} />
      <SBPInfo />
    </SBPWrapper>
  );

  const renderContent = () => {
    if (isSbpActive && isCardActive) {
      return (
        <RadioTabGroup checked={payType} setChecked={setPayType}>
          <RadioTab label="Карта" name="card" default={true} image={cardLogo} width="254px">
            {cardContent}
          </RadioTab>
          <RadioTab label="Система быстрых платежей" name="sbp" image={sbpLogo} width="254px">
            {sbpContent}
          </RadioTab>
        </RadioTabGroup>
      );
    }

    if (isSbpActive && !isCardActive) {
      return sbpContent;
    }

    return cardContent;
  };

  return (
    <Panel>
      <FormContainer>
        <BlockTitle>Реквизиты для пополнения баланса</BlockTitle>
        <PaymentInfo isMobile={isMobile} />
        {renderContent()}
      </FormContainer>
    </Panel>
  );
};

export default withMobile(React.memo(RequisitesPanel));
