/**
 * 'ErrorPanel', Control Component
 *
 * @flow
 */
import React, { Fragment } from 'react';

import { Link, Wrapper } from './styled';

type TProps = {
  text?: string,
  testId?: string,
};

const urlInTextPattern = new RegExp(
  'https?:\\/\\/(?:www\\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b[-a-zA-Z0-9()@:%_+.~#?&\\/=]*'
);
const urlsInTextPattern = new RegExp(urlInTextPattern, 'g');

const checkWordIsLink = (word) => word.match(urlsInTextPattern);

const ErrorPanel = (props: TProps) => {
  if (props.text && props.text.length > 0) {
    return (
      <Wrapper data-testid={props.testId || 'error_panel'}>
        {props.text &&
          props.text.split('\n').map((sentence, sentenceIndex) => {
            const wordsInSentence = sentence.split(' ');

            const replacedWords = wordsInSentence.map((word, wordIndex) => (
              <Fragment key={wordIndex}>
                {checkWordIsLink(word) ? (
                  <Link target="_blank" rel="noopener noreferrer" href={word} key={wordIndex}>
                    {word}
                  </Link>
                ) : (
                  word
                )}{' '}
              </Fragment>
            ));

            return <div key={sentenceIndex}>{replacedWords}</div>;
          })}
      </Wrapper>
    );
  } else {
    return null;
  }
};

export default React.memo(ErrorPanel);
