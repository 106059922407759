import * as React from 'react';
import ErrorPanel from '../../../../Components/ErrorPanel';

const ErrorMessage = ({ commonError }) => {
  if (commonError) {
    return (
      <div style={{ margin: '0px 0px 25px 0px' }}>
        <ErrorPanel text={commonError} />
      </div>
    );
  }
  return null;
};

export default ErrorMessage;
