/**
 * 'Field's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';
import { breakpoints } from '../../../../Constants/css';

export const Wrapper = styled.div`
  margin: 17px 0 0 0;

  flex: 1;
  flex-basis: auto; //Fix IE11
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.div`
  font-size: 22px;
  color: #8f9399;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 16px;
  }
`;

export const Value = styled.div`
  font-size: 22px;
  color: #1f2229;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 16px;
  }
`;
