import React, { Fragment, useEffect, useState } from 'react';
import { compose } from 'recompose';

import Failed from '../../Components/Blocks/Failed';
import Layout from '../../Components/Blocks/Layout';
import Request from '../../Components/Blocks/Request';
import { withCloseInFrame, withErrorsHandle, withFrame, withQueryParams, withThreeDS } from '../../Components/HOC';
import { CriticalError } from '../../Constants/errors';
import { convertKopecksToRublesAsDecimal } from '../../Helpers/Currency';
import { deleteFirstSevenCharacter, formalizeMsisdn } from '../../Helpers/Normalize';
import PaymentService from '../../Services/PaymentService';
import Form from './Form';
import SelfRegistrationResult from './SelfRegistrationResult';

const SelfRegistrationPage = (props) => {
  const { queryParams, criticalError, handleError, threeDS, handlePostFrame, history, onClose } = props;

  const [paymentInfo, setPaymentInfo] = useState({ msisdn: '', amount: '' });
  const [loading, setLoading] = useState(false);

  const { msisdn, amount } = paymentInfo;
  const { paymentid, payId } = queryParams;

  const resolvePayment = async () => {
    try {
      setLoading(true);
      const paymentInfo = await PaymentService.getSelfRegistrationOrder({ payId });
      setPaymentInfo(paymentInfo);
    } catch (error) {
      const { commonErrors } = error;

      onError(
        new CriticalError({
          commonErrors:
            commonErrors && commonErrors.length > 0
              ? commonErrors
              : [`Произошла техническая ошибка, повторите запрос позднее.`],
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (payId) {
      resolvePayment();
    }
  }, [payId]);

  const title = (
    <Fragment>
      <div>Оплата заказа</div>
      {msisdn && <div>{formalizeMsisdn(deleteFirstSevenCharacter(msisdn))}</div>}
      {amount && <div>Сумма пополнения {convertKopecksToRublesAsDecimal(amount)} ₽</div>}
    </Fragment>
  );

  const onError = (error) => handleError(error);

  const onThreeDSSuccess = async (threeDSData) => await threeDS.redirect(threeDSData);
  const onFrameSuccess = async (threeDSData) => await handlePostFrame(threeDSData);

  const onAreqSucceed = (paymentId) => history.push(`selfregistration?paymentid=${paymentId}`);

  return (
    <Layout title={title}>
      {loading && <Request />}

      {criticalError && <Failed message={criticalError} onClick={onClose} buttonLabel={'Закрыть'} />}

      {!criticalError && msisdn && amount && !paymentid && (
        <Form
          {...queryParams}
          {...paymentInfo}
          onError={onError}
          onThreeDSSuccess={onThreeDSSuccess}
          onFrameSuccess={onFrameSuccess}
          onAreqSucceed={onAreqSucceed}
        />
      )}

      {paymentid && <SelfRegistrationResult paymentId={paymentid} />}
    </Layout>
  );
};

export default compose(
  withQueryParams,
  withThreeDS,
  withErrorsHandle,
  withCloseInFrame,
  withFrame
)(React.memo(SelfRegistrationPage));
