import React from 'react';

import AcceptCode from '../../../../Components/Blocks/AcceptCode';
import FieldTextInput from '../../../../Components/Controls/TextInput/FieldTextInput';
import { formalizeMsisdn } from '../../../../Helpers/Normalize';

const IsCardBind = props => {
  const { remainingQuantityConfirm, values } = props;

  return (
    <AcceptCode
      label={`SMS-код отправлен на номер ${formalizeMsisdn(values.msisdn)}`}
      hint={remainingQuantityConfirm ? `У вас осталась ${remainingQuantityConfirm} попытки ввода` : ''}
    >
      <FieldTextInput
        name={'acceptCodeConfirm'}
        label={'Код'}
        type={'tel'}
        required
        maxLength={'4'}
        maskType={'integer'}
      />
    </AcceptCode>
  );
};

export default React.memo(IsCardBind);
